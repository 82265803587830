import React from 'react'

import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { Button, Space, Modal } from 'antd'

import { InventoryOperationTypes } from 'common/server/server_types'

import { OperationIcon } from './operation_icon'

type OperationTabTitleProps = {
  title: string
  onRemove?: React.MouseEventHandler<HTMLElement>
  isRemovable?: boolean
  operationType: InventoryOperationTypes
}

export const OperationTabTitle = ({ title, operationType, isRemovable = false, onRemove }: OperationTabTitleProps) => {
  const handleRemove = (e) => {
    e.stopPropagation()
    Modal.confirm({
      title: 'Are you sure you want to remove this item?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        onRemove?.(e)
      },
    })
  }

  return (
    <Space>
      <OperationIcon operationType={operationType} />
      <div>{title}</div>
      {isRemovable && (
        <Button
          type="text"
          size="small"
          icon={<CloseOutlined style={{ fontSize: 12, marginRight: 0 }} onClick={handleRemove} />}
        />
      )}
    </Space>
  )
}
