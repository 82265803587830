import React from 'react'

import { Col, Row, Form, RadioChangeEvent } from 'antd'

import SelectAddress from 'common/components/address/selector'
import { Box } from 'common/components/boxes'
import DateTimePicker from 'common/components/date_time_picker'
import { AddressTo } from 'common/server/addressesTo'

import { DeliveryInstructions } from 'contractor/components/DeliveryInstructions'
import { SelectAddressTo } from 'contractor/components/OrderDeliveries/address_to_selector'
import { AllAddresses } from 'contractor/server/addresses'

import { OperationTypeToggle } from './operation_type_toggle'

export type OperationInformationProps = {
  operationName: number
  isLoadingAddresses?: boolean
  isLoadingAddressesTo?: boolean
  addressToOptions?: AddressTo[]
  addressOptions?: AllAddresses
  onChangeOperationType: (e: RadioChangeEvent) => void
}

export const OperationInformation = ({
  operationName,
  isLoadingAddresses,
  isLoadingAddressesTo,
  addressToOptions,
  addressOptions = { company: [], project: [], company_vendor: [], warehouse: [] },
  onChangeOperationType,
}: OperationInformationProps) => {
  return (
    <Row gutter={8}>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={10}>
        <Box display="flex" width="100%" alignItems="flex-end" gridGap={8}>
          <Form.Item name={[operationName, 'operation_type']}>
            <OperationTypeToggle onChange={onChangeOperationType} />
          </Form.Item>

          <Box justifyContent="flex-start" alignItems="flex-start" width="100%">
            <Form.Item name={[operationName, 'address_id']} label="Address">
              <SelectAddress
                addressOptions={addressOptions}
                wrapperProps={{
                  maxWidth: '100%',
                  minWidth: '100%',
                  width: '100%',
                }}
                loading={isLoadingAddresses}
              />
            </Form.Item>
          </Box>
        </Box>
      </Col>

      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={4}>
        <Form.Item name={[operationName, 'attention_to_id']} label="Attention to">
          <SelectAddressTo
            loading={isLoadingAddressesTo}
            addressToOptions={addressToOptions}
            placeholder="Optional"
            wrapperProps={{
              maxWidth: '100%',
              minWidth: '100%',
              width: '100%',
            }}
          />
        </Form.Item>
      </Col>

      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={4}>
        <Box display="flex" width="100%" alignItems="flex-end" gridGap={8}>
          <Form.Item style={{ width: '100%' }} name={[operationName, 'requested_date']} label="Requested date">
            <DateTimePicker placeholder="Optional" wrapperProps={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={[operationName, 'operation_instructions']}>
            <DeliveryInstructions />
          </Form.Item>
        </Box>
      </Col>
    </Row>
  )
}
