import React from 'react'

import { useTheme } from '@emotion/react'

import { AuditOutlined, FileDoneOutlined } from '@ant-design/icons'
import { Badge, Tag } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import { CommitmentStatuses } from 'common/server/server_types'

export const CommitmentState = ({ state }: { state: CommitmentStatuses }) => {
  const theme = useTheme()

  const defaultStyle = {
    color: 'black',
    width: '140px',
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'normal',
  }

  const StateMapping = {
    [CommitmentStatuses.DRAFT]: {
      color: theme.colors['gold-1'],
      text: 'Draft',
      icon: <AuditOutlined style={{ paddingTop: 4 }} />,
    },
    [CommitmentStatuses.CONFIRMED]: {
      color: 'blue',
      text: 'Confirmed',
      icon: <FileDoneOutlined style={{ paddingTop: 4 }} />,
    },
    [CommitmentStatuses.PARTIALLY_DELIVERED]: {
      color: 'green',
      text: 'Partially Delivered',
    },
    [CommitmentStatuses.DELIVERED]: {
      color: 'green',
      text: 'Delivered',
    },
    [CommitmentStatuses.CANCELLED]: {
      color: theme.colors['gray-2'],
      text: 'Cancelled',
    },
  }

  return (
    <Badge style={{ right: 12 }}>
      <Tag icon={StateMapping[state]?.['icon']} color={StateMapping[state]?.['color']} style={defaultStyle}>
        <FlexBoxX aria-label="status-badge" fontSize={12}>
          {StateMapping[state]?.['text']}
        </FlexBoxX>
      </Tag>
    </Badge>
  )
}
