import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { Page } from 'common/components/Page'

import { WarehousePage } from 'contractor/pages/Inventory/Warehouses/List/warehouses'

import { Requests } from './Requests'
import { Stock } from './Stock'

export const InventoryPage = () => {
  const history = useHistory()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const activeKey = urlParams.get('tab')

  const tabItems = [
    {
      label: 'Stock',
      key: 'stocks',
      children: <Stock />,
    },
    {
      label: 'Requests',
      key: 'requests',
      children: <Requests />,
    },
    {
      label: 'Warehouses',
      key: 'warehouses',
      children: <WarehousePage />,
    },
  ]

  return (
    <Page>
      <Page.Tabs
        activeKey={activeKey || 'stocks'}
        onChange={(key) => {
          urlParams.set('tab', key)
          history.push({
            search: urlParams.toString(),
          })
        }}
        items={tabItems}
        destroyInactiveTabPane
      />
    </Page>
  )
}
