import React from 'react'

import { Button, Form, Typography, Row, Col } from 'antd'

import { Box } from 'common/components/boxes'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import { useGoBack } from 'common/hooks/use-go-back'

import { SelectUser, makeCompanyUserOption } from 'contractor/components/SelectUser'
import { WatchersDrawer } from 'contractor/components/WatchersDrawer'
import { makeCompanyUserWatcherOption } from 'contractor/components/WatchersSelect'

import { useCreateInventoryRequest } from '../context'

type RequestCreateHeaderProps = {
  isSubmitting: boolean
}

export const RequestCreateHeader = ({ isSubmitting }: RequestCreateHeaderProps) => {
  const { goBack } = useGoBack()
  const { handleSetSubmitType } = useCreateInventoryRequest()

  return (
    <Row gutter={16} justify="space-between">
      <Col xs={24} sm={24} md={6} lg={5} xl={4} xxl={3}>
        <Box display="flex" alignItems="center">
          <Box mr={16}>{<UnsavedPopup goBack={() => goBack('/inventory?tab=requests')} />}</Box>

          <Typography.Title level={5} style={{ margin: 0, fontSize: 20 }}>
            New request
          </Typography.Title>
        </Box>
      </Col>

      <Col xs={24} sm={24} md={18} lg={19} xl={20} xxl={21}>
        <Row gutter={8} justify="end">
          <Col xs={24} sm={6} md={6} lg={4} xl={3} xxl={2}>
            <Form.Item name="watchers">
              <WatchersDrawer boxProps={{ width: '100%', mt: 1 }} makeOption={makeCompanyUserWatcherOption} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={8} md={6} lg={6} xl={5} xxl={4}>
            <Form.Item name="assigned_to_id">
              <SelectUser
                onlyActives
                makeOption={makeCompanyUserOption}
                placeholder="Assignee"
                boxProps={{ width: '100%' }}
                showAssignToMe={false}
                showAssignToMeOnDropdown
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={5} md={6} lg={4} xl={3} xxl={2}>
            <Button
              onClick={() => handleSetSubmitType('DRAFT')}
              loading={isSubmitting}
              htmlType="submit"
              style={{ width: '100%', marginBottom: 12 }}
            >
              Save Draft
            </Button>
          </Col>
          <Col xs={24} sm={5} md={6} lg={3} xl={3} xxl={2}>
            <Button
              onClick={() => handleSetSubmitType('REQUESTED')}
              type="primary"
              loading={isSubmitting}
              htmlType="submit"
              style={{ width: '100%' }}
            >
              Request
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
