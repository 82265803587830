import React from 'react'

import { Empty } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'
import { NoWarehouseSelected } from 'contractor/pages/Inventory/common/components/NoWarehouseSelected'

import { Actions } from './actions'
import { StockRow } from './StockRow'

export const StockList = observer(() => {
  const {
    inventoryStockStore: { listStore },
  } = useStores()
  const persistentFilters = usePersistentFilters()

  if (listStore.isFetching) return <Loading />

  // We must not load the list if the warehouse is not selected
  if (!listStore.searchState.filters['warehouse_id']) {
    return (
      <NoWarehouseSelected
        value={listStore.searchState.filters['warehouse_id']}
        onChange={(value) => persistentFilters.handleChangeFilters({ warehouse_id: value })}
      />
    )
  }

  const consolidatedStocks = toJS(listStore.records)

  if (!consolidatedStocks.length) return <Empty description="No results found." />

  return (
    <>
      <Actions />

      {consolidatedStocks.map((consolidatedStock) => (
        <StockRow key={consolidatedStock.id} consolidatedStock={consolidatedStock} />
      ))}
    </>
  )
})
