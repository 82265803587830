import React, { createContext, useContext, useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { Form, FormInstance, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Warehouse } from 'contractor/server/warehouses'

type WarehouseContextProps = {
  warehouse: Warehouse
  setWarehouse: React.Dispatch<React.SetStateAction<Warehouse>>
  isSubmitting: boolean
  goBack: () => void
  form: FormInstance
  setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  handleLogError: (error: {}, defaultMessage?: string) => void
  formatWarehousePayload: (formValues: Warehouse) => Warehouse
}

const WarehouseContext = createContext<WarehouseContextProps>({} as WarehouseContextProps)

export const useWarehouse = () => useContext(WarehouseContext)

export const WarehouseProvider = observer(({ children }) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const [isSubmitting, setSubmitting] = useState(false)
  const [warehouse, setWarehouse] = useState<Warehouse>({} as Warehouse)

  const handleLogError = (error, defaultMessage = '') => {
    if (error?.response?.data?.error) {
      message.error({
        content: error.response.data.error,
        duration: 8,
      })
    } else {
      message.error(defaultMessage || 'Unable to complete action. Please try again.')
    }
  }

  const formatWarehousePayload = (formValues: Warehouse) => {
    return {
      ...warehouse,
      name: formValues['name'],
      project_id: formValues['project_id'],
      address_id: formValues['address_id'],
    } as Warehouse
  }

  const goBack = () => {
    history.push('/inventory?tab=warehouses')
  }

  const resetCurrentWarehouse = () => {
    setWarehouse({} as Warehouse)
  }

  useEffect(() => {
    return () => {
      resetCurrentWarehouse()
    }
  }, [])

  return (
    <WarehouseContext.Provider
      value={{
        isSubmitting,
        goBack,
        form,
        warehouse,
        setWarehouse,
        setSubmitting,
        handleLogError,
        formatWarehousePayload,
      }}
    >
      {children}
    </WarehouseContext.Provider>
  )
})

export const withWarehouseProvider = (Component) => (props) => {
  return (
    <WarehouseProvider>
      <Component {...props} />
    </WarehouseProvider>
  )
}
