import React from 'react'

import { Checkbox, Typography } from 'antd'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { currencyFormatter } from 'common/helpers/formatters'
import theme from 'common/styles/theme'

type Option = {
  id: string
  description?: string
  quantity_shipped: number
  uom?: string
  unit_price: number
  unit_multiplier?: number
  unit_qty_increment?: number
  checked?: boolean
  calcExtCost: (...rest) => number
}

type Column = {
  id: string
  label?: string
  render: (value, option: Option) => React.ReactNode
  width?: number | string
}

const COLUMNS: Column[] = [
  {
    id: 'description',
    label: 'Order Description',
    render: (value) => value || '-',
    width: '100%',
  },
  {
    id: 'quantity_shipped',
    label: 'Qty',
    render: (value) => value || '-',
    width: 100,
  },
  {
    id: 'uom',
    label: 'UOM',
    render: (value) => value || '-',
    width: 150,
  },
  {
    id: 'unit_price',
    label: 'Unit cost',
    render: (value) => currencyFormatter(value),
    width: 150,
  },
  {
    id: 'ext_price',
    label: 'Ext. cost',
    render: (_, option) =>
      currencyFormatter(
        option.calcExtCost({
          unitCost: option?.unit_price,
          quantity: option?.quantity_shipped,
          multiplier: option?.unit_multiplier,
          qtyIncrement: option?.unit_qty_increment,
        }),
      ),
    width: 200,
  },
]

type OrderItemProps = {
  children?: React.ReactNode
  item: Option
  onCheck?: (itemId: string) => void
  disabled?: boolean
}

const OrderItem = ({ children, item, onCheck, disabled }: OrderItemProps) => {
  const renderColumn = React.useCallback((column: Column, option: Option) => {
    return (
      <FlexBoxY key={`${option.description}-${column.id}`} alignItems="baseline" width={column.width}>
        <Typography.Text color={theme.colors['gray-8']} style={{ fontSize: 10 }}>
          {column.label}
        </Typography.Text>
        <Typography.Text style={{ display: 'flex', gap: 8 }} color={theme.colors['gray-12']}>
          {column.render(option[column.id], option)}
        </Typography.Text>
      </FlexBoxY>
    )
  }, [])

  return (
    <Box>
      <FlexBoxX alignItems="center" style={{ gap: 16 }}>
        {onCheck && (
          <Checkbox onChange={() => onCheck(item.id)} checked={item.checked} className="mt-4" disabled={disabled} />
        )}
        {COLUMNS.map((column) => renderColumn(column, item))}
      </FlexBoxX>
      {children}
    </Box>
  )
}

export { OrderItem }
