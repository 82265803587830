import { renderHook, act } from '@testing-library/react-hooks'

import { message } from 'antd'

import { noticeError } from 'common/helpers/new_relic'

import { useStores } from 'contractor/hooks/use-stores'

import { useCreateStock } from '../use-create-stock'

jest.mock('antd', () => ({
  message: {
    success: jest.fn(),
    error: jest.fn(),
  },
}))

jest.mock('common/helpers/new_relic', () => ({
  noticeError: jest.fn(),
}))

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

const stockCreationPayload = {
  warehouse_id: 'warehouse_id',
  company_material_id: 'company_material_id',
  unit_id: 'unit_id',
  expiration_date: 'expiration_date',
  manufactured_date: 'manufactured_date',
  date_in: 'date_in',
  qty: '1',
  send_to_quality_hold: true,
}

describe('Inventory > Stock > Create > useCreateStock', () => {
  const mockCreateStock = jest.fn()
  const mockOnSuccess = jest.fn()

  beforeEach(() => {
    jest.clearAllMocks()
    ;(useStores as jest.Mock).mockReturnValue({
      inventoryStockStore: {
        createStock: mockCreateStock,
      },
    })
  })

  it('should handle stock creation successfully', async () => {
    mockCreateStock.mockResolvedValueOnce(undefined)

    const { result } = renderHook(() => useCreateStock({ onSuccess: mockOnSuccess }))

    await act(async () => {
      await result.current.handleCreateStock(stockCreationPayload)
    })

    expect(result.current.isSubmitting).toBe(false)
    expect(mockCreateStock).toHaveBeenCalledWith(stockCreationPayload)
    expect(message.success).toHaveBeenCalledWith('Successfully created stock')
    expect(mockOnSuccess).toHaveBeenCalled()
  })

  it('should handle errors during stock creation', async () => {
    const mockError = {
      response: {
        data: {
          error: 'Stock creation failed',
        },
      },
    }
    mockCreateStock.mockRejectedValueOnce(mockError)

    const { result } = renderHook(() => useCreateStock({ onSuccess: mockOnSuccess }))

    await act(async () => {
      await result.current.handleCreateStock(stockCreationPayload)
    })

    expect(result.current.isSubmitting).toBe(false)
    expect(noticeError).toHaveBeenCalledWith(mockError, { entry: 'create-inventory-stock' })
    expect(message.error).toHaveBeenCalledWith('Stock creation failed')
    expect(mockOnSuccess).not.toHaveBeenCalled()
  })

  it('should handle generic errors gracefully', async () => {
    mockCreateStock.mockRejectedValueOnce(new Error('Generic error'))

    const { result } = renderHook(() => useCreateStock({ onSuccess: mockOnSuccess }))

    await act(async () => {
      await result.current.handleCreateStock(stockCreationPayload)
    })

    expect(result.current.isSubmitting).toBe(false)
    expect(message.error).toHaveBeenCalledWith('Unable to create stock')
    expect(mockOnSuccess).not.toHaveBeenCalled()
  })

  it('should toggle `isSubmitting` during the process', async () => {
    mockCreateStock.mockResolvedValueOnce(undefined)

    const { result, waitFor } = renderHook(() => useCreateStock({ onSuccess: mockOnSuccess }))

    expect(result.current.isSubmitting).toBe(false)

    await act(async () => {
      const promise = result.current.handleCreateStock(stockCreationPayload)

      await waitFor(() => {
        expect(result.current.isSubmitting).toBe(true)
      })

      await promise
    })

    expect(result.current.isSubmitting).toBe(false)
  })
})
