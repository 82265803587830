import React from 'react'

import { render, screen } from '@testing-library/react'

import { InventoryRequestState, InventoryRequestStateProps } from '../inventory_request_state'

jest.mock('common/hooks/use-theme', () => ({
  useTheme: () => ({
    colors: {
      'gray-1': '#f0f0f0',
      'gray-3': '#d9d9d9',
      'gray-6': '#595959',
    },
  }),
}))

describe('Inventory > Request > common > components > InventoryRequestState', () => {
  const mockState = {
    label: 'Pending',
    color: 'gray',
  } as InventoryRequestStateProps['state']

  it('renders the component with the correct label', () => {
    render(<InventoryRequestState state={mockState} />)

    expect(screen.getByText('Pending')).toBeInTheDocument()
  })

  it('applies the correct styles from the theme', () => {
    render(<InventoryRequestState state={mockState} />)

    const tagElement = screen.getByText('Pending')

    expect(tagElement).toHaveStyle(`
      background-color: #f0f0f0;
      border-color: #d9d9d9;
      color: #595959;
    `)
  })

  it('stops event propagation when clicked', () => {
    render(<InventoryRequestState state={mockState} />)
    const tagElement = screen.getByText('Pending')

    // Create a real event and spy on stopPropagation
    const clickEvent = new MouseEvent('click', { bubbles: true, cancelable: true })
    jest.spyOn(clickEvent, 'stopPropagation')

    // Dispatch event instead of using fireEvent
    tagElement.dispatchEvent(clickEvent)

    // Verify stopPropagation was called
    expect(clickEvent.stopPropagation).toHaveBeenCalled()
  })
})
