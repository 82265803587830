import { renderHook } from '@testing-library/react-hooks'

import { useStores } from 'contractor/hooks/use-stores'
import { useGetWarehouses } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-get-warehouses'
import { Warehouse } from 'contractor/server/warehouses'

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

const mockGetIndex = jest.fn()

beforeEach(() => {
  jest.clearAllMocks()
  ;(useStores as jest.Mock).mockReturnValue({
    warehouseStore: {
      getIndex: mockGetIndex,
    },
  })
})

describe('useGetWarehouses Hook', () => {
  it('should return data when getIndex is successful', async () => {
    const mockData: Warehouse[] = [
      { id: '1', name: 'Alpha', project_id: 1, address_id: 1 },
      { id: '2', name: 'Beta', project_id: 2, address_id: 2 },
    ]
    mockGetIndex.mockResolvedValueOnce(mockData)

    const { result, waitForNextUpdate } = renderHook(() => useGetWarehouses())

    expect(result.current.isLoading).toBe(true)
    expect(mockGetIndex).toHaveBeenCalled()

    await waitForNextUpdate()

    expect(result.current.isLoading).toBe(false)
    expect(result.current.data).toEqual(mockData)
    expect(result.current.isError).toBe(false)
  })

  it('should handle error when getIndex fails', async () => {
    const mockError = new Error('Failed to fetch')
    mockGetIndex.mockRejectedValueOnce(mockError)

    const { result, waitForNextUpdate } = renderHook(() => useGetWarehouses())

    expect(result.current.isLoading).toBe(true)
    expect(mockGetIndex).toHaveBeenCalled()

    await waitForNextUpdate()

    expect(result.current.isLoading).toBe(false)
    expect(result.current.data).toEqual(null)
    expect(result.current.isError).toBe(true)
  })

  it('should indicate loading state initially', () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mockGetIndex.mockReturnValue(new Promise(() => {}))

    const { result } = renderHook(() => useGetWarehouses())

    expect(result.current.isLoading).toBe(true)
    expect(result.current.data).toEqual(null)
    expect(result.current.isError).toBe(false)
    expect(mockGetIndex).toHaveBeenCalled()
  })
})
