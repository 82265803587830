import React from 'react'

import { render, screen } from '@testing-library/react'

import { NoWarehouseSelected } from '../no_warehouse_selected'

jest.mock('contractor/pages/Inventory/common/components/SelectWarehouseFilter', () => ({
  SelectWarehouseFilter: jest.fn(() => <div data-testid="select-warehouse-filter" />),
}))

describe('Inventory > common > components > NoWarehouseSelected', () => {
  const mockProps = {
    value: 'warehouse_123',
    onChange: jest.fn(),
  }

  it('renders the correct title', () => {
    render(<NoWarehouseSelected {...mockProps} />)

    expect(screen.getByText('Please select a warehouse.')).toBeInTheDocument()
  })

  it('renders the SelectWarehouseFilter component', () => {
    render(<NoWarehouseSelected {...mockProps} />)

    expect(screen.getByTestId('select-warehouse-filter')).toBeInTheDocument()
  })
})
