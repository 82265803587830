import React from 'react'

import { Typography, Button, List as AntdList, message, Divider } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { FlexBoxY, FlexBoxX } from 'common/components/boxes'
import { Loading } from 'common/components/Loading'
import { usePersistentFilters } from 'common/contexts/persistent_filters'
import { useLocalStorage } from 'common/hooks/use-local-storage'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { IndexCompanyVendor } from 'contractor/server/company_vendors'
import { Vendor } from 'contractor/server/vendors'

import { CompanyVendorContactCard } from './company_vendor_contact_card'
import { EmptyVendors } from './empty_vendors'

type ListProps = {
  onCreate: () => void
  goToNetWork: () => void
  onOpenVendorProfile: (vendor: Vendor, companyVendor: IndexCompanyVendor) => void
  onOpenCompanyVendorContactUpdate: (companyVendorContactId: string, companyVendor: IndexCompanyVendor) => void
  onOpenCompanyVendorUpdate: (companyVendor: IndexCompanyVendor) => void
}

export const List = observer<ListProps>(
  ({ onCreate, goToNetWork, onOpenVendorProfile, onOpenCompanyVendorUpdate, onOpenCompanyVendorContactUpdate }) => {
    const { companyVendorStore } = useStores()
    const persistentFilters = usePersistentFilters()
    const [localStorage] = useLocalStorage<boolean>('@subbase/my-vendors/only-actives', true)

    const { companyVendorListStore } = companyVendorStore

    useQuery(() => {
      persistentFilters.handleChangeFilters({ active: localStorage }, false)
      return persistentFilters.init()
    }, [localStorage])

    const handleChangeActive = async (active: boolean, companyVendorId: string, companyVendorContactId: string) => {
      try {
        await companyVendorStore.updateCompanyVendorContact({
          id: companyVendorContactId,
          active,
        })
        companyVendorListStore.fetchRecords()

        message.success(`Successfully changed contact to ${active ? 'Active' : 'Inactive'}`)
      } catch {
        message.error(`Unable to ${active ? 'ACTIVE' : 'INACTIVE'} contact`)
      }
    }

    if (companyVendorListStore.isFetching && companyVendorListStore.searchState.page === 1) {
      return <Loading />
    }

    if (companyVendorListStore.records.length === 0) {
      return <EmptyVendors mt={32} onCreate={onCreate} onFind={goToNetWork} />
    }

    const companyVendors = toJS(companyVendorListStore.records)

    const handleOpenCompanyVendorContactUpdate = (
      companyVendor: IndexCompanyVendor,
      companyVendorContactId: string,
    ) => {
      onOpenCompanyVendorContactUpdate(companyVendorContactId, companyVendor)
    }

    return (
      <>
        {companyVendors.map((companyVendor, index) => (
          <React.Fragment key={companyVendor.id}>
            <FlexBoxY width="100%" alignItems="flex-start" mb="10px">
              <FlexBoxX justifyContent="space-between" width="100%" mb="8px">
                <FlexBoxX flexGrow={0}>
                  <Typography.Title style={{ marginBottom: 0 }} level={3}>
                    {companyVendor?.vendor?.name || companyVendor.vendor_name}
                  </Typography.Title>
                </FlexBoxX>

                {companyVendor?.vendor?.is_public && (
                  <Button
                    style={{ width: 120 }}
                    onClick={() => onOpenVendorProfile(companyVendor?.vendor, companyVendor)}
                  >
                    More
                  </Button>
                )}

                {!companyVendor?.company_vendor_contacts?.length && (
                  <Button style={{ width: 120 }} onClick={() => onOpenCompanyVendorUpdate(companyVendor)}>
                    Edit
                  </Button>
                )}
              </FlexBoxX>

              {companyVendor?.vendor?.description && (
                <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
                  {companyVendor.vendor.description}
                </Typography.Paragraph>
              )}
            </FlexBoxY>

            <AntdList
              grid={{
                gutter: 20,
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 2,
                xxl: 3,
              }}
              locale={{ emptyText: 'No contacts' }}
              dataSource={companyVendor.company_vendor_contacts}
              renderItem={(companyVendorContact) => (
                <AntdList.Item>
                  <CompanyVendorContactCard
                    name={
                      companyVendorContact.vendor_user
                        ? companyVendorContact.vendor_user.full_name
                        : companyVendorContact.full_name
                    }
                    email={
                      companyVendorContact.vendor_user
                        ? companyVendorContact.vendor_user.email
                        : companyVendorContact.email
                    }
                    nickname={companyVendorContact.nickname}
                    phone={companyVendorContact?.phone_number}
                    headline={companyVendorContact?.vendor_user?.headline}
                    isActive={companyVendorContact.active}
                    id={companyVendorContact.id}
                    onChange={(isActive) => handleChangeActive(isActive, companyVendor.id, companyVendorContact.id)}
                    onClick={() => handleOpenCompanyVendorContactUpdate(companyVendor, companyVendorContact.id)}
                  />
                </AntdList.Item>
              )}
            />

            {companyVendorListStore.records.length - 1 !== index && <Divider />}
          </React.Fragment>
        ))}

        {companyVendorListStore.hasMore && (
          <FlexBoxX width="100%" p={16}>
            <Button
              type="primary"
              ghost
              loading={companyVendorListStore.isFetching}
              onClick={companyVendorListStore.fetchNextPage}
            >
              Load More
            </Button>
          </FlexBoxX>
        )}
      </>
    )
  },
)
