import React from 'react'

import moment from 'moment'

import { Typography, Tooltip } from 'antd'

import { BoxProps } from 'common/components/boxes'
import { IntegrationsSourceSystem } from 'common/server/server_types'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'

type Sage100SyncStatusDetailedProps = {
  isSynced: boolean
  manual_exported_at?: string
} & BoxProps

const style = { fontSize: 14, fontWeight: 400 }

export const Sage100SyncStatusDetailded = ({ isSynced, manual_exported_at }: Sage100SyncStatusDetailedProps) => {
  if (!isSynced) {
    return (
      <Typography.Text type="danger" style={style}>
        Not Synced
      </Typography.Text>
    )
  }

  const formattedDate = manual_exported_at
    ? moment(manual_exported_at).format('MM/DD/YYYY [at] hh:mm a')
    : 'Unknown date'

  return (
    <Tooltip title="CSV downloaded, user must manually upload to Sage 100" placement="bottom">
      <IntegrationIcons type={IntegrationsSourceSystem.SAGE_100} style={{ width: 32, height: 32 }} />

      <Typography.Text type="secondary" style={style}>
        Synced on {formattedDate}
      </Typography.Text>
    </Tooltip>
  )
}
