import React from 'react'

import { Copy } from '@styled-icons/zondicons/Copy'

import { Button, Tooltip } from 'antd'

import { useInvoice } from '../../context'

export const CreateGroup = () => {
  const { invoiceMaterialsFiltered, handleSaveInvoiceMaterialsGroup } = useInvoice()

  const hasSelectedGroups = React.useMemo(
    () => invoiceMaterialsFiltered.groups.some((group) => group.checked),
    [invoiceMaterialsFiltered.groups],
  )
  const hasNoSelectedMaterials = React.useMemo(
    () => invoiceMaterialsFiltered.materials.every((material) => !material.checked),
    [invoiceMaterialsFiltered.materials],
  )

  const disabled = React.useMemo(() => {
    return hasSelectedGroups || hasNoSelectedMaterials
  }, [hasSelectedGroups, hasNoSelectedMaterials])

  const handleCreateGroup = React.useCallback(() => {
    const selectedMaterials = invoiceMaterialsFiltered.materials.filter((material) => material.checked)
    const groupName = `Group ${invoiceMaterialsFiltered.groups.length + 1}`

    handleSaveInvoiceMaterialsGroup(groupName, selectedMaterials)
  }, [invoiceMaterialsFiltered, handleSaveInvoiceMaterialsGroup])

  const TooltipRender = React.useCallback(
    ({ children }: { children: React.ReactNode }) => {
      if (!disabled) return <>{children}</>
      const title = hasSelectedGroups
        ? 'You cannot create new groups when groups are selected!'
        : 'You cannot create new groups when no items are selected!'
      return <Tooltip title={title}>{children}</Tooltip>
    },
    [disabled, hasSelectedGroups],
  )

  return (
    <>
      <TooltipRender>
        <Button
          type="ghost"
          onClick={handleCreateGroup}
          icon={<Copy style={{ width: 12, marginRight: 8 }} />}
          disabled={disabled}
        >
          Group items
        </Button>
      </TooltipRender>
    </>
  )
}
