import React from 'react'

import { Form, Input, Row, Col } from 'antd'

export const CompanyVendorContactForm = () => {
  return (
    <Row gutter={24}>
      <Col xs={24} sm={12}>
        <Form.Item name={['contact', 'first_name']} label="First Name">
          <Input required />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item name={['contact', 'last_name']} label="Last Name">
          <Input required />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          name={['contact', 'nickname']}
          label="Nickname"
          tooltip="You can add a nickname to the contact and it will appear in all places right after the contact name, like: [First Name] [Last Name] ([Nickname])"
        >
          <Input />
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item name={['contact', 'phone_number']} label="Phone Number">
          <Input />
        </Form.Item>
      </Col>
    </Row>
  )
}
