import { act, renderHook } from '@testing-library/react-hooks'

import { useNotifyWarehouse } from 'common/hooks/use-notify-warehouse'

import { useStores } from 'contractor/hooks/use-stores'
import { useUpdateWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-update-warehouse'

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

jest.mock('common/hooks/use-notify-warehouse', () => ({
  useNotifyWarehouse: jest.fn(),
}))

const mockUpdateWarehouse = jest.fn()
const mockNotifyWarehouse = jest.fn()

beforeEach(() => {
  jest.clearAllMocks()
  ;(useStores as jest.Mock).mockReturnValue({
    warehouseStore: {
      updateWarehouse: mockUpdateWarehouse,
    },
  })
  ;(useNotifyWarehouse as jest.Mock).mockReturnValue({
    notifyWarehouse: mockNotifyWarehouse,
  })
})

describe('useUpdateWarehouse Hook', () => {
  const setup = () => renderHook(() => useUpdateWarehouse())

  it('should update a warehouse successfully', async () => {
    const mockOnSuccess = jest.fn()
    const mockSetSubmitting = jest.fn()
    mockUpdateWarehouse.mockResolvedValueOnce(undefined)

    const { result } = setup()

    await act(async () => {
      await result.current.updateWarehouse({
        payload: { id: '1', name: 'Updated Warehouse', project_id: 1, address_id: 1 },
        onSuccess: mockOnSuccess,
        setSubmitting: mockSetSubmitting,
      })
    })

    expect(mockSetSubmitting).toHaveBeenCalledWith(true)
    expect(mockUpdateWarehouse).toHaveBeenCalledWith({
      id: '1',
      name: 'Updated Warehouse',
      project_id: 1,
      address_id: 1,
    })
    expect(mockNotifyWarehouse).toHaveBeenCalledWith({
      message: 'Warehouse updated!',
      warehouseId: '1',
    })
    expect(mockOnSuccess).toHaveBeenCalled()
    expect(mockSetSubmitting).toHaveBeenCalledWith(false)
  })

  it('should handle errors during warehouse update', async () => {
    const mockOnError = jest.fn()
    const mockSetSubmitting = jest.fn()
    const mockError = new Error('Update failed')
    mockUpdateWarehouse.mockRejectedValueOnce(mockError)

    const { result } = setup()

    await act(async () => {
      await result.current.updateWarehouse({
        payload: { id: '2', name: 'Failed Update', project_id: 2, address_id: 2 },
        onError: mockOnError,
        setSubmitting: mockSetSubmitting,
      })
    })

    expect(mockSetSubmitting).toHaveBeenCalledWith(true)
    expect(mockUpdateWarehouse).toHaveBeenCalledWith({
      id: '2',
      name: 'Failed Update',
      project_id: 2,
      address_id: 2,
    })
    expect(mockNotifyWarehouse).not.toHaveBeenCalled()
    expect(mockOnError).toHaveBeenCalledWith(mockError)
    expect(mockSetSubmitting).toHaveBeenCalledWith(false)
  })
})
