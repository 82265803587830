import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

import { Stock } from '../'

export function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/consolidated_stocks`

  return axios.get<StandardIndexResponse<Stock[]>>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}
