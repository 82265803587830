import React from 'react'

import { LocalShipping } from '@styled-icons/material'

import { ShopFilled } from '@ant-design/icons'

import { InventoryOperationTypes } from 'common/server/server_types'

type OperationIconProps = {
  operationType: InventoryOperationTypes
}

export const OperationIcon = ({ operationType }: OperationIconProps) => {
  if (operationType === InventoryOperationTypes.PICK_UP) {
    return <ShopFilled />
  }
  return <LocalShipping size="15" />
}
