import React from 'react'

import moment from 'moment'

import styled from '@emotion/styled'

import { Button, message, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'

import { observer } from 'mobx-react-lite'

import { FlexBoxY } from 'common/components/boxes'
import { useQuery } from 'common/hooks/use-query'

import { CopyInviteLink } from 'contractor/components/CopyInviteLink'
import { useStores } from 'contractor/hooks/use-stores'
import { GroupSelect } from 'contractor/pages/CompanySettings/group_select'
import { ManagedUser } from 'contractor/server/company_settings/manage_users'

import { RoleSelect } from './role_select'
import { TableActions } from './table_actions'

const TableStyled = styled(Table)`
  width: 100%;

  tr.disabled-table-row:hover > td,
  .disabled-table-row {
    background-color: #f5f5f5;
  }
`

type UsersTableProps = {
  filter: string
  onReplace: (user: ManagedUser) => void
}

export const UsersTable = observer((props: UsersTableProps) => {
  const { filter, onReplace } = props

  const { companySettingStore, userStore, projectGroupsStore } = useStores()

  useQuery(projectGroupsStore.indexProjectGroups)

  if (companySettingStore.filteredUsers.length === 0) {
    return null
  }

  const data = () => {
    const users = companySettingStore.filteredUsers
    if (filter) {
      return [...users.filter((user) => user?.role?.id === filter)]
    }
    return [...users]
  }

  const handleResendInvitation = async (record) => {
    try {
      await companySettingStore.resendInvitation(record)
      message.success('Resent invitation')
    } catch (error) {
      message.error('Failed to resend invitation')
    }
  }

  const columns: ColumnsType<ManagedUser> = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => <a href={`mailto:${text}`}>{text}</a>,
    },
    {
      title: 'Joined At',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 170,
      render: (created_at, record) => {
        const isExpired = moment(record.invitation_expires_at).isBefore(moment())
        return record.last_sign_in_at || !record.invitation_sent_at ? (
          <div>{moment(created_at).format('lll')}</div>
        ) : (
          <FlexBoxY alignItems="flex-start">
            Invited At: {moment(record['invitation_sent_at']).format('lll')}
            {userStore.canManageCompanySettings && (
              <Button
                data-cy={`company-setting-resend-invitation-${record.id}`}
                type="link"
                disabled={!record.is_active}
                style={{ padding: '0' }}
                onClick={() => handleResendInvitation(record)}
              >
                Resend invitation
              </Button>
            )}
            {userStore.canManageCompanySettings && (
              <CopyInviteLink isExpired={isExpired} record_id={record.id} invitation_link={record.invitation_link} />
            )}
          </FlexBoxY>
        )
      },
    },
    {
      title: 'Deactivated At',
      dataIndex: 'deactivated_at',
      key: 'deactivated_at',
      width: 170,
      render: (deactivated_at, record) => {
        if (deactivated_at && !record.is_active) {
          return <div>{moment(deactivated_at).format('lll')}</div>
        }
      },
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      width: 212,
      render: (_, record) => <RoleSelect {...record} />,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      render: (_, record) => <GroupSelect {...record} />,
    },
    {
      align: 'right',
      key: 'actions',
      width: 64,
      render: (_, record) => <TableActions onReplace={onReplace} {...record} />,
    },
  ]

  return (
    <TableStyled
      data-cy="company-setting-users-table"
      rowKey={'id'}
      dataSource={data()}
      columns={columns}
      pagination={false}
      rowClassName={(record: ManagedUser) => {
        return !record?.is_active ? 'disabled-table-row' : ''
      }}
      scroll={{ x: 1250 }}
    />
  )
})
