import theme from 'common/styles/theme'

import { getRemainingPriceColor } from '..'

describe('[helpers: getRemainingPriceColor]', () => {
  it('should return gray color when remaining is zero', () => {
    const color = getRemainingPriceColor(0)
    expect(color).toBe(theme.colors['gray-7'])
  })

  it('should return link color when remaining is positive', () => {
    const positiveValues = [1, 10, 100, 0.1]

    positiveValues.forEach((value) => {
      const color = getRemainingPriceColor(value)
      expect(color).toBe(theme.colors.link)
    })
  })

  it('should return error color when remaining is negative', () => {
    const negativeValues = [-1, -10, -100, -0.1]

    negativeValues.forEach((value) => {
      const color = getRemainingPriceColor(value)
      expect(color).toBe(theme.colors.error)
    })
  })
})
