import React from 'react'

import { Empty } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { Loading } from 'common/components/Loading'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'
import { NoWarehouseSelected } from 'contractor/pages/Inventory/common/components/NoWarehouseSelected'

import { RequestRow } from './RequestRow'

export const RequestList = observer(() => {
  const {
    inventoryRequestStore: { listStore },
  } = useStores()
  const persistentFilters = usePersistentFilters()

  if (listStore.isFetching) return <Loading />

  // We must not load the list if the warehouse is not selected
  if (!listStore.searchState.filters['warehouse_id']) {
    return (
      <NoWarehouseSelected
        value={listStore.searchState.filters['warehouse_id']}
        onChange={(value) => persistentFilters.handleChangeFilters({ warehouse_id: value })}
      />
    )
  }

  const consolidatedRequests = toJS(listStore.records)

  if (!consolidatedRequests.length) return <Empty description="No results found." />

  return (
    <>
      {consolidatedRequests.map((consolidatedRequest) => (
        <RequestRow key={consolidatedRequest.id} consolidatedRequest={consolidatedRequest} />
      ))}
    </>
  )
})
