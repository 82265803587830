import { renderHook } from '@testing-library/react-hooks'

import { toJS } from 'mobx'

import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

import { useGetUnits } from '../use-get-units'

jest.mock('mobx', () => ({
  toJS: jest.fn((x) => x),
}))

jest.mock('common/hooks/use-query', () => ({
  useQuery: jest.fn(),
}))

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

describe('useGetUnits', () => {
  const mockUnits = [
    { id: 1, name: 'Unit A' },
    { id: 2, name: 'Unit B' },
  ]

  const mockUnitsStore = {
    units: mockUnits,
    maybeUnits: jest.fn(),
  }

  beforeEach(() => {
    jest.clearAllMocks()
    ;(useStores as jest.Mock).mockReturnValue({
      unitsStore: mockUnitsStore,
    })
  })

  it('should return units from the MobX store', () => {
    const { result } = renderHook(() => useGetUnits())

    expect(toJS).toHaveBeenCalledWith(mockUnits)

    expect(result.current.units).toEqual(mockUnits)
  })

  it('should call `useQuery` with the correct argument', () => {
    renderHook(() => useGetUnits())

    expect(useQuery).toHaveBeenCalledWith(mockUnitsStore.maybeUnits)
  })
})
