import React from 'react'

import styled from '@emotion/styled'

import { DeleteOutlined } from '@ant-design/icons'
import { Button, Typography, Row, Col, Popconfirm, Form, InputNumber, Input } from 'antd'

import { Box } from 'common/components/boxes'
import { SelectCostCode } from 'common/components/SelectCostCode'

import { SelectCostCodePhaseAdvanced } from 'contractor/components/SelectCostCodePhaseAdvanced'

import { useCreateInventoryRequest } from '../../context'

const FormItemQty = styled(Form.Item)`
  .ant-form-item-label > label {
    width: 100%;
  }
`

type StockRequestProps = {
  remove: (index: number | number[]) => void
  name: number
}

export const StockRequest = ({ remove, name }: StockRequestProps) => {
  const { costCodes, costCodeSettings, costCodePhases } = useCreateInventoryRequest()

  return (
    <Box bg="white" borderRadius={2} p={12}>
      <Row gutter={16} align="middle">
        <Col xs={24} sm={18} md={18} lg={18} xl={9} xxl={10}>
          <Form.Item name={[name, 'stock_id']} hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name={[name, 'company_material', 'description']} label="Item">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} sm={6} md={6} lg={6} xl={3} xxl={3}>
          <FormItemQty
            name={[name, 'quantity']}
            label={
              <Box display="flex" alignItems="baseline" justifyContent="space-between" width="100%">
                <span>Qty</span>{' '}
                <Typography.Text type="secondary" style={{ fontSize: 10 }}>
                  Stock: 400
                </Typography.Text>{' '}
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Please input the qty!',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </FormItemQty>
        </Col>

        <Col xs={24} sm={7} md={7} lg={7} xl={3} xxl={3}>
          <Form.Item name={[name, 'unit', 'unit_name_label']} label="UOM">
            <Input disabled />
          </Form.Item>
        </Col>

        <Col xs={24} sm={17} md={17} lg={17} xl={9} xxl={8}>
          <Box display="flex" alignItems="flex-end" gridGap={16}>
            <Form.Item name={[name, 'cost_code']} label="Cost code" style={{ width: '100%' }}>
              <SelectCostCode costCodes={costCodes} costCodeSettings={costCodeSettings} />
            </Form.Item>
            {costCodeSettings?.independent_phase_codes_enabled && (
              <Form.Item name={[name, 'cost_code_phase']} style={{ width: '90%' }}>
                <SelectCostCodePhaseAdvanced initialCostCodePhases={costCodePhases} />
              </Form.Item>
            )}
            <Popconfirm
              placement="topRight"
              title="Are you sure to delete this item?"
              onConfirm={() => {
                remove(name)
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button
                icon={<DeleteOutlined />}
                size="small"
                danger
                type="text"
                shape="circle"
                style={{ minWidth: 24, marginBottom: 28 }}
              />
            </Popconfirm>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}
