import React from 'react'

import styled from 'styled-components'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Space, Table } from 'antd'

import { formatEnumValue } from 'common/helpers/formatters'

import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { ItemAttribute } from 'contractor/server/warehouses'

const TableContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
`

const StyledTable = styled(Table)`
  width: 100%;
  .ant-table-thead > tr > th {
    background: #ffffff;
    color: #434343;
    padding: 8px 12px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-tbody > tr > td {
    padding: 8px 12px;
    border-bottom: 1px solid #f0f0f0;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: none;
  }
`

export type AttributeTableProps = {
  onEdit: (attribute: ItemAttribute) => void
  onDelete: (name: string, type: string) => void
}

export const AttributeTable = (props: AttributeTableProps) => {
  const { onEdit, onDelete } = props
  const { warehouse } = useWarehouse()
  const itemAttributes = warehouse?.item_attributes || []
  const locationAttributes = warehouse?.location_attributes || []

  const data = [
    ...itemAttributes?.map((item) => ({
      ...item,
      type_label: 'Item',
      type: 'item_attributes',
    })),
    ...locationAttributes?.map((item) => ({
      ...item,
      type_label: 'Location',
      type: 'location_attributes',
    })),
  ]

  const columns = [
    {
      title: 'Attribute Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type_label',
      key: 'type_label',
      sorter: (a, b) => a.type_label?.localeCompare?.(b?.type_label),
    },
    {
      title: 'Attribute Type',
      dataIndex: 'custom_attribute_type',
      key: 'custom_attribute_type',
      render: (type: string) => formatEnumValue(type),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, element) => (
        <Space style={{ width: '100%', placeContent: 'end', paddingRight: 8 }} size={24}>
          <EditOutlined style={{ cursor: 'pointer' }} onClick={() => onEdit(element)} />
          <DeleteOutlined
            style={{ color: 'red', cursor: 'pointer' }}
            onClick={() => onDelete(element.name, element.type)}
          />
        </Space>
      ),
    },
  ]

  if (!data.length) return null

  return (
    <TableContainer>
      <StyledTable columns={columns} dataSource={data} pagination={false} showHeader={true} bordered={false} />
    </TableContainer>
  )
}
