import React from 'react'

import { render, screen } from '@testing-library/react'

import { DynamicTag } from '../dynamic_tag'

// Mock the `useTheme` hook
jest.mock('common/hooks/use-theme', () => ({
  useTheme: jest.fn(() => ({
    colors: {
      'geekblue-3': '#3c91f5',
      'geekblue-1': '#e6f7ff',
      'purple-3': '#9a3c91',
      'purple-1': '#f5e6f7',
    },
  })),
}))

describe('Inventory > List > StockRow > DynamicTag', () => {
  it('should render a tag with correct styles for type "item"', () => {
    render(<DynamicTag type="item">Item Tag</DynamicTag>)

    const tag = screen.getByText('Item Tag')

    expect(tag).toBeInTheDocument()

    expect(tag).toHaveStyle({
      borderColor: '#3c91f5', // geekblue-3
      backgroundColor: '#e6f7ff', // geekblue-1
    })
  })

  it('should render a tag with correct styles for type "location"', () => {
    render(<DynamicTag type="location">Location Tag</DynamicTag>)

    const tag = screen.getByText('Location Tag')

    expect(tag).toBeInTheDocument()

    expect(tag).toHaveStyle({
      borderColor: '#9a3c91', // purple-3
      backgroundColor: '#f5e6f7', // purple-1
    })
  })
})
