import { toJS } from 'mobx'

import { useQuery } from 'common/hooks/use-query'
import { Unit } from 'common/server/units'

import { useStores } from 'contractor/hooks/use-stores'

export const useGetUnits = () => {
  const { unitsStore } = useStores()

  useQuery<Unit[]>(unitsStore.maybeUnits)

  return { units: toJS(unitsStore.units) }
}
