import React from 'react'

import { Form } from 'antd'

import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { Header } from 'contractor/pages/Inventory/Warehouses/common/components/Header/header'
import { WarehouseFormCard } from 'contractor/pages/Inventory/Warehouses/common/components/WarehouseForm/FormCard/warehouse_form_card'
import { useWarehouse, withWarehouseProvider } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { useCreateWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-create-warehouse'
import { Warehouse } from 'contractor/server/warehouses'

const New = () => {
  const { projectStore, warehouseStore } = useStores()
  const { form, formatWarehousePayload, setSubmitting, handleLogError, goBack } = useWarehouse()

  const { createWarehouse } = useCreateWarehouse()

  const { isLoading } = useQuery(() => {
    return Promise.all([projectStore.maybeIndexProjects()])
  })

  const handleFinish = async (formValues: Warehouse) => {
    const warehousePayload = formatWarehousePayload(formValues)
    const addressPayload = {
      address_line_1: formValues?.address_line_1,
      address_line_2: formValues?.address_line_2,
      address_to: formValues?.address_to,
      city: formValues?.city,
      nickname: formValues?.nickname,
      phone_number: formValues?.phone_number,
      state: formValues?.state,
      zip_code: formValues?.zip_code,
    }

    await createWarehouse({
      payload: warehousePayload,
      setSubmitting,
      onError: handleLogError,
      onSuccess: goBack,
      addressPayload,
      addAddress: warehouseStore.addAddress,
    })
  }

  return (
    <Form form={form} onFinish={handleFinish} layout="vertical" style={{ width: '100%', height: '100%' }}>
      <Page>
        <Page.Header>
          <Header />
        </Page.Header>

        <Page.Content
          p={0}
          px={{ _: 0, sm: 16 }}
          width="100%"
          py={16}
          alignItems="flex-start"
          justifyContent="center"
          display="flex"
        >
          <WarehouseFormCard isLoading={isLoading} />
        </Page.Content>
      </Page>
    </Form>
  )
}

export const NewWarehouse = withWarehouseProvider(New)
