import { renderHook, act } from '@testing-library/react-hooks'
import { Option as CompanyVendorSelectorOption } from 'javascript/contractor/pages/CompanyVendorContacts/MyVendors/Create/company_vendor_selector'
import { IndexCompanyVendor } from 'javascript/contractor/server/company_vendors'
import type { Vendor } from 'javascript/contractor/server/vendors'

import { useVendorDomainValidation } from '../vendorContactValidation'

describe('useVendorDomainValidation', () => {
  const mockCompanyVendor = { id: '1', name: 'Test Corp', vendor_name: 'Test Corp' } as IndexCompanyVendor
  const mockVendor = { id: '1', name: 'Test Corp', domain: 'test.com' } as Vendor
  const mockSelectorOption = { value: '1', label: 'Test Corp' } as CompanyVendorSelectorOption

  describe('validateDomain', () => {
    it('should set domain when no vendors are selected', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      act(() => {
        result.current.validateDomain('user@test.com', undefined, undefined)
      })
      expect(result.current.contactDomain).toBe('test.com')
    })

    it('should clear domain when company vendor is selected', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      act(() => {
        result.current.validateDomain('user@test.com', mockCompanyVendor, undefined)
      })
      expect(result.current.contactDomain).toBe('')
    })

    it('should clear domain when vendor is selected', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      act(() => {
        result.current.validateDomain('user@test.com', undefined, mockVendor)
      })
      expect(result.current.contactDomain).toBe('')
    })
  })

  describe('checkDomainMatch', () => {
    it('should not throw for matching domains', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      expect(() => result.current.checkDomainMatch('user@test.com', 'test.com')).not.toThrow()
    })

    it('should throw vendorDomain error for mismatched domains', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      expect(() => result.current.checkDomainMatch('user@test.com', 'example.com')).toThrow(
        expect.objectContaining({ error: 'vendorDomain' }),
      )
    })

    it('should whitelist gmail.com domains', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      expect(() => result.current.checkDomainMatch('user@gmail.com', 'example.com')).not.toThrow()
    })
  })

  describe('state management', () => {
    it('should update contact domain state', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      act(() => {
        result.current.setContactDomain('newdomain.com')
      })
      expect(result.current.contactDomain).toBe('newdomain.com')
    })

    it('should update matched vendor state', () => {
      const { result } = renderHook(() => useVendorDomainValidation())
      act(() => {
        result.current.setMatchVendorByDomain(mockSelectorOption)
      })
      expect(result.current.matchVendorByDomain).toEqual(mockSelectorOption)
    })
  })
})
