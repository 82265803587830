import React from 'react'

import { useParams } from 'react-router'

import { LeftCircleFilled } from '@ant-design/icons'
import { Select, Typography, SelectProps } from 'antd'

import { Box } from 'common/components/boxes'
import { formatName } from 'common/helpers/formatters'
import { useQuery } from 'common/hooks/use-query'
import { useTheme } from 'common/hooks/use-theme'

import { useStores } from 'contractor/hooks/use-stores'

export type ActionStatusProps = { allowedNextStateIds?: string[] } & SelectProps

export const ActionStatus = ({ onChange, allowedNextStateIds = [], ...props }: ActionStatusProps) => {
  const { companySettingStore, warehouseStore } = useStores()

  const theme = useTheme()
  const params = useParams<{ warehouseId: string }>()

  useQuery(companySettingStore.indexUsers)
  const { isLoading, data: inventoryRequestStates = [] } = useQuery(
    () => warehouseStore.getInventoryRequestStates(params.warehouseId),
    [params.warehouseId],
  )

  return (
    <Select
      style={{ width: 200 }}
      placeholder="Status"
      optionFilterProp="children"
      loading={isLoading}
      onSelect={onChange}
      {...props}
    >
      {inventoryRequestStates?.map((state) => {
        const assigneeName = state.default_assignee?.first_name
          ? formatName(state.default_assignee?.first_name, state.default_assignee?.last_name)
          : state.default_assignee?.email

        const disabledOption = ![...allowedNextStateIds, props?.value].includes(state?.id)
        const color = disabledOption ? theme.colors['gray-3'] : theme.colors[`${state?.color || 'gray'}-6`]

        return (
          <Select.Option key={state?.label} value={state?.id} disabled={disabledOption}>
            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
              <Typography.Text ellipsis type={disabledOption ? 'secondary' : undefined}>
                {state?.label}
              </Typography.Text>
              <LeftCircleFilled
                style={{
                  color,
                  borderColor: theme.colors['gray-6'],
                  border: '1px solid',
                  borderRadius: 8,
                  marginLeft: 7,
                }}
              />
            </Box>

            {state.default_assignee && props?.value !== state.id && (
              <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                {`assigns to ${assigneeName}`}
              </Typography.Text>
            )}
          </Select.Option>
        )
      })}
    </Select>
  )
}
