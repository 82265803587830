import React from 'react'

import styled from '@emotion/styled'

import { FilePdfOutlined, UnorderedListOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { Button, Card, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX } from 'common/components/boxes'
import { PdfViewer as PdfViewerComp } from 'common/components/PdfViewer'
import { InvoiceResponse } from 'common/server/invoice'

import { useInvoice } from '../../context'
import { OrderSearch } from '../../OrderDetailNew'
import { ReconciliationOrderView } from '../ReconciliationOrderView'
import { PdfButton } from './pdf_button'
import { PdfSelect } from './pdf_select'

const pdfFileIndex = 0

const ButtonGroup = styled(Box)<{ isOpened: boolean }>`
  display: flex;
  flex-direction: ${({ isOpened }) => (isOpened ? 'row' : 'column')};

  & > button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  & > button:last-of-type {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

export type PdfViewerProps = {
  onAttachOrders: (orderIds: string[]) => void
  toggleLinkOrderModal: (visible: boolean) => void
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
}

export const PdfViewer = observer(({ onAttachOrders, toggleLinkOrderModal, saveInvoice }: PdfViewerProps) => {
  const {
    invoice,
    selectedPdfInvoice,
    handleReconcile,
    selectedOrderMaterialIdsToReconcile,
    selectedInvoiceMaterials,
    selectedOrders,
    modeView,
    setModeView,
    currentReconcileStatus,
    setCurrentReconcileStatus,
    pdfViewMode,
    setPdfViewMode,
  } = useInvoice()

  const isOpened = modeView === 'reconciliation'
  const setIsOpened = (isOpened: boolean) => setModeView(isOpened ? 'reconciliation' : 'review')

  const getModeViewType = (mode) => {
    return mode === pdfViewMode ? 'primary' : 'default'
  }
  const getIconExpand = () => (isOpened ? <ArrowLeftOutlined /> : <ArrowRightOutlined />)

  const handleModeView = (mode) => {
    setPdfViewMode(mode)
    setIsOpened(true)
  }

  const handleOpenSidebar = () => {
    if (currentReconcileStatus === 'all_order_items') setCurrentReconcileStatus('all')
    setIsOpened(!isOpened)
  }

  const reconcileBtnDisabled = React.useMemo(() => {
    return !selectedOrderMaterialIdsToReconcile.length
  }, [selectedOrderMaterialIdsToReconcile, selectedInvoiceMaterials])

  const style = React.useMemo(
    () => ({
      wrapper: {
        flex: isOpened ? 1 : 0,
        gap: 16,
      },
      card: {
        display: 'flex',
        'flex-direction': 'column',
      },
      btnGroup: {
        display: 'flex',
        'flex-direction': isOpened ? 'row' : 'column-reverse',
        gap: 12,
      },
      btnAddFile: {
        ...(isOpened ? {} : { display: 'none' }),
      },
    }),
    [isOpened],
  )

  const renderTitle = React.useMemo(() => {
    if (!isOpened) return null
    if (pdfViewMode === 'pdf') return <PdfSelect />
    return <Typography.Text strong>Order Items</Typography.Text>
  }, [pdfViewMode, isOpened])

  const renderBtnAction = () => {
    if (!isOpened) return null

    if (pdfViewMode === 'order_items') {
      return (
        <Button
          style={style.btnAddFile}
          type="primary"
          size="small"
          onClick={handleReconcile}
          disabled={reconcileBtnDisabled}
        >
          Reconcile
        </Button>
      )
    }

    return <PdfButton />
  }

  const renderContent = React.useMemo(() => {
    if (!isOpened) return null
    if (pdfViewMode === 'pdf') {
      return (
        <PdfViewerComp
          url={selectedPdfInvoice || invoice.extracted_files[pdfFileIndex]?.url}
          key={`${invoice.id}-expand`}
          style={{ height: '100%', minHeight: '100%', borderRadius: 4 }}
          loading={!invoice?.id}
        />
      )
    }

    if (!selectedOrders.length) {
      return (
        <Card style={style.card} bodyStyle={{ padding: 12 }}>
          <OrderSearch
            onSelect={(order) => {
              onAttachOrders([order.id])
              toggleLinkOrderModal(false)
            }}
            onCreated={(order) => {
              onAttachOrders([order.id])
              toggleLinkOrderModal(false)
            }}
            initialValues={{
              grandTotal: invoice?.total_amount,
              orderNumber: invoice?.extracted_order_number,
              companyVendorId: invoice?.company_vendor_id,
            }}
            onClickToCreateQuickPO={() => toggleLinkOrderModal(false)}
            saveInvoice={saveInvoice}
          />
        </Card>
      )
    }
    return <ReconciliationOrderView mode="edit" />
  }, [pdfViewMode, invoice, isOpened, saveInvoice, selectedOrders?.length, selectedPdfInvoice, onAttachOrders])

  return (
    <Box display="flex" flexDirection="column" style={style.wrapper}>
      <Card style={style.card} bodyStyle={{ padding: 0 }}>
        <FlexBoxX style={style.btnGroup} p={12} justifyContent="space-between">
          <Box display="flex" style={{ gap: 12 }}>
            <ButtonGroup isOpened={isOpened}>
              <Button
                icon={<FilePdfOutlined />}
                type={getModeViewType('pdf')}
                size="small"
                onClick={() => handleModeView('pdf')}
              />
              <Button
                icon={<UnorderedListOutlined />}
                type={getModeViewType('order_items')}
                size="small"
                onClick={() => handleModeView('order_items')}
                disabled={currentReconcileStatus === 'all_order_items'}
              />
            </ButtonGroup>

            {renderTitle}
          </Box>

          <Box display="flex" style={{ gap: 8 }}>
            {renderBtnAction()}
            <Button icon={getIconExpand()} size="small" onClick={handleOpenSidebar} />
          </Box>
        </FlexBoxX>
      </Card>

      {renderContent}
    </Box>
  )
})
