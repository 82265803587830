import React from 'react'

import { useHistory } from 'react-router-dom'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Card, Col, Divider, Popconfirm, Row, Space, Tag, Typography } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { useDeleteWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-delete-warehouse'
import { AttributesTags } from 'contractor/pages/Inventory/Warehouses/List/Components/WarehouseList/attributes_tags'
import { Warehouse } from 'contractor/server/warehouses'

type WarehouseProps = {
  warehouse: Warehouse
  refetch?: () => void
}

export const WarehouseCard = (props: WarehouseProps) => {
  const { warehouse, refetch } = props
  const history = useHistory()
  const theme = useTheme()
  const { handleLogError } = useWarehouse()
  const projectName = warehouse.project_name || 'None'

  const { deleteWarehouse } = useDeleteWarehouse()
  const handleDeleteWarehouse = () => {
    deleteWarehouse({
      id: warehouse.id,
      onError: handleLogError,
      onSuccess: refetch,
    })
  }

  const handleEditWarehouse = () => {
    history.push(`/warehouses/${warehouse.id}`)
  }

  return (
    <Card
      id={warehouse.id}
      title={name}
      aria-label={`project-card-${warehouse.name.toLowerCase()}`}
      hoverable
      bodyStyle={{ padding: '0' }}
      data-cy={`project-card-${warehouse.name.toLowerCase()}`}
    >
      <Row
        wrap={false}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '12px 16px',
          gap: '18px',
        }}
      >
        <Col flex="auto">
          <Typography.Text style={{ color: theme.colors['gray-11'] }}>{warehouse.name}</Typography.Text>
          <Row style={{ marginTop: 4 }} gutter={4} align="middle">
            <Col>
              <Typography.Text>Project</Typography.Text>
            </Col>
            <Col>
              <Tag color="default">{projectName}</Tag>
            </Col>
          </Row>
        </Col>

        <Col>
          <Space size={16}>
            <Button
              onClick={handleEditWarehouse}
              icon={<EditOutlined style={{ color: theme.colors['gray-9'] }} />}
              style={{ padding: '0 7px', height: 30 }}
              type="text"
            />
            <Popconfirm
              title={`Are you sure you want to delete ${warehouse.name}?`}
              okType={'danger'}
              okText={'Delete'}
              onConfirm={handleDeleteWarehouse}
            >
              <Button
                icon={<DeleteOutlined style={{ color: theme.colors['red-6'] }} />}
                style={{ padding: '0 7px', height: 30 }}
                type="text"
              />
            </Popconfirm>
          </Space>
        </Col>
      </Row>

      <Divider style={{ margin: 0 }} />

      <div style={{ padding: 16 }}>
        <Row gutter={12}>
          <Col span={12}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Text>Location attribute</Typography.Text>
            </div>
            <AttributesTags attributes={warehouse?.location_attributes} />
          </Col>

          <Col span={12}>
            <div style={{ marginBottom: 4 }}>
              <Typography.Text>Item attribute</Typography.Text>
            </div>
            <AttributesTags attributes={warehouse?.item_attributes} />
          </Col>
        </Row>
      </div>
    </Card>
  )
}
