import React from 'react'

import { Copy, Search, Undo } from '@styled-icons/boxicons-regular'

import { Button, Checkbox, Input } from 'antd'

import { Box, FlexBoxY } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { DrawerEditGroupModeType } from './types'

interface SearchBarProps {
  mode: DrawerEditGroupModeType
  groupName: string
  searchTerm: string
  allChecked: boolean
  onSetGroupName: (name: string) => void
  onSetSearchTerm: (term: string) => void
  onToggleAll: () => void
  onSetMode: (mode: DrawerEditGroupModeType) => void
}

export const SearchBar = React.memo(
  ({
    mode,
    groupName,
    searchTerm,
    allChecked,
    onSetGroupName,
    onSetSearchTerm,
    onToggleAll,
    onSetMode,
  }: SearchBarProps) => {
    return (
      <FlexBoxY
        alignItems="center"
        pt={12}
        px={12}
        position="sticky"
        top={0}
        zIndex={1}
        paddingY={12}
        backgroundColor={theme.colors.white}
        style={{ gap: 10 }}
      >
        <Box display="flex" alignItems="center" width="100%" style={{ gap: 10 }}>
          <Checkbox onChange={onToggleAll} checked={allChecked} disabled={mode === 'view'} />
          <Input
            placeholder="Group name"
            style={{ width: '50%' }}
            value={groupName}
            onChange={(e) => onSetGroupName(e.target.value)}
          />
          <Input
            value={searchTerm}
            onChange={(e) => onSetSearchTerm(e.target.value)}
            placeholder="Extracted items..."
            style={{ width: '50%' }}
            prefix={<Search style={{ width: 16 }} />}
          />
        </Box>

        <Box display="flex" width="100%" style={{ gap: 10 }}>
          <Box width={17} />
          <Button
            type={mode === 'add' ? 'primary' : 'default'}
            style={{ width: '50%' }}
            icon={<Copy style={{ width: 16, marginRight: 5 }} />}
            onClick={() => onSetMode('add')}
          >
            Add to Group
          </Button>
          <Button
            type={mode === 'ungroup' ? 'primary' : 'default'}
            style={{ width: '50%' }}
            icon={<Undo style={{ width: 16, marginRight: 5 }} />}
            onClick={() => onSetMode('ungroup')}
          >
            Ungroup
          </Button>
        </Box>
      </FlexBoxY>
    )
  },
)
