import axios from 'axios'

import {
  AvailableCommitmentsResponse,
  InvoiceMaterialsResponse,
  InvoiceResponse,
  UnreconciledInvoiceResponse,
} from 'common/server/invoice'
import { InvoiceHistoryResponse } from 'common/server/invoice_history'
import {
  InvoicesStatuses,
  SearchKeysResponse,
  StandardIndexRequest,
  StandardIndexResponse,
} from 'common/server/server_types'

export interface InvoiceHit {
  id: string
  number?: string
  status: InvoicesStatuses
  total_amount?: number
  orders: {
    id: string
    name: string
  }[]
  order_names: string
  projects: {
    id: string
    name: string
  }[]
  project_names: string
  due_date?: string
  document_date?: string
  posted_at?: string
  company_vendor_name?: string
  company_id: string
  assigned_to?: string
  created_at: string
  updated_at: string
  files: { url: string; filename: string }[]
  rejection_reason?: string
  state: {
    id: string
    label: string
    color: string
  }
}

export interface InvoicePayload {
  company_vendor_id?: string
  watcher_ids?: string[]
  order_ids?: string[]
  state_id?: string
  assigned_to_company_user_id?: string
  tags?: string[]
  rejection_reason?: string
  name?: string
  number?: string
  document_date?: string
  accounting_date?: string
  total_amount?: string | number
  subtotal_amount?: string | number
  tax_amount?: string | number
  shipping_cost?: number
  discount_amount?: number
  other_costs?: number
  seller_name?: string
  comment?: string
  project_id?: string
  invoice_materials?: {
    id: string
    description?: string
    uom?: string
    quantity_shipped?: number
    unit_price?: number
    extended_price?: number
    order_material_id?: string
    auto_calculated_ext_price?: boolean
  }[]
  invoice_materials_groups?: {
    id: string
    name?: string
    unit_name?: string
    quantity_shipped?: number
    unit_price?: number
    extended_price?: number
    invoice_materials_ids?: string[]
  }[]
  extra_files_signed_ids?: string[]
  extra_files_delete_ids?: string[]
}

export interface CreateInvoicePayload {
  project_id?: string
  order_ids: string[]
  assigned_to_company_user_id?: string
  company_vendor_id?: string
  document_date?: string
  accounting_date?: string
  due_date?: string
  file_signed_id?: string
  name?: string
  number?: string
  total_amount?: number
  invoice_inbox_id?: string
  file_id?: string
}

export type StateCounts = {
  [stateName: string]: number
}

export type IndexResponse = StandardIndexResponse<IndexInvoiceRecord> & {
  state_counts: StateCounts
}

export interface IndexInvoiceRecord {
  id: string
  invoice_id: string
  company_id: string
  company_vendor_name: string
  invoice_number: string
  assigned_to_id: string | null
  assigned_to_label: string | null
  invoice_created_at: string | null
  invoice_updated_at: string | null
  state_name: string
  state: { id: string; label: string; color: string }
  project_names: string[]
  project_numbers_names: string[]
  order_names: string
  invoice_date: string
  due_date: string
  posted_at: string
  total_amount: number
  tags: string[]
  agave_sync_status: string
  rejection_reason: string
  files: { url: string; filename: string }[]
  discarded_at?: string
  discarded_by_company_user_id?: string
  discarded_reason?: string
  discarded_explained?: string
}

export interface FacetsInvoice {
  company_vendor_names: string[]
  tags: string[]
  sync_statuses: string[]
  assigned_to_labels: string[]
}

export interface ReportInvoiceIssuePayload {
  issue_message: string
  invoice_inbox_attachment_id: string
}

export interface ReportInvoiceInboxIssuePayload {
  issue_message: string
  invoice_inbox_attachment_ids?: string[]
}

export interface ReportInvoiceInboxTipPayload {
  tip_message: string
}

export function search_key() {
  return axios.get<SearchKeysResponse>('/invoices/search_key')
}

export function show(id: string) {
  return axios.get<InvoiceResponse>(`/invoices/${id}`)
}

export function showInvoiceMaterials(id: string, params: Pick<StandardIndexRequest, 'search'>) {
  const url = `/invoices/${id}/invoice_materials`

  return axios.get<InvoiceMaterialsResponse>(url, { params })
}

export function update(id: string, invoice: InvoicePayload, auto_match?: boolean) {
  return axios.patch<InvoiceResponse>(`/invoices/${id}`, { invoice, auto_match })
}

export function discard(id: string, discard_reason: string, discard_explained?: string) {
  return axios.patch(`/invoices/${id}/discard`, { discard_reason, discard_explained })
}

export function getInvoiceTags() {
  return axios.get<{ invoice_tags: string[] }>('/invoices/settings')
}

export function create(invoice: CreateInvoicePayload) {
  return axios.post<InvoiceResponse>('/invoices', { invoice })
}

export function issue(id: string, payload: ReportInvoiceIssuePayload) {
  return axios.post(`/invoices/${id}/issue`, { report_issue: payload })
}

export function history(id: string) {
  return axios.get<{ history: InvoiceHistoryResponse[] }>(`/invoices/${id}/history`)
}

export function unreconciledInvoices(payload: {}) {
  return axios.get<UnreconciledInvoiceResponse[]>(`/invoices/unreconciled_invoices`, payload)
}

export function availableCommitments(id: string, params: {}) {
  return axios.get<AvailableCommitmentsResponse[]>(`/invoices/${id}/available_commitments`, { params })
}

export function attachInvoicesToCommitment(invoice_ids: string[], commitment_id: string) {
  return axios.post(`/invoices/attach_invoices_to_commitment`, { invoice_ids, commitment_id })
}

export function index(request: StandardIndexRequest) {
  const url = `/consolidated_invoices`

  return axios.get<IndexResponse>(url, { params: { ...request.filters, ...request } })
}

export function facets(params = {}) {
  const url = `/consolidated_invoices/facets`

  return axios.get<FacetsInvoice>(url, { params })
}

export function internal_comment(payload: { id: string; comment: string; company_user_ids: string[] }) {
  return axios.post<InvoiceResponse>(`/invoices/${payload['id']}/internal_comment`, payload)
}

export function mark_as_extracted(payload: { id: string }) {
  return axios.patch<InvoiceResponse>(`/invoices/${payload['id']}/mark_as_extracted`, payload)
}
export function mark_as_reconciled(payload: { id: string }) {
  return axios.patch<InvoiceResponse>(`/invoices/${payload['id']}/mark_as_reconciled`, payload)
}

export function export_records(params?: StandardIndexRequest) {
  const url = `/consolidated_invoices/export`

  return axios.post(url, { ...params.filters, ...params })
}
