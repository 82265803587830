import React, { useMemo, useRef } from 'react'

import pluralize from 'pluralize'

import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Divider, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { DrawerRef } from 'common/components/Drawer'
import {
  calculateInvoiceAmounts,
  calculateOrderTotalAmount,
  extractInvoiceOrderDiscrepancy,
} from 'common/helpers/invoice'
import { roundValue } from 'common/helpers/order'
import { useTheme } from 'common/hooks/use-theme'

import { useInvoice } from '../context'
import { DrawerInvoices, DrawerOrders } from './Drawers'

export const FooterReview = () => {
  const theme = useTheme()

  const {
    calculatedGrandTotal,
    allInvoicesExceptTheCurrent,
    currencyFormatter,
    calculatedSubtotal,
    shippingCostField,
    otherCostsField,
    discountAmountField,
    taxAmountField,
    invoice,
    selectedOrders,
  } = useInvoice()

  const drawerInvoicesRef = useRef<DrawerRef>()
  const drawerOrdersRef = useRef<DrawerRef>()

  const allInvoices = useMemo(
    () => [
      {
        ...invoice,
        invoice_grand_total: calculatedGrandTotal,
        shipping_cost: shippingCostField,
        other_costs: otherCostsField,
        discount_amount: discountAmountField,
        tax_amount: taxAmountField,
      },
      ...allInvoicesExceptTheCurrent,
    ],
    [invoice?.id, calculatedGrandTotal, allInvoicesExceptTheCurrent],
  )

  const totalInvoicedAmount = useMemo(() => calculateInvoiceAmounts(allInvoices, 'invoice_grand_total'), [allInvoices])

  const ordersGrandTotal = useMemo(
    () =>
      calculateOrderTotalAmount(
        selectedOrders?.map((o) => o.order),
        'grand_total',
      ),
    [selectedOrders?.map((o) => o.order?.id)],
  )

  const ordersSubtotalTotal = useMemo(
    () =>
      calculateOrderTotalAmount(
        selectedOrders?.map((o) => o.order),
        'sub_total',
      ),
    [selectedOrders?.map((o) => o.order?.id)],
  )

  const formattedOrdersGrandTotal = useMemo(
    () => roundValue(ordersGrandTotal, invoice?.decimal_precision),
    [ordersGrandTotal, invoice?.decimal_precision],
  )

  const formattedTotalInvoicedAmount = useMemo(
    () => roundValue(totalInvoicedAmount, invoice?.decimal_precision),
    [totalInvoicedAmount, invoice?.decimal_precision],
  )

  const remaining = useMemo(
    () => formattedOrdersGrandTotal - formattedTotalInvoicedAmount,
    [formattedOrdersGrandTotal, formattedTotalInvoicedAmount],
  )

  const remainingProperties = React.useMemo(() => {
    if (remaining === 0) {
      return {
        color: theme.colors['gray-7'],
        text: '',
        icon: null,
      }
    }

    if (remaining > 0) {
      return {
        color: theme.colors.link,
        text: 'Outstanding balance to be invoiced',
        icon: <ExclamationCircleOutlined style={{ color: theme.colors.link, marginLeft: 4 }} />,
      }
    }

    if (remaining < 0 && ordersSubtotalTotal == calculatedSubtotal) {
      const message = extractInvoiceOrderDiscrepancy(allInvoices, selectedOrders)

      return {
        color: theme.colors.warning,
        text: message,
        icon: <ExclamationCircleOutlined style={{ color: theme.colors.warning, marginLeft: 4 }} />,
      }
    }

    return {
      color: theme.colors.error,
      text: 'Total invoiced exceeds PO amount',
      icon: <ExclamationCircleOutlined style={{ color: theme.colors.error, marginLeft: 4 }} />,
    }
  }, [remaining, selectedOrders?.length, allInvoices, formattedTotalInvoicedAmount, formattedOrdersGrandTotal])

  return (
    <>
      <Box
        bg="white"
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        px={16}
        py={8}
        borderTop="1px solid"
        borderTopColor="gray-4"
        width="100%"
        style={{ gap: 8 }}
      >
        <Box display="flex" flexShrink={1} py={8} style={{ gap: 8 }}>
          <Typography.Text type="secondary">
            PO Total{' '}
            <Typography.Link onClick={() => drawerOrdersRef.current?.show()}>
              ({pluralize('Order', selectedOrders?.length || 0, true)})
            </Typography.Link>
          </Typography.Text>
          <Typography.Text style={{ margin: 0 }} strong>
            {currencyFormatter(ordersGrandTotal)}
          </Typography.Text>
        </Box>

        <Divider type="vertical" style={{ borderColor: theme.colors['gray-6'], height: '100%' }} />

        <Box display="flex" flexShrink={1} py={8} style={{ gap: 8 }}>
          <Typography.Text type="secondary">
            Total Invoiced{' '}
            <Typography.Link onClick={() => drawerInvoicesRef.current?.show()}>
              ({pluralize('Invoice', allInvoices?.length, true)})
            </Typography.Link>
          </Typography.Text>
          <Typography.Text style={{ margin: 0 }} strong>
            {currencyFormatter(totalInvoicedAmount)}
          </Typography.Text>
        </Box>

        <Divider type="vertical" style={{ borderColor: theme.colors['gray-6'], height: '100%' }} />

        <Tooltip title={remainingProperties.text} placement="topRight">
          <Box display="flex" flexShrink={1} py={8} style={{ gap: 8 }}>
            <Typography.Text type="secondary">Remaining {remainingProperties.icon}</Typography.Text>

            <Typography.Text style={{ margin: 0, color: remainingProperties.color }} strong>
              {currencyFormatter(remaining)}
            </Typography.Text>
          </Box>
        </Tooltip>
      </Box>

      <DrawerInvoices
        ref={drawerInvoicesRef}
        ordersGrandTotal={formattedOrdersGrandTotal}
        totalInvoicedAmount={formattedTotalInvoicedAmount}
        invoices={allInvoices}
        ordersCount={selectedOrders?.length}
      />

      <DrawerOrders
        ref={drawerOrdersRef}
        ordersGrandTotal={formattedOrdersGrandTotal}
        totalInvoicedAmount={formattedTotalInvoicedAmount}
        orders={selectedOrders?.map(({ order }) => order)}
        invoicesCount={allInvoices.length}
      />
    </>
  )
}
