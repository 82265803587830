import React from 'react'

import { Space, Tooltip, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'

import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'

type LeftActionsProps = {
  goBack: () => void
}

export const LeftActions = (props: LeftActionsProps) => {
  const { goBack } = props
  const { warehouse, isLoading } = useWarehouse()

  const warehouseNamePlaceholder = React.useMemo(() => {
    if (isLoading) return 'Loading warehouse...'

    return warehouse?.name || 'New warehouse'
  }, [warehouse, isLoading])

  return (
    <Box display="flex" alignItems="center">
      <Box mr={16}>{<UnsavedPopup goBack={goBack} />}</Box>

      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Tooltip title="Warehouse Name" placement="bottom">
          <Space>
            <Typography.Title level={5} style={{ margin: 0, fontSize: 20 }}>
              {warehouseNamePlaceholder}
            </Typography.Title>
          </Space>
        </Tooltip>
      </Box>
    </Box>
  )
}
