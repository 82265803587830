import React from 'react'

import { Button, Form } from 'antd'

import { Box } from 'common/components/boxes'
import { InputCurrency } from 'common/components/InputCurrency'
import { InvoiceMaterial } from 'common/server/invoice'

import { TaxInput } from '../components/TaxInput'
import { useInvoice } from '../context'
import { InvoiceMaterials } from '../InvoiceMaterials'

type ReconciliationViewProps = {
  onFilterInvoiceMaterialsTable: (invoiceMaterial: InvoiceMaterial) => void
  onAddItem: () => void
  visibleColumns: string[]
}

export const ReconciliationView = ({
  onFilterInvoiceMaterialsTable,
  onAddItem,
  visibleColumns,
}: ReconciliationViewProps) => {
  const { form, setSelectedInvoiceDirty, lockInvoice, invoice, modeView } = useInvoice()

  return (
    <Form
      key={invoice?.id}
      disabled={!lockInvoice.canEditInvoice}
      form={form}
      onChange={() => setSelectedInvoiceDirty(true)}
      layout="vertical"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Box overflow="auto" height={0} flexGrow={1}>
          <InvoiceMaterials onFilterTable={onFilterInvoiceMaterialsTable} visibleColumns={visibleColumns} />

          <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" mt={12}>
            <Button disabled={!lockInvoice.canEditInvoice} onClick={() => onAddItem()} type="primary">
              Add Item
            </Button>
          </Box>
        </Box>
      </Box>

      {modeView === 'reconciliation' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          style={{ gap: 16 }}
          mt={12}
          backgroundColor="white"
          p={8}
        >
          <Form.Item label="Tax" name="taxAmount" style={{ flexGrow: 1, margin: 0 }}>
            <TaxInput tabIndex={51} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Shipping" name="shippingCost" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={52} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Other costs" name="otherCosts" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={53} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
          <Form.Item label="Discount" name="discountAmount" style={{ flexGrow: 1, margin: 0 }}>
            <InputCurrency tabIndex={54} disabled={!lockInvoice.canEditInvoice} />
          </Form.Item>
        </Box>
      )}
    </Form>
  )
}
