import React, { useRef } from 'react'

import { NoteSticky } from '@styled-icons/fa-regular'
import shouldForwardProp from '@styled-system/should-forward-prop'

import styled from '@emotion/styled'

import { Button, Input, Typography, Badge, Tooltip } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'

type DeliveryInstructionsProps = {
  value?: string
  onChange?: (value: string) => void
  isFullWidth?: boolean
  disabled?: boolean
}

const BadgeStyled = styled(Badge, { shouldForwardProp })<Pick<DeliveryInstructionsProps, 'isFullWidth'>>`
  ${(props) => {
    if (props?.isFullWidth) {
      return 'width: 100%;'
    }
  }}

  .ant-badge-dot {
    width: 10px;
    min-width: 10px;
    height: 10px;
  }
`

export const DeliveryInstructions = ({ value, onChange, isFullWidth, disabled }: DeliveryInstructionsProps) => {
  const drawerRef = useRef<DrawerRef>()

  const style = isFullWidth ? { width: '100%' } : {}

  return (
    <>
      <Tooltip title="Delivery Instructions">
        <span>
          <BadgeStyled dot={!!value?.trim()} status="default" isFullWidth={isFullWidth}>
            <Button
              icon={<NoteSticky size="20" />}
              onClick={() => drawerRef.current?.toggle()}
              style={style}
              disabled={disabled}
            />
          </BadgeStyled>
        </span>
      </Tooltip>

      <Drawer
        bgGray
        closable={false}
        ref={drawerRef}
        title="Delivery Instructions"
        onClose={() => drawerRef.current?.close()}
        width={376}
      >
        <Box
          p={16}
          width="100%"
          height="100%"
          overflowY="auto"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Box>
            <Input.TextArea
              rows={6}
              value={value}
              onChange={(event) => onChange(event.target.value)}
              placeholder="Instructions"
              style={{ resize: 'none' }}
            />
            <Typography.Text type="secondary">
              Instructions will be saved when you save the rest of your order
            </Typography.Text>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => drawerRef.current?.close()} style={{ width: 100 }}>
              Close
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  )
}
