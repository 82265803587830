import React from 'react'

import { Box } from 'common/components/boxes'

import { WarehouseCard } from 'contractor/pages/Inventory/Warehouses/List/Components/WarehouseList/warehouse_card'
import { EmptySearch } from 'contractor/pages/Projects/empty_search'
import { Warehouse } from 'contractor/server/warehouses'

type WarehouseListProps = {
  data: Warehouse[]
  refetch: () => void
}

export const WarehouseList = (props: WarehouseListProps) => {
  const { data, refetch } = props

  if (!data?.length) return <EmptySearch />

  return (
    <Box p={16} flexGrow={1}>
      <Box display="grid" gridGap="16px" gridTemplateColumns="repeat(auto-fill, minmax(300px, 1fr))">
        {data.map((warehouse) => (
          <WarehouseCard key={warehouse.id} warehouse={warehouse} refetch={refetch} />
        ))}
      </Box>
    </Box>
  )
}
