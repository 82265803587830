import React from 'react'

import { Space, Tag } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

import { ItemAttribute } from 'contractor/server/warehouses'

type WarehouseProps = {
  attributes: ItemAttribute[]
}

export const AttributesTags = (props: WarehouseProps) => {
  const { attributes } = props
  const theme = useTheme()

  if (!attributes?.length) {
    return <Tag>None</Tag>
  }

  return (
    <Space wrap>
      {attributes?.map((attr) => (
        <Tag
          key={attr.id}
          color={theme.colors['purple-1']}
          style={{
            border: `1px solid ${theme.colors['purple-3']}`,
            color: 'black',
          }}
        >
          {attr.name}
        </Tag>
      ))}
    </Space>
  )
}
