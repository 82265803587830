import { act, renderHook } from '@testing-library/react-hooks'

import { useNotifyWarehouse } from 'common/hooks/use-notify-warehouse'

import { useStores } from 'contractor/hooks/use-stores'
import { useDeleteWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-delete-warehouse'

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

jest.mock('common/hooks/use-notify-warehouse', () => ({
  useNotifyWarehouse: jest.fn(),
}))

const mockDeleteWarehouse = jest.fn()
const mockNotifyWarehouse = jest.fn()

beforeEach(() => {
  jest.clearAllMocks()
  ;(useStores as jest.Mock).mockReturnValue({
    warehouseStore: {
      deleteWarehouse: mockDeleteWarehouse,
    },
  })
  ;(useNotifyWarehouse as jest.Mock).mockReturnValue({
    notifyWarehouse: mockNotifyWarehouse,
  })
})

describe('useDeleteWarehouse Hook', () => {
  const setup = () => renderHook(() => useDeleteWarehouse())

  it('should delete a warehouse successfully', async () => {
    const mockOnSuccess = jest.fn()
    const mockSetSubmitting = jest.fn()
    mockDeleteWarehouse.mockResolvedValueOnce(undefined)

    const { result } = setup()

    await act(async () => {
      await result.current.deleteWarehouse({
        id: '1',
        onSuccess: mockOnSuccess,
        setSubmitting: mockSetSubmitting,
      })
    })

    expect(mockSetSubmitting).toHaveBeenCalledWith(true)
    expect(mockDeleteWarehouse).toHaveBeenCalledWith('1')
    expect(mockNotifyWarehouse).toHaveBeenCalledWith({
      message: 'Warehouse deleted successfully!',
      warehouseId: '1',
    })
    expect(mockOnSuccess).toHaveBeenCalled()
    expect(mockSetSubmitting).toHaveBeenCalledWith(false)
  })

  it('should handle errors during warehouse deletion', async () => {
    const mockOnError = jest.fn()
    const mockSetSubmitting = jest.fn()
    const mockError = new Error('Deletion failed')
    mockDeleteWarehouse.mockRejectedValueOnce(mockError)

    const { result } = setup()

    await act(async () => {
      await result.current.deleteWarehouse({
        id: '2',
        onError: mockOnError,
        setSubmitting: mockSetSubmitting,
      })
    })

    expect(mockSetSubmitting).toHaveBeenCalledWith(true)
    expect(mockDeleteWarehouse).toHaveBeenCalledWith('2')
    expect(mockNotifyWarehouse).not.toHaveBeenCalled()
    expect(mockOnError).toHaveBeenCalledWith(mockError)
    expect(mockSetSubmitting).toHaveBeenCalledWith(false)
  })
})
