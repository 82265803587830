import axios from 'axios'

interface DynamicFields {
  id: string
  value: string | number | boolean
}

export interface CreateStockRequest {
  warehouse_id: string
  company_material_id: string
  unit_id: string
  expiration_date?: string
  manufactured_date?: string
  date_in?: string
  qty?: string
  send_to_quality_hold?: boolean
  location_values?: DynamicFields
  item_values?: DynamicFields
}

interface CreateStockResponse {
  id: string
  qty_available: string
  qty_commited: string
  qty_quality_hold: string
  qty_rejected: string
  workflow_state: string
  unit_id: string
  warehouse_id: string
  company_material_id: string
  created_at: string
  updated_at: string
  location_values?: DynamicFields
  item_values?: DynamicFields
  expiration_date?: string
  manufactured_date?: string
  date_in?: string
}

export function create(stock: CreateStockRequest) {
  return axios.post<CreateStockResponse>(`/warehouses/${stock.warehouse_id}/stocks`, { stock })
}
