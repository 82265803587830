import { useHistory } from 'react-router-dom'

import { act, renderHook } from '@testing-library/react-hooks'

import { message } from 'antd'

import { useWarehouse, WarehouseProvider } from '../context'

const getIndexMocked = jest.fn().mockResolvedValue([
  { id: 1, name: 'Alpha' },
  { id: 2, name: 'Beta' },
  { id: 3, name: 'Gamma' },
])

const loadWarehouseMocked = jest
  .fn()
  .mockResolvedValue({ id: 1, name: 'Mock Warehouse', project_id: 999, address_id: 456 })

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: () => ({
    warehouseStore: {
      loadWarehouse: loadWarehouseMocked,
      getIndex: getIndexMocked,
    },
    projectStore: {
      maybeIndexProjects: jest.fn(),
    },
    addressStore: {
      indexAddresses: jest.fn(),
    },
  }),
}))

jest.mock('react-router-dom', () => ({
  ...jest.requireActual('react-router-dom'),
  useHistory: jest.fn(),
  useParams: jest.fn().mockReturnValue({ id: '123' }),
}))

message.error = jest.fn()

const mockHistoryPush = jest.fn()
;(useHistory as jest.Mock).mockReturnValue({ push: mockHistoryPush })

describe('WarehouseContext', () => {
  beforeEach(() => {
    jest.clearAllMocks()
  })

  const setup = () => renderHook(() => useWarehouse(), { wrapper: WarehouseProvider })

  describe('handleLogError', () => {
    it('should display error from response', () => {
      const { result } = setup()

      const error = { response: { data: { error: 'Test Error' } } }
      act(() => {
        result.current.handleLogError(error)
      })

      expect(message.error).toHaveBeenCalledWith(expect.objectContaining({ content: 'Test Error', duration: 8 }))
    })

    it('should display default message when no error response', () => {
      const { result } = setup()

      act(() => {
        result.current.handleLogError({})
      })

      expect(message.error).toHaveBeenCalledWith('Unable to complete action. Please try again.')
    })
  })

  describe('goBack', () => {
    it('should fetch warehouses and navigate to /inventory?tab=warehouses', async () => {
      const { result } = setup()

      await act(async () => {
        await result.current.goBack()
      })

      expect(mockHistoryPush).toHaveBeenCalledWith('/inventory?tab=warehouses')
    })
  })
})
