import React, { useState } from 'react'

import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import PlusOutlined from '@ant-design/icons/PlusOutlined'
import { Button, Form, Input, Table, Typography } from 'antd'
import { FormInstance } from 'antd/es/form'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'

type DropdownOptionsProps = {
  form: FormInstance
}

export const DropdownOptions = (props: DropdownOptionsProps) => {
  const { form } = props
  const [option, setOption] = useState('')
  const dataSource = Form.useWatch('options', form) || []
  const showTable = dataSource?.length > 0
  const handleAddNewOption = () => {
    const trimmedOption = option.trim()
    if (trimmedOption) {
      form.setFieldValue('options', [...dataSource, trimmedOption])
      setOption('')
    }
  }

  const handleRemoveOption = (removedOption: string) => {
    const newOptions = dataSource.filter((opt: string) => opt !== removedOption)
    form.setFieldValue('options', newOptions)
  }

  const columns = [
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option',
    },
    {
      title: 'Action',
      key: 'action',
      width: 50,
      render: (_, record) => (
        <Button icon={<DeleteOutlined />} type="link" danger onClick={() => handleRemoveOption(record.option)} />
      ),
    },
  ]

  const tableData = dataSource?.map((item: string, index: number) => ({
    key: index,
    option: item,
  }))

  return (
    <FlexBoxY alignItems="start" width="100%" pb={16}>
      <Typography.Text>Value</Typography.Text>
      <FlexBoxX style={{ width: '100%' }}>
        <FlexBoxX width="100%">
          <Input
            placeholder="Enter a new dropdown option"
            value={option}
            onChange={(e) => setOption(e.target.value)}
            style={{ flexGrow: 1, marginRight: 16 }}
          />
          <Button icon={<PlusOutlined />} onClick={handleAddNewOption} />
        </FlexBoxX>
      </FlexBoxX>

      {showTable && (
        <Table dataSource={tableData} columns={columns} style={{ width: '100%', paddingTop: 8 }} pagination={false} />
      )}
    </FlexBoxY>
  )
}
