import React, { useRef } from 'react'

import { Filter } from '@styled-icons/fluentui-system-filled'

import styled from '@emotion/styled'

import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { Typography, Button, Switch } from 'antd'

import { Box } from 'common/components/boxes'
import { Drawer, DrawerRef } from 'common/components/Drawer'

const reviewStepTableColumnLocalStorageKey = '@subbase/review-step/table-columns'

const StyledListItem = styled(Box)`
  cursor: pointer;
  transition: background 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors['gray-3']};
  }
`

export const TableOptions = ({ value = [], onChange }) => {
  const drawerRef = useRef<DrawerRef>()

  const setLocalStorage = (value) => localStorage.setItem(reviewStepTableColumnLocalStorageKey, JSON.stringify(value))

  const handleChangeVisibleColumns = (changedVisibleColumns) => {
    setLocalStorage(changedVisibleColumns)
    onChange(changedVisibleColumns)
  }

  return (
    <>
      <Button
        style={{ minWidth: 32 }}
        onClick={() => drawerRef.current?.show()}
        icon={<Filter style={{ width: 16 }} />}
      />

      <Drawer bgGray title="Table Options" ref={drawerRef} width={378} closable={false}>
        <Box p={12} width="100%">
          {value.map((column) => {
            return (
              <StyledListItem
                key={column.id}
                mb={4}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => {
                  handleChangeVisibleColumns(
                    value.map((currentColumn) => {
                      if (currentColumn.id === column.id) {
                        return { ...column, isVisible: !currentColumn.isVisible }
                      }

                      return currentColumn
                    }),
                  )
                }}
                py={4}
                px={6}
                borderRadius="md"
                backgroundColor={column.isVisible ? 'transparent' : 'gray-4'}
              >
                <Typography.Text>{column.Header}</Typography.Text>
                <Switch
                  size="small"
                  checked={column.isVisible}
                  checkedChildren={<EyeOutlined />}
                  unCheckedChildren={<EyeInvisibleOutlined />}
                />
              </StyledListItem>
            )
          })}

          <Button
            style={{ marginTop: 16, width: '100%' }}
            type="primary"
            onClick={() => handleChangeVisibleColumns(value.map((column) => ({ ...column, isVisible: true })))}
            disabled={value.every((column) => column.isVisible)}
          >
            Show All
          </Button>
        </Box>
      </Drawer>
    </>
  )
}
