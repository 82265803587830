import { useNotifyWarehouse } from 'common/hooks/use-notify-warehouse'

import { useStores } from 'contractor/hooks/use-stores'

type UseDeleteWarehouseProps = {
  id: string
  onSuccess?: () => void
  onError?: (error: {}) => void
  setSubmitting?: (value: boolean) => void
}

export const useDeleteWarehouse = () => {
  const { warehouseStore } = useStores()
  const { notifyWarehouse } = useNotifyWarehouse()

  const deleteWarehouse = async (props: UseDeleteWarehouseProps) => {
    const { id, onSuccess, onError, setSubmitting } = props

    try {
      setSubmitting?.(true)
      await warehouseStore.deleteWarehouse(id)

      notifyWarehouse({
        message: 'Warehouse deleted successfully!',
        warehouseId: id,
      })

      onSuccess?.()
    } catch (error) {
      onError?.(error)
    } finally {
      setSubmitting?.(false)
    }
  }

  return { deleteWarehouse }
}
