import React from 'react'

import { CopyToClipboard } from 'react-copy-to-clipboard'

import { CopyOutlined } from '@ant-design/icons'
import { Button, message, Typography } from 'antd'

interface ExpiredInviteProps {
  record_id: string
}

const ExpiredInvite: React.FC<ExpiredInviteProps> = React.memo(({ record_id }) => {
  return (
    <Typography.Text data-cy={`company-setting-invite-expired-${record_id}`} type="danger">
      Invite expired!
    </Typography.Text>
  )
})

interface CopyInviteButtonProps {
  record_id: string
  invitation_link?: string
}

const CopyInviteButton: React.FC<CopyInviteButtonProps> = React.memo(({ record_id, invitation_link }) => {
  return (
    <CopyToClipboard text={invitation_link} onCopy={() => message.success('Copied invitation link')}>
      <Button data-cy={`company-setting-copy-invite-link-${record_id}`} type="link" style={{ padding: '0' }}>
        Copy invite link <CopyOutlined />
      </Button>
    </CopyToClipboard>
  )
})

interface CopyInviteLinkProps {
  isExpired: boolean
  record_id: string
  invitation_link?: string
}

export const CopyInviteLink: React.FC<CopyInviteLinkProps> = React.memo(({ isExpired, record_id, invitation_link }) => {
  return isExpired ? (
    <ExpiredInvite record_id={record_id} />
  ) : (
    <CopyInviteButton record_id={record_id} invitation_link={invitation_link} />
  )
})
