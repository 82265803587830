import React from 'react'

import { useHistory } from 'react-router'

import { CodepenOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Row, Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'
import { SelectWarehouseFilter } from 'contractor/pages/Inventory/common/components/SelectWarehouseFilter'

const ribbonOptions = [
  { label: 'All', filter: '' },
  { label: 'Draft', filter: 'DRAFT' },
  { label: 'New request', filter: 'REQUESTED' },
  { label: 'WM review', filter: 'UNREVIEWED' },
  { label: 'Approved', filter: 'APPROVED' },
  { label: 'Delivered', filter: 'DELIVERED' },
  { label: 'Rejected', filter: 'REJECTED' },
]

export const RequestHeader = observer(() => {
  const {
    inventoryRequestStore: { listStore },
  } = useStores()
  const persistentFilters = usePersistentFilters()
  const history = useHistory()

  const warehouseId = listStore.searchState.filters['warehouse_id']

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={24} sm={8} md={6} lg={5} xl={4} xxl={4}>
          <SelectWarehouseFilter
            value={warehouseId}
            onChange={(value) => persistentFilters.handleChangeFilters({ warehouse_id: value }, false)}
          />
        </Col>
        <Col xs={24} sm={16} md={13} lg={15} xl={17} xxl={17}>
          <Box display="flex" alignItems="center" gridGap={10}>
            <SearchInput
              value={listStore.searchState.search}
              onSearch={(search: string) => persistentFilters.handleChangeFilters({ search }, false)}
            />
            <Button icon={<CodepenOutlined />} style={{ minWidth: 32 }} />
            <Button icon={<FilterOutlined />} style={{ minWidth: 32 }} />
          </Box>
        </Col>

        <Col xs={24} sm={24} md={5} lg={4} xl={3} xxl={3}>
          <Button
            onClick={() => history.push(`/warehouses/${warehouseId}/requests/new`)}
            icon={<PlusOutlined style={{ fontSize: 11 }} />}
            style={{ width: '100%' }}
            type="primary"
          >
            New request
          </Button>
        </Col>
      </Row>

      <RibbonFilter
        showCounts
        overflowCount={9999}
        value={listStore.searchState.filters['state'] || []}
        counts={listStore.stateCounts || {}}
        onChange={(value) => persistentFilters.handleChangeFilters({ state: value })}
        options={ribbonOptions}
        boxProps={{ overflowX: 'auto' }}
      />
    </>
  )
})
