import React, { createContext, useContext, useState } from 'react'

import { Form } from 'antd'
import { FormInstance } from 'antd/es/form'

import { observer } from 'mobx-react-lite'

import { useQuery } from 'common/hooks/use-query'
import { AddressTo } from 'common/server/addressesTo'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'
import { CostCode } from 'common/server/cost_codes/cost_codes'

import { useStores } from 'contractor/hooks/use-stores'
import { AllAddresses } from 'contractor/server/addresses'
import { CostCodeSettings } from 'contractor/server/company_settings/other_settings'

const InventoryRequestContext = createContext<InventoryRequestContextProps>({} as InventoryRequestContextProps)

export const useCreateInventoryRequest = () => useContext(InventoryRequestContext)

type SubmitType = 'DRAFT' | 'REQUESTED'

type InventoryRequestContextProps = {
  form: FormInstance
  isLoadingProjects: boolean
  isLoadingAddresses: boolean
  isLoadingAddressesTo: boolean
  costCodes: CostCode[]
  costCodeSettings: CostCodeSettings
  addressToOptions: AddressTo[]
  addressOptions: AllAddresses
  costCodePhases: CostCodePhase[]
  submitType: SubmitType
  handleSetSubmitType: (type: SubmitType) => void
}

export const InventoryRequestProvider = observer(({ children }) => {
  const { projectStore, companySettingStore, costCodeStore, addressStore, addressToStore } = useStores()

  const [form] = Form.useForm()
  const projectIdField = Form.useWatch('project_id', form)

  const [submitType, setSubmitType] = useState<SubmitType>(null)

  useQuery(() => {
    if (!companySettingStore?.otherSettings?.cost_code_settings?.independent_phase_codes_enabled) {
      return Promise.resolve([])
    }

    costCodeStore.costCodePhaseListStore.setFilter('active', true, true, true)

    if (companySettingStore?.otherSettings?.cost_code_settings?.project_specific_phase_codes_enabled) {
      costCodeStore.costCodePhaseListStore.setFilter('project_id', projectIdField, true)
    }

    return costCodeStore.costCodePhaseListStore.fetchRecords()
  }, [companySettingStore?.otherSettings?.cost_code_settings?.independent_phase_codes_enabled, projectIdField])

  useQuery(costCodeStore.fetchAllActiveCostCodes)
  const { isLoading: isLoadingProjects } = useQuery(projectStore.maybeIndexProjects)
  const { isLoading: isLoadingAddresses } = useQuery(addressStore.maybeIndexAddresses)
  const { isLoading: isLoadingAddressesTo } = useQuery(addressToStore.indexAddressesTo)

  const handleSetSubmitType = (type: SubmitType) => setSubmitType(type)

  return (
    <InventoryRequestContext.Provider
      value={{
        form,
        submitType,
        handleSetSubmitType,
        isLoadingProjects,
        isLoadingAddresses,
        isLoadingAddressesTo,
        costCodePhases: costCodeStore.costCodePhaseListStore.records,
        costCodes: costCodeStore.costCodeListStore.records,
        costCodeSettings: companySettingStore?.otherSettings?.cost_code_settings,
        addressToOptions: addressToStore.addressesTo,
        addressOptions: addressStore.addresses,
      }}
    >
      {children}
    </InventoryRequestContext.Provider>
  )
})

export const withInventoryRequestProvider = (Component) => (props) => {
  return (
    <InventoryRequestProvider>
      <Component {...props} />
    </InventoryRequestProvider>
  )
}
