import React from 'react'

import {
  DeleteOutlined,
  DownOutlined,
  MoreOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
  EditOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Row, Col, Dropdown, Typography, Space, Tag, Popover } from 'antd'

import { Box } from 'common/components/boxes'
import { useTheme } from 'common/hooks/use-theme'

import { Stock } from 'contractor/server/inventory'

import { DynamicTag } from './dynamic_tag'
import { QtyByStatus } from './qty_by_status'

type StockRowProps = {
  consolidatedStock: Stock
}

export const StockRow = ({ consolidatedStock }: StockRowProps) => {
  const theme = useTheme()

  return (
    <Box py={12} px={16} bg="white" borderRadius={4}>
      <Row gutter={[10, 10]} align="middle">
        <Col xs={24} sm={24} md={14} lg={9} xl={10} xxl={10}>
          <Box display="flex" alignItems="center" gridGap={10} width="100%">
            <Checkbox style={{ minWidth: 16 }} />
            <Box display="flex" flexDirection="column" width="100%" gridGap={4}>
              <Typography.Text>
                {consolidatedStock.number && <Tag>{consolidatedStock.number}</Tag>}{' '}
                {consolidatedStock.company_material?.description}
              </Typography.Text>
              <Space style={{ flexWrap: 'wrap' }}>
                {Object.entries(consolidatedStock.item_values).map(([key, value]) => (
                  <DynamicTag key={`${key}-${value}`} type="item">
                    {key}: {value}
                  </DynamicTag>
                ))}
              </Space>
            </Box>
          </Box>
        </Col>

        <Col xs={24} sm={24} md={10} lg={6} xl={6} xxl={6}>
          <Space style={{ flexWrap: 'wrap' }}>
            {Object.entries(consolidatedStock.location_values).map(([key, value]) => (
              <DynamicTag key={`${key}-${value}`} type="location">
                {key}: {value}
              </DynamicTag>
            ))}
          </Space>
        </Col>

        <Col xs={24} sm={6} md={6} lg={2} xl={2} xxl={2}>
          <QtyByStatus
            title={
              <>
                Available <CheckCircleOutlined style={{ color: theme.colors.success }} />
              </>
            }
          >
            <Typography.Text strong>{consolidatedStock.qty_available}</Typography.Text>
          </QtyByStatus>
        </Col>

        <Col xs={24} sm={6} md={6} lg={2} xl={2} xxl={2}>
          <QtyByStatus
            title={
              <>
                Committed <InfoCircleOutlined style={{ color: theme.colors.info }} />
              </>
            }
          >
            <Popover
              placement="bottomRight"
              trigger="click"
              content={
                <Box width={360} display="flex" flexDirection="column">
                  <Box display="flex" alignItems="center" justifyContent="space-between" gridGap={10}>
                    <Typography.Link target="_blank">21-445-101: Materials to field</Typography.Link>
                    <Tag style={{ margin: 0 }}> WM Review</Tag>
                  </Box>
                </Box>
              }
              title="Requests"
            >
              <Box width="fit-content" display="flex" alignItems="center" gridGap={8} style={{ cursor: 'pointer' }}>
                <Typography.Text>{consolidatedStock.qty_commited}</Typography.Text>
                <DownOutlined style={{ fontSize: 10 }} />
              </Box>
            </Popover>
          </QtyByStatus>
        </Col>

        <Col xs={24} sm={6} md={6} lg={2} xl={2} xxl={2}>
          <QtyByStatus
            title={
              <>
                Quality hold <WarningOutlined style={{ color: theme.colors.warning }} />
              </>
            }
          >
            <Typography.Text>{consolidatedStock.qty_quality_hold}</Typography.Text>
          </QtyByStatus>
        </Col>

        <Col xs={24} sm={6} md={6} lg={3} xl={2} xxl={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between" gridGap={10} width="100%">
            <QtyByStatus
              title={
                <>
                  Rejected <ExclamationCircleOutlined style={{ color: theme.colors.error }} />
                </>
              }
            >
              <Typography.Text>{consolidatedStock.qty_rejected}</Typography.Text>
            </QtyByStatus>

            <Dropdown
              menu={{
                items: [
                  { key: 'edit', label: 'Edit', icon: <EditOutlined /> },
                  { key: 'delete', label: 'Delete', icon: <DeleteOutlined />, danger: true },
                ],
              }}
              trigger={['click']}
            >
              <Button icon={<MoreOutlined />} style={{ minWidth: 24 }} size="small" />
            </Dropdown>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}
