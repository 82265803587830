import React from 'react'

import { Tag, TagProps } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

type DynamicTagProps = { type: 'location' | 'item' } & TagProps

export const DynamicTag = ({ type, ...props }: DynamicTagProps) => {
  const theme = useTheme()

  const itemStyle = {
    item: {
      borderColor: theme.colors['geekblue-3'],
      backgroundColor: theme.colors['geekblue-1'],
    },
    location: {
      borderColor: theme.colors['purple-3'],
      backgroundColor: theme.colors['purple-1'],
    },
  }

  return (
    <Tag
      style={{
        margin: 0,
        ...itemStyle[type],
      }}
      {...props}
    />
  )
}
