import React, { useEffect, useRef, useState } from 'react'

import hotkeys from 'hotkeys-js'

import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { Button, Divider } from 'antd'

import { Box, BoxProps } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { LeftButton, RightButton, Scrollable, StyledTag, BadgeStyled } from './styles'

const MULTI_SELECTION_KEY = 'alt'

interface OptionType {
  wrapper?: (children: React.ReactNode) => React.ReactNode
  filter: string
  label: string
  rightIcon?: React.ReactNode
  leftIcon?: React.ReactNode
  ignoreWhenCountingAll?: boolean
}

interface RibbonFilterProps {
  value?: string[] | string
  onChange?: (value: string[]) => void
  counts?: Record<string, number>
  showCounts?: boolean
  options?: OptionType[]
  boxProps?: BoxProps
  overflowCount?: number
  size?: 'small' | 'middle'
}

export const RibbonFilter = (props: RibbonFilterProps) => {
  const { value, onChange, counts = {}, options = [], boxProps, overflowCount, showCounts } = props

  const scrollableRef = useRef<HTMLDivElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const [showLeftButton, setLeftButton] = useState(false)
  const [showRightButton, setRightButton] = useState(false)

  let ignoredForAllCounter = 0
  const countsParsedByLabel = React.useMemo(
    () =>
      options.reduce((acc, current) => {
        ignoredForAllCounter = !current.ignoreWhenCountingAll
          ? ignoredForAllCounter
          : ignoredForAllCounter + counts[current.filter]

        acc[current.label] = counts[current.label] || counts[current.filter] || 0

        return acc
      }, {}),
    [counts],
  )

  const total = React.useMemo(() => {
    let recordsSum = 0

    Object.values(countsParsedByLabel).forEach((labelSum) => {
      // @ts-ignore
      recordsSum += labelSum
    })

    return recordsSum - (ignoredForAllCounter || 0)
  }, [countsParsedByLabel])

  useEffect(() => {
    // Bind both Alt (Windows) and Cmd (Mac)
    hotkeys(`${MULTI_SELECTION_KEY},cmd`, function () {
      console.log('Pressed', MULTI_SELECTION_KEY, 'or Cmd')
    })
    return () => {
      hotkeys.unbind(`${MULTI_SELECTION_KEY},cmd`)
    }
  }, [])

  const handleClickPrev = () =>
    scrollableRef.current?.scrollTo({
      left: scrollableRef.current?.scrollLeft - 100,
      behavior: 'smooth',
    })

  const handleClickNext = () =>
    scrollableRef.current?.scrollTo({
      left: scrollableRef.current?.scrollLeft + 100,
      behavior: 'smooth',
    })

  const maybeAddFilter = (filter: string) => {
    // Check if either key is pressed
    if (hotkeys.isPressed(MULTI_SELECTION_KEY) || hotkeys.isPressed('cmd')) {
      const values = Array.isArray(value) ? value : value?.split(',') || []
      return values.includes(filter) ? values.filter((val) => val !== filter) : [...values, filter]
    }
    return [filter]
  }

  useEffect(() => {
    const onScroll = () => {
      if (scrollableRef.current?.scrollLeft > 0) {
        !showLeftButton && setLeftButton(true)
      } else {
        showLeftButton && setLeftButton(false)
      }

      if (
        scrollableRef.current?.scrollLeft + scrollableRef.current?.offsetWidth >=
        scrollableRef.current?.scrollWidth
      ) {
        showRightButton && setRightButton(false)
      } else {
        !showRightButton && setRightButton(true)
      }
    }

    scrollableRef.current?.addEventListener('scroll', onScroll)
    return () => scrollableRef.current?.removeEventListener('scroll', onScroll)
  }, [showLeftButton, showRightButton, scrollableRef.current])

  useEffect(() => {
    if (Math.round(scrollableRef.current?.scrollWidth) > Math.round(wrapperRef.current?.clientWidth)) {
      !showRightButton && setRightButton(true)
    } else {
      showRightButton && setRightButton(false)
    }
  }, [wrapperRef.current])

  useEffect(() => {
    const onWheel = (event) => {
      if (event.deltaY === 0) return
      event.preventDefault()
      const left = scrollableRef.current?.scrollLeft + event.deltaY

      scrollableRef.current?.scrollTo({
        left,
        behavior: 'smooth',
      })
    }

    scrollableRef.current?.addEventListener('wheel', onWheel)
    return () => scrollableRef.current?.removeEventListener('wheel', onWheel)
  }, [scrollableRef.current])

  return (
    <Box position="relative" maxWidth="100%" {...boxProps} ref={wrapperRef}>
      {showLeftButton && (
        <LeftButton>
          <Box bg="white" display="flex" alignItems="center">
            <Button icon={<LeftOutlined />} type="text" shape="circle" onClick={handleClickPrev} size="small" />
          </Box>
        </LeftButton>
      )}

      <Scrollable overflowX="auto" display="flex" flexWrap="nowrap" ref={scrollableRef}>
        {options?.map((option) => {
          const count =
            option.filter === '' ? total : countsParsedByLabel[option.filter] || countsParsedByLabel[option.label] || 0

          let isChecked = false
          if (value) {
            // Ensure the value is always an array
            const values = Array.isArray(value) ? value : value.split(',')
            if (values && values.length > 0) {
              if (option.filter === '' && values.includes('')) {
                isChecked = true
              } else if (option.filter === '') {
                const filters = options.map((opt) => opt.filter)
                // if there is a value that is not in the filters, we should check it
                isChecked = values.some((val) => !filters.includes(val)) && values.length === 1
              } else {
                isChecked = values.includes(option.filter)
              }
            } else if (!values || values.length === 0) {
              isChecked = '' === option.filter
            }
          }

          // Keeping the old method for now
          // const isChecked = value?.length > 1 ? value.includes(option.filter) : get(value, '[0]', '') === option.filter

          const optionComponent = (
            <Box display="flex" alignItems="center" key={option.label}>
              {option.label == 'Cancelled' && (
                <Divider
                  style={{ borderColor: theme.colors['gray-5'], height: '2em', marginLeft: 8, marginRight: 12 }}
                  type="vertical"
                />
              )}
              <StyledTag
                data-cy={`${option.label}-filter`}
                key={option.label}
                checked={isChecked}
                onChange={() => {
                  if (onChange) {
                    onChange(maybeAddFilter(option.filter))
                  }
                }}
                size={props.size}
              >
                {option?.leftIcon}

                {option.label}

                {!!Object.values(countsParsedByLabel).length && showCounts && (
                  <BadgeStyled aria-label="counter-badge" count={count} showZero overflowCount={overflowCount || 999} />
                )}

                {option?.rightIcon}
              </StyledTag>
            </Box>
          )

          if (option?.wrapper) {
            return <div key={option.label}>{option?.wrapper(<React.Fragment>{optionComponent}</React.Fragment>)}</div>
          }

          return optionComponent
        })}
      </Scrollable>

      {showRightButton && (
        <RightButton>
          <Box bg="white" display="flex" alignItems="center">
            <Button icon={<RightOutlined />} type="text" shape="circle" onClick={handleClickNext} size="small" />
          </Box>
        </RightButton>
      )}
    </Box>
  )
}
