import React from 'react'

import { IntegrationsSourceSystem } from 'common/server/server_types'

type IntegrationIconsProps = {
  type: IntegrationsSourceSystem
  integrationName?: string
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>

export const IntegrationIcons = ({ type, integrationName, ...props }: IntegrationIconsProps) => {
  const imgIntegrationLogo = {
    [IntegrationsSourceSystem.QBO]: window.railsAssets?.quickbooksLogo,
    [IntegrationsSourceSystem.QBD]: window.railsAssets?.quickbooksLogo,
    [IntegrationsSourceSystem.PROCORE]: window.railsAssets?.procoreLogo,
    [IntegrationsSourceSystem.FOUNDATION_HOSTED]: window.railsAssets?.foundationLogo,
    [IntegrationsSourceSystem.CMIC]: window.railsAssets?.cmicLogo,
    [IntegrationsSourceSystem.SPECTRUM]: window.railsAssets?.viewpointSpectrumLogo,
    [IntegrationsSourceSystem.ACUMATICA]: window.railsAssets?.acumaticaLogo,
    [IntegrationsSourceSystem.SAGE_300]: window.railsAssets?.sage300Logo,
    [IntegrationsSourceSystem.SAGE_100]: window.railsAssets?.sage100Logo,
  }

  let imgSrc = imgIntegrationLogo[type]

  if (!imgSrc && integrationName) {
    imgSrc = imgIntegrationLogo[IntegrationsSourceSystem[integrationName]]

    if (!imgSrc) imgSrc = imgIntegrationLogo[IntegrationsSourceSystem[integrationName.toUpperCase()]]
  }

  return <img src={imgSrc} width={48} height={48} style={{ marginRight: 3, marginLeft: 5 }} {...props} />
}
