import React from 'react'

import { useParams } from 'react-router-dom'

import { Form } from 'antd'

import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { ErrorState } from 'contractor/pages/CompanyMaterials/PriceSheet/Edit/components'
import { Header } from 'contractor/pages/Inventory/Warehouses/common/components/Header/header'
import { WarehouseFormCard } from 'contractor/pages/Inventory/Warehouses/common/components/WarehouseForm/FormCard/warehouse_form_card'
import { useWarehouse, withWarehouseProvider } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { useGetWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-get-warehouse'
import { useUpdateWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-update-warehouse'
import { Warehouse } from 'contractor/server/warehouses'

const Details = () => {
  const { projectStore } = useStores()
  const { id } = useParams<{ id: string }>()
  const { form, formatWarehousePayload, setSubmitting, handleLogError, goBack, setWarehouse } = useWarehouse()

  const handleSuccess = (warehouseResponse: Warehouse) => {
    setWarehouse(warehouseResponse)
    form.setFieldsValue({ ...warehouseResponse, ...warehouseResponse?.address })
  }

  const { isError, isLoading } = useGetWarehouse({ id, onSuccess: handleSuccess })
  const { updateWarehouse } = useUpdateWarehouse()

  const { isLoading: isLoadingSelectors } = useQuery(() => {
    return Promise.all([projectStore.maybeIndexProjects()])
  })

  const handleFinish = (formValues: Warehouse) => {
    const payload = formatWarehousePayload(formValues)
    updateWarehouse({ payload, setSubmitting, onError: handleLogError, onSuccess: goBack })
  }

  if (isLoading) return <Loading />

  if (isError) return <ErrorState />

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      requiredMark={false}
      style={{ width: '100%', height: '100%' }}
    >
      <Page>
        <Page.Header>
          <Header />
        </Page.Header>

        <Page.Content
          p={0}
          px={{ _: 0, sm: 16 }}
          width="100%"
          py={16}
          alignItems="flex-start"
          justifyContent="center"
          display="flex"
        >
          <WarehouseFormCard isLoading={isLoadingSelectors} />
        </Page.Content>
      </Page>
    </Form>
  )
}

export const WarehouseDetails = withWarehouseProvider(Details)
