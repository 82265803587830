import { act, renderHook } from '@testing-library/react-hooks'

import { useNotifyWarehouse } from 'common/hooks/use-notify-warehouse'

import { useStores } from 'contractor/hooks/use-stores'
import { useCreateWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-create-warehouse'

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

jest.mock('common/hooks/use-notify-warehouse', () => ({
  useNotifyWarehouse: jest.fn(),
}))

const mockCreateWarehouse = jest.fn()
const mockNotifyWarehouse = jest.fn()

beforeEach(() => {
  jest.clearAllMocks()
  ;(useStores as jest.Mock).mockReturnValue({
    warehouseStore: {
      createWarehouse: mockCreateWarehouse,
    },
  })
  ;(useNotifyWarehouse as jest.Mock).mockReturnValue({
    notifyWarehouse: mockNotifyWarehouse,
  })
})

describe('useCreateWarehouse Hook', () => {
  const setup = () => renderHook(() => useCreateWarehouse())

  it('should create a warehouse successfully', async () => {
    const mockOnSuccess = jest.fn()
    const mockSetSubmitting = jest.fn()
    mockCreateWarehouse.mockResolvedValueOnce(undefined)

    const { result } = setup()

    await act(async () => {
      await result.current.createWarehouse({
        payload: { id: '1', name: 'Test Warehouse', project_id: 1, address_id: 1 },
        onSuccess: mockOnSuccess,
        setSubmitting: mockSetSubmitting,
      })
    })

    expect(mockSetSubmitting).toHaveBeenCalledWith(true)
    expect(mockCreateWarehouse).toHaveBeenCalledWith({
      id: '1',
      name: 'Test Warehouse',
      project_id: 1,
      address_id: 1,
    })
    expect(mockNotifyWarehouse).toHaveBeenCalledWith({
      message: 'Warehouse created!',
      warehouseId: '1',
    })
    expect(mockOnSuccess).toHaveBeenCalled()
    expect(mockSetSubmitting).toHaveBeenCalledWith(false)
  })

  it('should handle errors during warehouse creation', async () => {
    const mockOnError = jest.fn()
    const mockSetSubmitting = jest.fn()
    const mockError = new Error('Creation failed')
    mockCreateWarehouse.mockRejectedValueOnce(mockError)

    const { result } = setup()

    await act(async () => {
      await result.current.createWarehouse({
        payload: { id: '2', name: 'Failed Warehouse', project_id: 2, address_id: 2 },
        onError: mockOnError,
        setSubmitting: mockSetSubmitting,
      })
    })

    expect(mockSetSubmitting).toHaveBeenCalledWith(true)
    expect(mockCreateWarehouse).toHaveBeenCalledWith({
      id: '2',
      name: 'Failed Warehouse',
      project_id: 2,
      address_id: 2,
    })
    expect(mockNotifyWarehouse).not.toHaveBeenCalled()
    expect(mockOnError).toHaveBeenCalledWith(mockError)
    expect(mockSetSubmitting).toHaveBeenCalledWith(false)
  })
})
