import React from 'react'

import { Button, Form } from 'antd'

import { Box } from 'common/components/boxes'
import { InvoiceMaterial } from 'common/server/invoice'

import { useInvoice } from '../context'
import { InvoiceMaterials } from '../InvoiceMaterials'

type ReconciliationViewProps = {
  onFilterInvoiceMaterialsTable: (invoiceMaterial: InvoiceMaterial) => void
  onAddItem: () => void
  visibleColumns: string[]
}

export const ReconciliationView = ({
  onFilterInvoiceMaterialsTable,
  onAddItem,
  visibleColumns,
}: ReconciliationViewProps) => {
  const { form, setSelectedInvoiceDirty, lockInvoice, invoice } = useInvoice()

  return (
    <Form
      key={invoice?.id}
      disabled={!lockInvoice.canEditInvoice}
      form={form}
      onChange={() => setSelectedInvoiceDirty(true)}
      layout="vertical"
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <Box flexGrow={1} display="flex" flexDirection="column">
        <Box overflow="auto" height={0} flexGrow={1}>
          <InvoiceMaterials onFilterTable={onFilterInvoiceMaterialsTable} visibleColumns={visibleColumns} />
          <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" mt={12}>
            <Button disabled onClick={() => onAddItem()} type="primary">
              Add Item
            </Button>
          </Box>
        </Box>
      </Box>
    </Form>
  )
}
