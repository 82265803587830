import React from 'react'

import { Form } from 'antd'

import { Box } from 'common/components/boxes'
import { makeCostCodeValue } from 'common/components/SelectCostCode'
import { CostCodePhase } from 'common/server/cost_codes/cost_code_phases'
import { InventoryOperationTypes } from 'common/server/server_types'

import { makeCostCodePhaseOptionAdvanced } from 'contractor/components/SelectCostCodePhaseAdvanced'

import { useCreateInventoryRequest } from '../context'
import { useOperationTitles } from './hooks'
import { OperationInformation } from './operation_information'
import { OperationTabs, OperationTabTitle } from './OperationTabs'
import { StockRequest } from './StockRequest'
import { StocksAutocomplete } from './StocksAutocomplete'

export const Operations = () => {
  const { form, isLoadingAddresses, isLoadingAddressesTo, costCodeSettings, addressToOptions, addressOptions } =
    useCreateInventoryRequest()
  const operations = Form.useWatch('operations', form) || []
  const warehouseId = Form.useWatch('warehouse_id', form)

  const { getOperationTitle } = useOperationTitles(operations)

  const operationsCount = operations.length

  const addStock = (operationIndex, content) => {
    const operations = form.getFieldValue('operations') || []
    if (!operations[operationIndex]?.stock_requests) {
      operations[operationIndex] = { ...(operations[operationIndex] || {}), stock_requests: [] }
    }
    operations[operationIndex].stock_requests.push(content)
    form.setFieldsValue({ operations })
  }

  return (
    <Form.List name="operations">
      {(operationFields, { add: addOperation, remove: removeOperation }) => {
        return (
          <OperationTabs
            onDuplicate={(operationIndex) => {
              const operationCopy = form.getFieldValue(['operations', Number(operationIndex)]) || {}
              addOperation({ ...operationCopy, id: null })
            }}
            onAdd={() =>
              addOperation({
                operation_type: InventoryOperationTypes.DELIVERY,
                address_id: null,
                attention_to_id: null,
                requested_date: null,
                stock_requests: [],
              })
            }
            operationsCount={operationsCount}
            items={operationFields.map(({ name: operationName }) => {
              const operationType = form.getFieldValue(['operations', operationName, 'operation_type'])
              const title = getOperationTitle(operationName)

              return {
                title,
                label: (
                  <OperationTabTitle
                    title={title}
                    operationType={operationType}
                    isRemovable={operationsCount > 1}
                    onRemove={() => removeOperation(operationName)}
                  />
                ),
                key: `${operationName}`,
                children: (
                  <Box display="flex" flexDirection="column">
                    <Box display="flex" flexDirection="column" id="operation-information">
                      <OperationInformation
                        operationName={operationName}
                        isLoadingAddresses={isLoadingAddresses}
                        isLoadingAddressesTo={isLoadingAddressesTo}
                        addressToOptions={addressToOptions}
                        addressOptions={addressOptions}
                        onChangeOperationType={() =>
                          form.setFieldValue(['operations', operationName, 'address_id'], null)
                        }
                      />

                      <StocksAutocomplete
                        warehouseId={warehouseId}
                        onSelect={(stock) =>
                          addStock(operationName, {
                            ...stock,
                            stock_id: stock?.id,
                            quantity: null,
                            cost_code: makeCostCodeValue(stock?.cost_code, costCodeSettings),
                            cost_code_phase: makeCostCodePhaseOptionAdvanced(stock?.cost_code_phase as CostCodePhase),
                          })
                        }
                      />
                    </Box>

                    <Box flexGrow={1} display="flex" flexDirection="column" gridGap={16} mt={16}>
                      <Form.List name={[operationName, 'stock_requests']}>
                        {(stockFields, { remove: removeStock }) =>
                          stockFields.map(({ key: stockKey, name: stockName }) => (
                            <StockRequest key={`stock-${stockKey}`} name={stockName} remove={removeStock} />
                          ))
                        }
                      </Form.List>
                    </Box>
                  </Box>
                ),
              }
            })}
          />
        )
      }}
    </Form.List>
  )
}
