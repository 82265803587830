import React from 'react'

import { Popover, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { AvailableCommitmentsResponse } from 'common/server/invoice'

import { CommitmentState } from './commitment_state'

type CommitmentTagWrapperProps = {
  children: React.ReactNode
  commitment: AvailableCommitmentsResponse
}

export const CommitmentTagWrapper = ({ children, commitment }: CommitmentTagWrapperProps) => {
  return (
    <Popover
      mouseEnterDelay={0.5}
      content={
        <Box width={280}>
          <Box display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Commitment Name
            </Typography.Text>
            <Typography.Link href={`/commitment/${commitment.id}`} target="_blank">
              {commitment?.name}
            </Typography.Link>
          </Box>

          <Box display="flex" flexDirection="column" my={8}>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Project
            </Typography.Text>
            <Typography.Text>{commitment?.project_name}</Typography.Text>
          </Box>

          <Box display="flex" flexDirection="column" my={8}>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Vendor
            </Typography.Text>
            <Typography.Text>{commitment?.vendor_name}</Typography.Text>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              Orders
            </Typography.Text>
            {commitment.orders?.map((order) => (
              <Typography.Link key={order.id} href={`/order/${order.id}`} target="_blank">
                {order.order_number}
              </Typography.Link>
            ))}
          </Box>
        </Box>
      }
      title={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography.Text>{commitment?.number}</Typography.Text>
          <CommitmentState state={commitment?.state} />
        </Box>
      }
    >
      {children}
    </Popover>
  )
}
