import React from 'react'

import { Button } from 'antd'

import { FlexBoxX } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { DrawerEditGroupModeType } from './types'

interface FooterProps {
  mode: DrawerEditGroupModeType
  onSave: () => boolean
  onClose: () => void
}

export const Footer = React.memo(({ mode, onSave, onClose }: FooterProps) => {
  const handleAction = () => {
    const shouldClose = onSave()
    if (shouldClose) {
      onClose()
    }
  }

  const getLabel = () => {
    if (mode === 'add') return 'Add to Group'
    if (mode === 'ungroup') return 'Ungroup'
    return 'Save'
  }

  return (
    <FlexBoxX
      position="sticky"
      justifyContent="flex-end"
      paddingX={16}
      paddingY={10}
      bottom={0}
      style={{ gap: 16 }}
      backgroundColor={theme.colors.white}
      borderTop={`1px solid ${theme.colors['gray-4']}`}
    >
      <Button type="ghost" size="middle" style={{ width: 100 }} onClick={onClose}>
        Back
      </Button>
      <Button type="primary" size="middle" style={{ minWidth: 100, width: 'fit-content' }} onClick={handleAction}>
        {getLabel()}
      </Button>
    </FlexBoxX>
  )
})
