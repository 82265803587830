import './style.css'

import React from 'react'

import { useHistory } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'
import { Button, notification } from 'antd'

import theme from 'common/styles/theme'

type NotifyWarehouseParams = {
  message: string
  warehouseId?: string
  isNewTab?: boolean
}

export const useNotifyWarehouse = () => {
  const key = `warehouse-notify-${new Date().getTime()}`
  const history = useHistory()

  const handleOpenClick = (warehouseId: string, isNewTab: boolean) => {
    notification.close(key)
    const url = `/warehouses/${warehouseId}`
    isNewTab ? window.open(url, '_blank') : history.push(url)
  }

  const notifyWarehouse = ({ message, warehouseId = '', isNewTab }: NotifyWarehouseParams) => {
    const closeIconElement = <CloseOutlined style={{ color: theme.colors['gray-7'] }} />

    const actionButton = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          handleOpenClick(warehouseId, isNewTab)
        }}
      >
        View Warehouse
      </Button>
    )

    notification.success({
      message,
      duration: 10,
      placement: 'bottomLeft',
      key,
      className: 'use-notify-warehouse',
      closeIcon: closeIconElement,
      btn: actionButton,
    })
  }

  return { notifyWarehouse }
}
