import React, { useRef, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Space, Typography } from 'antd'

import { DrawerRef } from 'common/components/Drawer'

import { AttributeTable } from 'contractor/pages/Inventory/Warehouses/common/components/WarehouseForm/CustomAtributes/attributes_table'
import { CustomAttributeDrawer } from 'contractor/pages/Inventory/Warehouses/common/components/WarehouseForm/CustomAtributes/CustomAttributeDrawer/custom_attribute_drawer'
import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { ItemAttribute } from 'contractor/server/warehouses'

export const CustomAttributes = () => {
  const customAttributesDrawerRef = useRef<DrawerRef>()
  const { setWarehouse } = useWarehouse()
  const [selectedAttribute, setSelectedAttribute] = useState<ItemAttribute>(null)
  const handleDelete = (name: string, type: string) => {
    setWarehouse((prevWarehouse) => {
      const currentValues = prevWarehouse[type]
      const updatedValues = currentValues.filter((item) => item.name !== name)
      return { ...prevWarehouse, [type]: updatedValues }
    })
  }

  const handleEdit = (item: ItemAttribute) => {
    setSelectedAttribute(item)
    customAttributesDrawerRef.current?.show()
  }

  const handleCloseDrawer = () => {
    setSelectedAttribute(null)
    customAttributesDrawerRef.current?.close()
  }

  return (
    <>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Button
          style={{ width: '100%' }}
          icon={<PlusOutlined />}
          onClick={() => customAttributesDrawerRef.current?.show()}
        >
          <Typography.Text>Create new attribute</Typography.Text>
        </Button>

        <AttributeTable onDelete={handleDelete} onEdit={handleEdit} />
      </Space>
      <CustomAttributeDrawer
        attribute={selectedAttribute}
        onClose={handleCloseDrawer}
        ref={customAttributesDrawerRef}
      />
    </>
  )
}
