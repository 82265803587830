import { useState, useRef, useEffect } from 'react'

import { message } from 'antd'

import { noticeError } from 'common/helpers/new_relic'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateInventoryRequestRequest } from 'contractor/server/inventory/requests'

export const useCreateRequest = ({ onSuccess }) => {
  const { inventoryRequestStore } = useStores()

  const [isSubmitting, toggleIsSubmitting] = useState(false)

  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const handleCreateRequest = async (request: CreateInventoryRequestRequest) => {
    try {
      toggleIsSubmitting(true)
      await inventoryRequestStore.createRequest(request)
      message.success('Successfully created request')
      onSuccess?.()
    } catch (error) {
      noticeError(error, { entry: 'create-inventory-request' })
      message.error(error?.response?.data?.error || 'Unable to create request')
    } finally {
      if (isMounted.current) {
        toggleIsSubmitting(false)
      }
    }
  }

  return { handleCreateRequest, isSubmitting }
}
