import React from 'react'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'

import { Actions } from 'contractor/pages/Inventory/Warehouses/common/components/Actions/actions'
import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'

import { LeftActions } from './left_actions'

export const Header = observer(() => {
  const { goBack, isLoading, warehouse, form } = useWarehouse()

  return (
    <Box display="flex" alignItems="flex-start" justifyContent="space-between">
      <LeftActions goBack={goBack} />
      <Actions disabled={false} isLoading={isLoading} wareHouse={warehouse} onSubmit={form.submit} />
    </Box>
  )
})
