import React from 'react'

import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'

import { Box, BoxProps } from 'common/components/boxes'
import { ErrorBoundary } from 'common/components/ErrorBoundary'

import { HeaderStyled, PageStyled } from './styles'

type PageProps = {
  children?: React.ReactNode
  isFull?: boolean
} & BoxProps

type CommonProps = { children?: React.ReactNode; style?: React.CSSProperties } & BoxProps

export type HeaderProps = { showShadow?: boolean } & CommonProps

const Header = ({ showShadow = true, ...props }: HeaderProps) => {
  let paddings = {}
  if (showShadow) {
    paddings = {
      p: { _: 12, lg: 16 },
    }
  } else {
    paddings = {
      px: { _: 12, lg: 16 },
      pt: { _: 12, lg: 16 },
    }
  }

  return (
    <HeaderStyled {...paddings} bg="white" width="100%" {...props} className="sb-page-header" showShadow={showShadow} />
  )
}

const Content = (props: CommonProps) => (
  <Box width="100%" p={{ _: 12, lg: 16 }} flexGrow={1} overflowY="auto" {...props} />
)

export const Page = ({ isFull = false, children, ...props }: PageProps) => {
  return (
    <ErrorBoundary isFull={isFull}>
      <PageStyled width="100%" height="100%" bg="gray-2" alignItems="flex-start" justifyContent="flex-start" {...props}>
        {children}
      </PageStyled>
    </ErrorBoundary>
  )
}

Page.Header = Header
Page.Content = Content
Page.Tabs = (props: TabsProps) => <Tabs {...props} className="sb-page-tabs" />
