import React from 'react'

import { Result } from 'antd'

import { Box } from 'common/components/boxes'

import {
  SelectWarehouseFilter,
  SelectWarehouseFilterProps,
} from 'contractor/pages/Inventory/common/components/SelectWarehouseFilter'

type NoWarehouseSelectedProps = SelectWarehouseFilterProps

export const NoWarehouseSelected = (props: NoWarehouseSelectedProps) => {
  return (
    <Box flexGrow={1} mt={20}>
      <Result
        style={{ padding: 0 }}
        status="404"
        title="Please select a warehouse."
        extra={<SelectWarehouseFilter {...props} style={{ maxWidth: 300 }} />}
      />
    </Box>
  )
}
