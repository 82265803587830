import React from 'react'

import { Card, Col, Form, Input, Row, Space, Typography } from 'antd'

import { SelectProject } from 'contractor/components/SelectProject'
import { CustomAttributes } from 'contractor/pages/Inventory/Warehouses/common/components/WarehouseForm/CustomAtributes/custom_attributes'
import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'

import AddressForm from '../../../../../../../components/Addresses/address_form'

type WarehouseFormCardProps = {
  isLoading: boolean
}

export const WarehouseFormCard = (props: WarehouseFormCardProps) => {
  const { isLoading } = props
  const { form, warehouse } = useWarehouse()
  const disabledAddressForm = !!warehouse?.address

  return (
    <Card style={{ maxWidth: 786, width: '100%' }}>
      <Space direction="vertical" size={16} style={{ width: '100%' }}>
        <Typography.Title level={5} style={{ margin: 0 }}>
          General info
        </Typography.Title>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={12}>
            <Form.Item required label="Name" name="name" style={{ margin: 0 }}>
              <Input placeholder="Enter warehouse name" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={12}>
            <Form.Item label="Project" name="project_id" style={{ margin: 0 }}>
              <SelectProject style={{ width: '100%' }} loading={isLoading} />
            </Form.Item>
          </Col>
        </Row>

        <Typography.Title level={5} style={{ margin: 0 }}>
          Address
        </Typography.Title>

        <AddressForm form={form} disabled={disabledAddressForm} />

        <Typography.Title level={5} style={{ margin: 0 }}>
          Warehouse attributes
        </Typography.Title>

        <CustomAttributes />
      </Space>
    </Card>
  )
}
