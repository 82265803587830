import React, { useMemo, useState } from 'react'

import _ from 'lodash'

import { DeleteOutlined, ForwardOutlined } from '@ant-design/icons'
import { Button, Divider, Popconfirm, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxY } from 'common/components/boxes'
import { Drawer } from 'common/components/Drawer'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { useQuery } from 'common/hooks/use-query'
import { InvoiceResponse } from 'common/server/invoice'
import theme from 'common/styles/theme'

import { useStores } from 'contractor/hooks/use-stores'

import { useInvoice } from '../context'
import { OrderSearch } from '../OrderDetailNew'
import { CommitmentsAutocomplete } from '../OrderDetailNew/commitments_autocomplete'
import { CommitmentTagWrapper } from './commitment_tag_wrapper'
import { TagWrapper } from './tag_wrapper'

type OrdersTagsProps = {
  noFilter?: boolean
  disabled?: boolean
  attachedOrderIds: string[]
  onAttachOrders: (orderIds: string[]) => void
  onUnAttachOrder: (orderId: Nullable<string>) => void
  value?: string[]
  onChange?: (value: string[]) => void
  saveInvoice: (autoMatch?: boolean, orderIds?: string[]) => Promise<InvoiceResponse>
}

export const OrdersTags = observer<OrdersTagsProps>(
  ({ attachedOrderIds = [], onAttachOrders, onUnAttachOrder, value, onChange, noFilter, saveInvoice, disabled }) => {
    const { invoiceStore } = useStores()
    const { invoice, showLinkOrderModal, toggleLinkOrderModal } = useInvoice()

    const { data, isLoading } = useQuery(
      () =>
        invoiceStore.getAvailableCommitments({
          company_vendor_id: invoice?.company_vendor?.id,
          project_id: invoice?.project?.id,
        }),
      [invoice?.company_vendor?.id, invoice?.project?.id],
      !!invoice,
    )

    const [showLinkCommitmentModal, toggleLinkCommitmentModal] = useState(false)

    const makeOrderFilterOption = ({ order }) => ({
      wrapper: (children) => <TagWrapper order={order}>{children}</TagWrapper>,
      leftIcon: order?.quick_created_at ? <ForwardOutlined /> : undefined,
      label: order?.order_number,
      filter: order?.id,
      rightIcon: !disabled && (
        <Popconfirm
          title="Are you sure to remove this order?"
          okText="Yes"
          cancelText="No"
          onConfirm={(e) => {
            e.stopPropagation()
            onUnAttachOrder(order.id)
            if (value?.includes(order.id) || !invoiceStore.selectedOrders?.length) {
              onChange?.(['all'])
            }
          }}
        >
          <DeleteOutlined onClick={(e) => e.stopPropagation()} />
        </Popconfirm>
      ),
    })

    const makeCommitmentFilterOption = (commitment) => ({
      wrapper: (children) => <CommitmentTagWrapper commitment={commitment}>{children}</CommitmentTagWrapper>,
      label: `${commitment.number}: ${commitment.name}`,
      filter: commitment.id,
      orderIds: commitment.orders.map((order) => order.id),
    })

    const ordersOptions = _.sortBy(invoiceStore.selectedOrders, ({ order }) => order?.order_number)?.map(
      makeOrderFilterOption,
    )

    const commitmentsOptions = _.sortBy(data, 'number')
      ?.map(makeCommitmentFilterOption)
      .filter((commitmentOption) =>
        invoiceStore.selectedOrders.some(({ order }) => order.commitment_id === commitmentOption.filter),
      )

    const allOrdersOptions = [
      ...(invoiceStore.selectedOrders.length ? [{ label: 'All', filter: 'all' }] : []),
      ...ordersOptions,
    ]

    const linkCommitmentButton = useMemo(
      () => (
        <Tooltip title="Link Commitment" placement="topRight">
          <Button
            disabled={disabled}
            shape="round"
            size="small"
            onClick={() => toggleLinkCommitmentModal(true)}
            style={{ padding: '0 8px' }}
          >
            Add commitment
          </Button>
        </Tooltip>
      ),
      [],
    )

    const linkOrderButton = useMemo(
      () => (
        <Tooltip title="Link Order" placement="topRight">
          <Button
            disabled={disabled}
            shape="round"
            size="small"
            onClick={() => toggleLinkOrderModal(true)}
            style={{ padding: '0 8px' }}
          >
            Add order
          </Button>
        </Tooltip>
      ),
      [],
    )

    const commitmentDrawer = useMemo(
      () => (
        <Drawer
          title="Link Commitment"
          bgGray
          width={720}
          open={showLinkCommitmentModal}
          onClose={() => toggleLinkCommitmentModal(false)}
          placement="left"
          closable={false}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box width="100%" overflowY="auto" p={16}>
              {!!attachedOrderIds?.length && (
                <Box mb={20}>
                  <Typography.Text>Linked Commitments</Typography.Text>
                  <RibbonFilter options={commitmentsOptions} size="small" />
                </Box>
              )}

              <FlexBoxY width="100%" alignItems="flex-start" justifyContent="center">
                <Typography.Text style={{ paddingBottom: 10 }}>
                  Select the commitment associated with this Invoice
                </Typography.Text>
                <CommitmentsAutocomplete
                  data={data}
                  isLoading={isLoading}
                  onSelect={(commitment) => {
                    if (commitment?.orders.length) onAttachOrders(commitment?.orders.map((order) => order.id))
                    toggleLinkCommitmentModal(false)
                  }}
                />
              </FlexBoxY>
            </Box>
          </Box>
        </Drawer>
      ),
      [
        onAttachOrders,
        showLinkCommitmentModal,
        attachedOrderIds.length,
        invoice?.total_amount,
        invoice?.extracted_order_number,
        invoice?.company_vendor_id,
      ],
    )

    const orderDrawer = useMemo(
      () => (
        <Drawer
          title="Link Order"
          bgGray
          width={720}
          open={showLinkOrderModal}
          onClose={() => toggleLinkOrderModal(false)}
          placement="left"
          closable={false}
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Box width="100%" overflowY="auto" p={16}>
              {!!attachedOrderIds?.length && (
                <Box mb={20}>
                  <Typography.Text>Linked Orders</Typography.Text>
                  <RibbonFilter options={ordersOptions} size="small" />
                </Box>
              )}

              <OrderSearch
                onSelect={(order) => {
                  onAttachOrders([order.id])
                  toggleLinkOrderModal(false)
                }}
                onCreated={(order) => {
                  onAttachOrders([order.id])
                  toggleLinkOrderModal(false)
                }}
                initialValues={{
                  grandTotal: invoice?.total_amount,
                  orderNumber: invoice?.extracted_order_number,
                  companyVendorId: invoice?.company_vendor_id,
                }}
                onClickToCreateQuickPO={() => toggleLinkOrderModal(false)}
                saveInvoice={saveInvoice}
              />
            </Box>

            <Box display="flex" flexDirection="column" width="100%" overflowY="auto" p={16} style={{ gap: 12 }}>
              <Typography.Text style={{ color: theme.colors['gray-7'], fontSize: 12, fontWeight: 700 }}>
                Search for comitted orders
              </Typography.Text>

              <OrderSearch
                onSelect={(order) => {
                  onAttachOrders([order.id])
                  toggleLinkOrderModal(false)
                }}
                onCreated={(order) => {
                  onAttachOrders([order.id])
                  toggleLinkOrderModal(false)
                }}
                initialValues={{
                  grandTotal: invoice?.total_amount,
                  orderNumber: invoice?.extracted_order_number,
                  companyVendorId: invoice?.company_vendor_id,
                }}
                onClickToCreateQuickPO={() => toggleLinkOrderModal(false)}
                saveInvoice={saveInvoice}
                label="Only orders that have a commitment attached to them will appear."
                onlyOrdersWithCommitment
              />
            </Box>
          </Box>
        </Drawer>
      ),
      [
        onAttachOrders,
        showLinkOrderModal,
        attachedOrderIds.length,
        invoice?.total_amount,
        invoice?.extracted_order_number,
        invoice?.company_vendor_id,
      ],
    )

    if (noFilter) {
      return (
        <>
          {orderDrawer}
          {commitmentDrawer}
          <Box display="inline-flex" alignItems="center">
            <RibbonFilter options={commitmentsOptions} size="small" />
            {linkCommitmentButton}

            <Divider type="vertical" style={{ height: 28 }} />

            <RibbonFilter options={ordersOptions} size="small" />
            {linkOrderButton}
          </Box>
        </>
      )
    }

    return (
      <>
        {commitmentDrawer}
        {orderDrawer}
        <Box
          width="100%"
          display="flex"
          alignContent="center"
          justifyContent="space-between"
          position="sticky"
          top={-15}
          zIndex={1}
          py={8}
          bg="gray-2"
          height={48}
        >
          {!!invoiceStore.selectedOrders.length && (
            <Box display="inline-flex" alignItems="center">
              <RibbonFilter value={value} onChange={onChange} options={allOrdersOptions} size="small" />
              <Divider type="vertical" />
              {linkOrderButton}
            </Box>
          )}
        </Box>
      </>
    )
  },
)
