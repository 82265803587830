import { BindAll } from 'lodash-decorators'

import ListBaseStore from 'common/stores/ListBaseStore'

import { index, create, CreateInventoryRequestRequest } from 'contractor/server/inventory/requests'

export class ConsolidatedInventoryRequestStore extends ListBaseStore {
  index = index
}

@BindAll()
export default class InventoryRequestStore {
  listStore: ConsolidatedInventoryRequestStore

  constructor() {
    this.listStore = new ConsolidatedInventoryRequestStore()
  }

  createRequest(stock: CreateInventoryRequestRequest) {
    return create(stock)
  }
}
