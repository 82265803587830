import React from 'react'

import styled from '@emotion/styled'

import { Collapse, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { InvoiceItem } from '../InvoiceItem'
import { DrawerEditGroupModeType, InvoiceMaterialItem } from './types'

interface GroupListProps {
  mode: DrawerEditGroupModeType
  invoicesItems: {
    persisted: InvoiceMaterialItem[]
    notPersisted: InvoiceMaterialItem[]
  }
  calcExtCost: (...rest) => number
  onCheckItem: (id: string) => void
}

const RenderItemsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;

  & > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors['gray-4']};
  }
`

export const GroupList = React.memo(({ mode, invoicesItems, calcExtCost, onCheckItem }: GroupListProps) => {
  const enableAdd = mode === 'add'
  const enableUngroup = mode === 'ungroup'

  return (
    <Collapse
      ghost
      expandIconPosition="right"
      defaultActiveKey={['grouped', 'others']}
      style={{ minHeight: 'calc(100% - 104px - 55px)' }}
    >
      <Collapse.Panel
        header={
          <Typography.Text style={{ fontSize: 12, fontWeight: 600, color: theme.colors['gray-7'] }}>
            Grouped items
          </Typography.Text>
        }
        key="grouped"
      >
        <RenderItemsContainer>
          {invoicesItems.persisted.map((item, idx) => (
            <Box key={idx} paddingY={12}>
              <InvoiceItem
                item={{
                  ...item,
                  invoice_material: item,
                  calcExtCost,
                  checked: item.checked,
                }}
                onCheck={enableUngroup ? () => onCheckItem(item.id) : undefined}
              />
            </Box>
          ))}
        </RenderItemsContainer>
      </Collapse.Panel>

      <Collapse.Panel
        header={
          <Typography.Text style={{ fontSize: 12, fontWeight: 600, color: theme.colors['gray-7'] }}>
            Exported invoice items
          </Typography.Text>
        }
        key="others"
      >
        <RenderItemsContainer>
          {invoicesItems.notPersisted.map((item, idx) => (
            <Box key={idx} paddingY={12}>
              <InvoiceItem
                item={{
                  ...item,
                  invoice_material: item,
                  calcExtCost,
                  checked: item.checked,
                }}
                onCheck={enableAdd ? () => onCheckItem(item.id) : undefined}
              />
            </Box>
          ))}
        </RenderItemsContainer>
      </Collapse.Panel>
    </Collapse>
  )
})
