import React, { createContext, useContext, useEffect, useState, useCallback, useRef, useMemo } from 'react'

import { useHistory } from 'react-router-dom'
import { useLastLocation } from 'react-router-last-location'

import { flatten } from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import { Form, FormInstance } from 'antd'

import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'

import { DrawerRef } from 'common/components/Drawer'
import { currencyFormatter as currencyFormatterDefault } from 'common/helpers/formatters'
import { calcExtCost as calcExtCostDefault, calcInvoiceTaxSplitBulk } from 'common/helpers/order'
import { CostCode } from 'common/server/cost_codes/cost_codes'
import { InvoiceMaterial, InvoiceMaterialGroup, InvoiceOrder, InvoiceResponse } from 'common/server/invoice'
import { InvoiceInboxFilesStates } from 'common/server/server_types'

import { useLockInvoice, LockInvoiceResponse } from 'contractor/hooks/use-lock-invoice'
import { useStores } from 'contractor/hooks/use-stores'

import { DraggableType } from './InvoiceMaterials'

export const INVOICE_EXTRA_FILE_KEY = 'extra_files'

const InvoiceContext = createContext<InvoiceContextProps>({} as InvoiceContextProps)

export const useInvoice = () => useContext(InvoiceContext)

export type InvoiceReconcileStatus = 'all' | 'matches' | 'partial-matches' | 'no-matches' | 'all_order_items'

type ModeViewType = 'reconciliation' | 'review' | 'order_view'
type PageViewType = 'pdf' | 'order_items'

type InvoiceContextProps = {
  form: FormInstance
  selectedInvoiceDirty: boolean
  setSelectedInvoiceDirty: React.Dispatch<React.SetStateAction<boolean>>
  handleReconcile: () => void
  handleUnmatch: (orderMaterialId: string) => void
  handleToggleInvoiceOrderMaterialDrawer: (orderId: string) => void
  invoiceOrderMaterialDrawerRef: React.MutableRefObject<DrawerRef>
  calculatedEligibleTaxSplitTotal: number
  calculatedSubtotal: number
  calculatedGrandTotal: number
  calculatedMobileGrandTotal: number
  calculatedAdditionalCosts: number
  selectedOrderIdOnDrawer: Nullable<string>
  taxAmountField: number
  shippingCostField: number
  discountAmountField: number
  otherCostsField: number
  allInvoicesExceptTheCurrent: InvoiceResponse[]
  calcExtCost: (params: {
    unitCost: number
    quantity: number
    multiplier?: number | string
    qtyIncrement?: number | string
  }) => number
  currencyFormatter: (value: number) => string
  calcTaxSplitBulk: (params: {
    taxAmount: number
    invoiceMaterials: InvoiceResponse['invoice_materials']
    taxLineItemsEnabled: boolean
  }) => InvoiceResponse['invoice_materials']
  taxLineItemsEnabled?: boolean
  lockInvoice: LockInvoiceResponse
  goBack: () => void
  inReviewAttachment: boolean
  invoiceMaterialsFiltered: {
    materials: InvoiceMaterial[]
    materialsFilteredBySearch: InvoiceMaterial[]
    groups: InvoiceMaterialGroup[]
  }
  searchTerm: string
  setSearchTerm: (searchTerm: string) => void
  handleSelectInvoiceMaterials: (invoiceMaterialsId: string[]) => void
  toggleAllInvoiceMaterialsChecked: (checkAll?: boolean) => void
  allInvoiceMaterialsIsChecked: boolean
  handleSaveInvoiceMaterialsGroup: (group_name: string, invoice_materials: InvoiceMaterial[], group_id?: string) => void
  handleRemoveMaterialItems: (invoiceMaterials: InvoiceMaterial[], group_name?: string) => void
  handleAddInvoiceMaterial: (invoice_materials: (InvoiceMaterial & { position?: number })[]) => void
  handleEditInvoiceMaterial: (invoice_material: InvoiceMaterial) => void
  handleSearch: (searchTerm: string) => void
  accountingDetails: {
    costCodes: CostCode[]
    costCodeSettings: {
      required: boolean
      project_filtering_enabled: boolean
    }
    projectId: string
  }
  invoice: InvoiceResponse
  selectedPdfInvoice: string
  setSelectedPdfInvoice: React.Dispatch<React.SetStateAction<string | null>>
  currentReconcileStatus: InvoiceReconcileStatus
  setCurrentReconcileStatus: React.Dispatch<React.SetStateAction<InvoiceReconcileStatus>>
  selectedOrderMaterialIdsToReconcile: string[]
  handleSelectOrderMaterialIdToReconcile: (orderMaterialId: string) => void
  selectedInvoiceMaterials: string[]
  handleUpdateInvoiceMaterials: (invoice_materials: InvoiceMaterial[]) => void
  showLinkOrderModal: boolean
  toggleLinkOrderModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedOrders: InvoiceOrder[]
  modeView: ModeViewType
  setModeView: React.Dispatch<React.SetStateAction<ModeViewType>>
  handleChangeInvoiceMaterial: (invoice_material: InvoiceMaterial) => void
  pdfViewMode: PageViewType
  setPdfViewMode: React.Dispatch<React.SetStateAction<PageViewType>>
}

export const InvoiceProvider = observer(({ children }) => {
  const { invoiceStore, costCodeStore, companySettingStore, uploaderStore } = useStores()

  const history = useHistory()
  const lastLocation = useLastLocation()
  const [previousListLocation] = useState(lastLocation)

  const invoiceOrderMaterialDrawerRef = useRef<DrawerRef>()
  const [selectedOrderIdOnDrawer, setSelectedOrderIdOnDrawer] = useState(null)
  const [selectedOrderMaterialIdsToReconcile, setSelectedOrderMaterialIdsToReconcile] = useState<string[]>([])
  const [selectedInvoiceMaterials, setSelectedInvoiceMaterials] = useState<string[]>([])
  const [selectedPdfInvoice, setSelectedPdfInvoice] = useState<string>()
  const [currentReconcileStatus, setCurrentReconcileStatus] = useState<InvoiceReconcileStatus>('all')
  const [showLinkOrderModal, toggleLinkOrderModal] = useState(false)
  const [modeView, setModeView] = React.useState<ModeViewType>('reconciliation')
  const [pdfViewMode, setPdfViewMode] = React.useState<PageViewType>('pdf')

  const [searchTerm, setSearchTerm] = useState('')
  const [selectedInvoiceDirty, setSelectedInvoiceDirty] = useState(false)
  const [form] = Form.useForm()

  const taxAmountField = Form.useWatch('taxAmount', form) || 0
  const shippingCostField = Form.useWatch('shippingCost', form) || 0
  const discountAmountField = Form.useWatch('discountAmount', form) || 0
  const otherCostsField = Form.useWatch('otherCosts', form) || 0

  const invoice = invoiceStore.invoice
  const selectedOrders = invoiceStore.selectedOrders

  const lockInvoice = useLockInvoice(invoice)

  const taxLineItemsEnabled = React.useMemo(
    () =>
      modeView === 'reconciliation' &&
      (invoice?.project?.tax_line_items?.enabled || [...(invoice?.projects || [])]?.[0]?.tax_line_items?.enabled),
    [invoice?.project?.tax_line_items?.enabled, invoice?.projects, modeView],
  )

  const inReviewAttachment = useMemo(
    () => invoiceStore.invoice?.invoice_inbox_attachment?.state === InvoiceInboxFilesStates.IN_REVIEW,
    [invoiceStore.invoice?.invoice_inbox_attachment?.state],
  )

  const [allInvoicesExceptTheCurrent, setAllInvoicesExceptTheCurrent] = useState<InvoiceResponse[]>([])

  const accountingDetails = useMemo(
    () => ({
      costCodes: toJS(costCodeStore.costCodeListStore.records),
      costCodeSettings: companySettingStore.otherSettings?.cost_code_settings,
      projectId: invoice?.project?.id,
    }),
    [
      invoice?.project?.id,
      costCodeStore?.costCodeListStore.records.length,
      companySettingStore.otherSettings?.cost_code_settings,
    ],
  )

  const invoiceMaterialsFiltered = useMemo(() => {
    const invoice = invoiceStore.invoice
    if (!invoice) return { materials: [], groups: [] }

    const enrichMaterialData = (materialId: string) => {
      return invoice.invoice_materials?.find((m) => m.id === materialId)
    }

    const materialsInGroups = new Set(
      invoice.invoice_materials_groups?.flatMap(
        (group) => group.invoice_materials_ids ?? group.invoice_materials?.map((m) => m.id) ?? [],
      ),
    )

    const materials = (invoice.invoice_materials ?? [])
      .filter((material) => material.description && !materialsInGroups.has(material.id))
      .map((material) => ({
        ...material,
        checked: selectedInvoiceMaterials.includes(material.id),
      }))

    const materialsFilteredBySearch = (invoice.invoice_materials ?? [])
      .filter(
        (material) =>
          material.description?.toLowerCase().includes(searchTerm.toLowerCase()) && !materialsInGroups.has(material.id),
      )
      .map((material) => ({
        ...material,
        checked: selectedInvoiceMaterials.includes(material.id),
      }))

    const groups = (invoice.invoice_materials_groups ?? [])
      .map((group) => {
        const groupMaterials = group.invoice_materials_ids
          ? group.invoice_materials_ids.map(enrichMaterialData)
          : group.invoice_materials

        return {
          ...group,
          invoice_materials: groupMaterials?.map((material) => ({
            ...material,
            checked: selectedInvoiceMaterials.includes(material.id),
          })),
          checked: groupMaterials?.every((material) => selectedInvoiceMaterials.includes(material.id)),
        }
      })
      .filter((group) => {
        return group.invoice_materials?.some((material) =>
          material.description?.toLowerCase().includes(searchTerm.toLowerCase()),
        )
      })

    return {
      materialsFilteredBySearch,
      materials,
      groups,
    }
  }, [
    invoiceStore.invoice?.invoice_materials,
    invoiceStore.invoice?.invoice_materials_groups,
    searchTerm,
    selectedInvoiceMaterials,
  ]) as InvoiceContextProps['invoiceMaterialsFiltered']

  const allInvoiceMaterialsIsChecked = useMemo(() => {
    const totalInvoiceMaterials = invoiceMaterialsFiltered.groups.reduce(
      (total, group) => total + (group.invoice_materials?.length || 0),
      0,
    )
    const totalMaterials = totalInvoiceMaterials + invoiceMaterialsFiltered.materialsFilteredBySearch?.length
    return totalMaterials > 0 && selectedInvoiceMaterials.length === totalMaterials
  }, [invoiceMaterialsFiltered, selectedInvoiceMaterials])

  /*
    We need to show all invoices information in the review step.
    Since the current invoice might have N orders related, every order also might be
    related to N other invoices.
    To do it, we need to follow these steps:
    1. Load the current invoice.
    2. Load all orders related to the current invoice.
    3. Load all invoices related to the orders.
    In this point we have the 1 and 2 steps done. Now we need to load all invoices related to the orders.
  */
  useEffect(() => {
    if (invoiceStore.selectedOrders?.length && invoice?.id) {
      const invoicesIds = flatten(
        invoiceStore.selectedOrders.map(({ order }) => order.invoices.map(({ id }) => id)),
      ).filter((id) => id !== invoice.id)

      Promise.all(invoicesIds.map((invoiceId) => invoiceStore.getInvoiceById(invoiceId))).then(
        setAllInvoicesExceptTheCurrent,
      )
    }
  }, [invoiceStore.selectedOrders.length, invoice?.id])

  // Load the extra files from the invoice
  useEffect(() => {
    uploaderStore.resetAllUploads()
    uploaderStore.addExistingFiles(INVOICE_EXTRA_FILE_KEY, invoice?.extra_files)

    return () => {
      uploaderStore.resetAllUploads()
    }
  }, [invoice?.extra_files])

  const calcExtCost = useCallback(
    (params) => {
      return calcExtCostDefault({ ...params, precision: invoice?.decimal_precision })
    },
    [invoice?.decimal_precision],
  )

  const calcTaxSplitBulk = useCallback(
    (params) => calcInvoiceTaxSplitBulk({ ...params, precision: invoice?.decimal_precision }),
    [invoice?.decimal_precision],
  )

  const handleReconcile = useCallback(() => {
    const dataSource = calcTaxSplitBulk({
      invoiceMaterials: invoice?.invoice_materials,
      taxAmount: taxAmountField,
      taxLineItemsEnabled,
    })

    const selectedOrderMaterials = selectedOrderMaterialIdsToReconcile
      .map((id) => invoiceStore.selectedOrdersMaterials.find((orderMaterial) => orderMaterial.id === id))
      .filter(Boolean)

    const firstSelectedOrderMaterial = selectedOrderMaterials[0]

    const checkedMaterialIds = invoiceMaterialsFiltered.materialsFilteredBySearch
      .filter((item) => item?.checked)
      .map((item) => item.id)

    const checkedGroupMaterialIds = invoiceMaterialsFiltered.groups
      .filter((group) => group?.checked)
      .flatMap((group) => group.invoice_materials?.map((material) => material.id) || [])

    const invoiceMaterialIdsSelected = [...checkedMaterialIds, ...checkedGroupMaterialIds]

    const invoiceMaterials = dataSource.map((currentInvoiceMaterial) => {
      if (invoiceMaterialIdsSelected.includes(currentInvoiceMaterial.id)) {
        return {
          ...currentInvoiceMaterial,
          cost_code: currentInvoiceMaterial?.cost_code || firstSelectedOrderMaterial?.cost_code,
          cost_code_phase: currentInvoiceMaterial?.cost_code_phase || firstSelectedOrderMaterial?.cost_code_phase,
          order_materials: [...(currentInvoiceMaterial?.order_materials || []), ...selectedOrderMaterials],
        }
      }

      return currentInvoiceMaterial
    })

    invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)
    setSelectedOrderMaterialIdsToReconcile([])
    setSelectedInvoiceMaterials([])
    setSelectedInvoiceDirty(true)
  }, [
    invoice?.invoice_materials,
    invoiceMaterialsFiltered.materialsFilteredBySearch,
    invoiceMaterialsFiltered.groups,
    invoiceStore.selectedOrdersMaterials,
    selectedOrderMaterialIdsToReconcile,
  ])

  const handleUnmatch = useCallback(
    (orderMaterialId) => {
      const invoiceMaterials = invoiceStore.invoice.invoice_materials.map((invoiceMaterial) => {
        if (invoiceMaterial?.order_materials?.some((orderMaterial) => orderMaterial.id === orderMaterialId)) {
          return {
            ...invoiceMaterial,
            order_materials: invoiceMaterial.order_materials?.filter(
              (orderMaterial) => orderMaterial.id !== orderMaterialId,
            ),
          }
        }

        return invoiceMaterial
      })
      invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)

      const selectedOrdersMaterials = invoiceStore.selectedOrdersMaterials.map((orderMaterial) => {
        if (orderMaterialId === orderMaterial.id) {
          return {
            ...orderMaterial,
            is_invoiced: false,
          }
        }

        return orderMaterial
      })
      invoiceStore.updateSelectedOrdersMaterials(selectedOrdersMaterials)
      setSelectedInvoiceDirty(true)
    },
    [invoiceStore.invoice?.invoice_materials?.length],
  )

  const handleToggleInvoiceOrderMaterialDrawer = useCallback((orderId) => {
    setSelectedOrderIdOnDrawer(orderId)
    invoiceOrderMaterialDrawerRef.current?.toggle()
  }, [])

  const handleSelectInvoiceMaterials = useCallback(
    (invoiceMaterialsIds: string[]) => {
      setSelectedInvoiceMaterials((prev) => {
        const newSelection = [...prev]

        invoiceMaterialsIds.forEach((id) => {
          const isSelected = prev.includes(id)

          if (isSelected) {
            const index = newSelection.indexOf(id)
            if (index > -1) {
              newSelection.splice(index, 1)
            }
          } else {
            newSelection.push(id)
          }
        })

        return newSelection
      })
    },
    [setSelectedInvoiceMaterials],
  )

  const handleSaveInvoiceMaterialsGroup = useCallback(
    (group_name: string, invoice_materials: InvoiceMaterial[], group_id?: string) => {
      const data: InvoiceMaterialGroup = {
        id: group_id,
        name: group_name,
        invoice_materials_ids: invoice_materials.map((material) => material.id),
        extended_price: Number(
          invoice_materials
            .reduce((acc, invoiceMaterial) => {
              const extendedCost =
                calcExtCost({
                  unitCost: invoiceMaterial.unit_price,
                  quantity: invoiceMaterial.quantity_shipped,
                  multiplier: invoiceMaterial.unit?.multiplier,
                  qtyIncrement: invoiceMaterial.unit?.qty_increment,
                }) || 0
              return acc + extendedCost
            }, 0)
            .toFixed(invoice?.decimal_precision),
        ),
        invoice_id: invoiceStore.invoice?.id,
        quantity_shipped: invoice_materials.reduce(
          (acc, invoiceMaterial) => acc + invoiceMaterial?.quantity_shipped,
          0,
        ),
        unit_name: invoice_materials.every(
          (invoiceMaterial) => invoiceMaterial.unit?.name === invoice_materials[0]?.unit?.name,
        )
          ? invoice_materials[0]?.unit?.name
          : '',
        unit_price:
          invoice_materials.every(
            (invoiceMaterial) => invoiceMaterial?.unit_price === invoice_materials[0]?.unit_price,
          ) && Number(invoice_materials[0]?.unit_price),
      }

      if (group_id && !data.invoice_materials_ids.length) {
        invoiceStore.updateSelectedInvoice(
          'invoice_materials_groups',
          invoiceStore.invoice?.invoice_materials_groups?.filter((group) => group.id !== group_id),
        )
      } else if (group_id) {
        const groupExist = invoiceStore.invoice?.invoice_materials_groups.some((group) => group.id === group_id)
        if (groupExist) {
          invoiceStore.updateSelectedInvoice(
            'invoice_materials_groups',
            invoiceStore.invoice?.invoice_materials_groups?.map((group) => {
              if (group.id === group_id) return data
              return group
            }),
          )
        } else {
          invoiceStore.updateSelectedInvoice(
            'invoice_materials_groups',
            invoiceStore.invoice?.invoice_materials_groups?.concat(data),
          )
        }
      } else {
        invoiceStore.updateSelectedInvoice(
          'invoice_materials_groups',
          invoiceStore.invoice?.invoice_materials_groups?.concat({
            ...data,
            id: `new-group-${uuidV4()}`,
          }),
        )
      }

      setSelectedInvoiceMaterials([])
      setSearchTerm('')
    },
    [invoiceStore.invoice, calcExtCost],
  )

  const handleAddInvoiceMaterial = useCallback(
    (invoice_materials: (InvoiceMaterial & { position?: number })[]) => {
      const currentMaterials = [...(invoiceStore.invoice?.invoice_materials || [])]

      invoice_materials.forEach((material) => {
        if (typeof material.position === 'number') currentMaterials.splice(material.position, 0, material)
        if (typeof material.position !== 'number') currentMaterials.push(material)
      })

      invoiceStore.updateSelectedInvoice('invoice_materials', currentMaterials)
    },
    [invoiceStore.invoice?.invoice_materials],
  )

  const handleEditInvoiceMaterial = useCallback(
    (invoice_material: InvoiceMaterial) => {
      const currentMaterials = [...(invoiceStore.invoice?.invoice_materials || [])]
      const index = currentMaterials.findIndex((material) => material.id === invoice_material.id)

      if (index > -1) {
        currentMaterials[index] = invoice_material
        invoiceStore.updateSelectedInvoice('invoice_materials', currentMaterials)
      }

      if (invoiceStore.invoice?.invoice_materials_groups) {
        const updatedGroups = invoiceStore.invoice.invoice_materials_groups.map((materials_group) => {
          if (materials_group.invoice_materials) {
            const updatedMaterials = materials_group.invoice_materials.map((material) =>
              invoice_material.id === material.id ? invoice_material : material,
            )
            return { ...materials_group, invoice_materials: updatedMaterials }
          }
          return materials_group
        })

        invoiceStore.updateSelectedInvoice('invoice_materials_groups', updatedGroups)
      }
    },
    [invoiceStore.invoice?.invoice_materials],
  )

  const handleRemoveMaterialItems = useCallback(
    (invoice_materials: InvoiceMaterial[], group_id?: string) => {
      if (group_id) {
        invoiceStore.updateSelectedInvoice(
          'invoice_materials_groups',
          invoiceStore.invoice?.invoice_materials_groups?.filter((group) => group.id !== group_id),
        )
      }

      invoice_materials.forEach((invoiceMaterial) => {
        invoiceStore.updateSelectedInvoice(
          'invoice_materials',
          invoiceStore.invoice?.invoice_materials?.filter((material) => material.id !== invoiceMaterial.id),
        )
      })
    },
    [invoiceStore.invoice?.invoice_materials],
  )

  const toggleAllInvoiceMaterialsChecked = useCallback(
    (checkAll?: boolean) => {
      const allMaterials = [
        ...invoiceMaterialsFiltered.materialsFilteredBySearch,
        ...invoiceMaterialsFiltered.groups.flatMap((group) => group.invoice_materials),
      ]

      if (checkAll === undefined) {
        const allSelected = allMaterials.length === selectedInvoiceMaterials.length
        setSelectedInvoiceMaterials(allSelected ? [] : allMaterials.map((material) => material.id))
      } else {
        setSelectedInvoiceMaterials(checkAll ? allMaterials.map((material) => material.id) : [])
      }
    },
    [invoiceMaterialsFiltered, selectedInvoiceMaterials],
  )

  const handleSearch = useCallback(
    (term: string) => {
      setSelectedInvoiceMaterials(
        invoiceMaterialsFiltered.materials
          ?.filter(
            (material) =>
              selectedInvoiceMaterials.includes(material.id) &&
              material.description?.toLowerCase().includes(term.toLowerCase()),
          )
          ?.map((material) => material.id),
      )
      setSearchTerm(term)
    },
    [invoiceMaterialsFiltered?.materials, setSearchTerm],
  )

  const handleSelectOrderMaterialIdToReconcile = useCallback(
    (orderMaterialId: string) => {
      setSelectedOrderMaterialIdsToReconcile((prevSelectedIds) => {
        if (prevSelectedIds.includes(orderMaterialId)) {
          return prevSelectedIds.filter((id) => id !== orderMaterialId)
        }
        return [...prevSelectedIds, orderMaterialId]
      })
    },
    [setSelectedOrderMaterialIdsToReconcile],
  )

  const handleChangeInvoiceMaterial = useCallback(
    (invoiceMaterial: InvoiceMaterial) => {
      const currentMaterials = [...(invoiceStore.invoice?.invoice_materials || [])]
      const index = currentMaterials.findIndex((material) => material.id === invoiceMaterial.id)

      if (index > -1) {
        currentMaterials[index] = invoiceMaterial
        invoiceStore.updateSelectedInvoice('invoice_materials', currentMaterials)
      }
    },
    [invoiceStore.invoice?.invoice_materials],
  )

  const handleUpdateInvoiceMaterials = useCallback((invoice_materials: InvoiceMaterial[]) => {
    invoiceStore.updateSelectedInvoice('invoice_materials', invoice_materials)
  }, [])

  const currencyFormatter = useCallback(
    (value) => currencyFormatterDefault(value, invoice?.decimal_precision, invoice?.decimal_precision),
    [invoice?.decimal_precision],
  )

  const goBack = () => {
    if (previousListLocation) {
      history.push(previousListLocation)
    } else {
      history.push('/invoices')
    }
  }

  const calculatedEligibleTaxSplitTotal = React.useMemo(() => {
    return invoice?.invoice_materials
      ?.filter((invoiceMaterial) => invoiceMaterial.accepts_tax_split)
      ?.reduce((acc, invoiceMaterial) => {
        const extendedCost =
          calcExtCost({
            unitCost: invoiceMaterial?.unit_price,
            quantity: invoiceMaterial?.quantity_shipped,
            multiplier: invoiceMaterial?.unit?.multiplier,
            qtyIncrement: invoiceMaterial?.unit?.qty_increment,
          }) || 0
        return acc + extendedCost
      }, 0)
  }, [invoice?.invoice_materials, calcExtCost])

  const calculatedSubtotal = React.useMemo(() => {
    return invoice?.invoice_materials.reduce((acc, invoiceMaterial) => {
      const extendedCost =
        calcExtCost({
          unitCost: invoiceMaterial?.unit_price,
          quantity: invoiceMaterial?.quantity_shipped,
          multiplier: invoiceMaterial?.unit?.multiplier,
          qtyIncrement: invoiceMaterial?.unit?.qty_increment,
        }) || 0
      return acc + extendedCost
    }, 0)
  }, [invoice?.invoice_materials, calcExtCost])

  const calculatedAdditionalCosts = React.useMemo(() => {
    const taxAmount = Number(taxAmountField) || 0
    const shippingAmount = Number(shippingCostField) || 0
    const discountAmount = Number(discountAmountField) || 0
    const otherCosts = Number(otherCostsField) || 0

    return taxAmount + shippingAmount + otherCosts - discountAmount
  }, [taxAmountField, shippingCostField, discountAmountField, otherCostsField])

  const calculatedGrandTotal = React.useMemo(() => {
    return calculatedSubtotal + calculatedAdditionalCosts
  }, [calculatedAdditionalCosts, calculatedSubtotal])

  // We need this mobile version because in mobile we do not render the form and we need to get the value from the invoice
  const calculatedMobileGrandTotal = React.useMemo(() => {
    const taxAmount = Number(invoice?.tax_amount)
    const shippingAmount = Number(invoice?.shipping_cost)
    const discountAmount = Number(invoice?.discount_amount)
    const otherCosts = Number(invoice?.other_costs)

    return calculatedSubtotal + taxAmount + shippingAmount + otherCosts - discountAmount
  }, [calculatedSubtotal, invoice?.tax_amount, invoice?.shipping_cost, invoice?.discount_amount, invoice?.other_costs])

  useEffect(() => {
    if (invoiceStore.selectedOrdersMaterials.length) {
      const orderMaterials = invoiceStore.selectedOrdersMaterials?.map((orderMaterial) => ({
        ...orderMaterial,
        type: DraggableType[DraggableType.ORDER_MATERIAL],
      }))
      invoiceStore.updateSelectedOrdersMaterials(orderMaterials)
    }
  }, [invoiceStore.invoice?.id, invoiceStore.selectedOrdersMaterials.length])

  useEffect(() => {
    if (invoiceStore.invoice) {
      const invoiceMaterials = invoiceStore.invoice?.invoice_materials?.map((invoiceMaterial) => ({
        ...invoiceMaterial,
        type: DraggableType[DraggableType.INVOICE_MATERIAL],
      }))
      invoiceStore.updateSelectedInvoice('invoice_materials', invoiceMaterials)
    }
  }, [invoiceStore.invoice?.id])

  useEffect(() => {
    return () => {
      invoiceStore.resetStore()
    }
  }, [])

  return (
    <InvoiceContext.Provider
      value={{
        form,
        selectedInvoiceDirty,
        setSelectedInvoiceDirty,
        handleUnmatch,
        handleToggleInvoiceOrderMaterialDrawer,
        selectedOrderIdOnDrawer,
        invoiceOrderMaterialDrawerRef,
        calculatedEligibleTaxSplitTotal,
        calculatedSubtotal,
        calculatedGrandTotal,
        calculatedMobileGrandTotal,
        calculatedAdditionalCosts,
        taxAmountField,
        shippingCostField,
        discountAmountField,
        otherCostsField,
        allInvoicesExceptTheCurrent,
        calcExtCost,
        currencyFormatter,
        calcTaxSplitBulk,
        taxLineItemsEnabled,
        lockInvoice,
        goBack,
        inReviewAttachment,
        invoiceMaterialsFiltered,
        searchTerm,
        setSearchTerm,
        handleSelectInvoiceMaterials,
        toggleAllInvoiceMaterialsChecked,
        allInvoiceMaterialsIsChecked,
        handleSaveInvoiceMaterialsGroup,
        handleRemoveMaterialItems,
        handleAddInvoiceMaterial,
        handleEditInvoiceMaterial,
        handleSearch,
        accountingDetails,
        invoice,
        selectedPdfInvoice,
        setSelectedPdfInvoice,
        currentReconcileStatus,
        setCurrentReconcileStatus,
        selectedOrderMaterialIdsToReconcile,
        handleSelectOrderMaterialIdToReconcile,
        handleReconcile,
        selectedInvoiceMaterials,
        handleUpdateInvoiceMaterials,
        showLinkOrderModal,
        toggleLinkOrderModal,
        selectedOrders,
        modeView,
        setModeView,
        handleChangeInvoiceMaterial,
        pdfViewMode,
        setPdfViewMode,
      }}
    >
      {children}
    </InvoiceContext.Provider>
  )
})
