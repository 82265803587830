import React from 'react'

import { useInvoice } from '../context'
import { FooterReconciliation } from './footer_reconciliation'
import { FooterReview } from './footer_review'

export const Footer = () => {
  const { modeView } = useInvoice()

  if (modeView === 'order_view') return null
  if (modeView === 'review') return <FooterReview />
  return <FooterReconciliation />
}
