import React from 'react'

import { Button } from 'antd'

import { Warehouse } from 'contractor/server/warehouses'

export type ActionsProps = {
  isLoading: boolean
  disabled?: boolean
  wareHouse: Warehouse
  onSubmit: () => void
}

export const Actions = (props: ActionsProps) => {
  const { isLoading, disabled, wareHouse, onSubmit } = props
  const buttonText = wareHouse?.id ? 'Update' : 'Create'

  return (
    <Button
      data-cy={`${buttonText}-warehouse`}
      type="primary"
      onClick={onSubmit}
      loading={isLoading}
      disabled={disabled}
      style={{ marginLeft: 8 }}
    >
      {buttonText}
    </Button>
  )
}
