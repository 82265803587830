import { renderHook } from '@testing-library/react-hooks'

import { useStores } from 'contractor/hooks/use-stores'
import { useGetWarehouse } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-get-warehouse'

jest.mock('contractor/hooks/use-stores', () => ({
  useStores: jest.fn(),
}))

const mockLoadWarehouse = jest.fn()

beforeEach(() => {
  jest.clearAllMocks()
  ;(useStores as jest.Mock).mockReturnValue({
    warehouseStore: {
      loadWarehouse: mockLoadWarehouse,
    },
  })
})

describe('useGetWarehouse Hook', () => {
  it('should return data when loadWarehouse is successful', async () => {
    const mockData = { id: '1', name: 'Test Warehouse' }
    mockLoadWarehouse.mockResolvedValueOnce(mockData)

    const { result, waitForNextUpdate } = renderHook(() => useGetWarehouse({ id: '1' }))

    expect(result.current.isLoading).toBe(true)
    expect(mockLoadWarehouse).toHaveBeenCalledWith('1')

    await waitForNextUpdate()

    expect(result.current.isLoading).toBe(false)
    expect(result.current.data).toEqual(mockData)
    expect(result.current.isError).toBe(false)
  })

  it('should handle error when loadWarehouse fails', async () => {
    const mockError = new Error('Failed to load')
    mockLoadWarehouse.mockRejectedValueOnce(mockError)

    const { result, waitForNextUpdate } = renderHook(() => useGetWarehouse({ id: '2' }))

    expect(result.current.isLoading).toBe(true)
    expect(mockLoadWarehouse).toHaveBeenCalledWith('2')

    await waitForNextUpdate()

    expect(result.current.isLoading).toBe(false)
    expect(result.current.data).toBeNull()
    expect(result.current.isError).toBe(true)
  })

  it('should indicate loading state initially', () => {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    mockLoadWarehouse.mockReturnValue(new Promise(() => {}))

    const { result } = renderHook(() => useGetWarehouse({ id: '3' }))

    expect(result.current.isLoading).toBe(true)
    expect(result.current.data).toBeNull()
    expect(result.current.isError).toBe(false)
    expect(mockLoadWarehouse).toHaveBeenCalledWith('3')
  })
})
