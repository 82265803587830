import theme from 'common/styles/theme'

export const getRemainingPriceColor = (remaining: number) => {
  if (remaining === 0) {
    return theme.colors['gray-7']
  }

  if (remaining > 0) {
    return theme.colors.link
  }

  return theme.colors.error
}
