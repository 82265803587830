import React from 'react'

import { useHistory } from 'react-router-dom'

import { Button, Space } from 'antd'

export const ListActions = () => {
  const history = useHistory()
  const handleOpenNewWarehouse = () => {
    history.push('/warehouses/new')
  }

  return (
    <Button type="primary" onClick={handleOpenNewWarehouse}>
      <Space>New warehouse</Space>
    </Button>
  )
}
