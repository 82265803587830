import React from 'react'

import { DeleteOutlined, MoreOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Row, Col, Dropdown, Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { formatDateString } from 'common/helpers/formatters'

import { InventoryRequestState } from 'contractor/pages/Inventory/Requests/common/components/InventoryRequestState/inventory_request_state'
import { InventoryRequest } from 'contractor/server/inventory/requests'

type RequestRowProps = {
  consolidatedRequest: InventoryRequest
}

export const RequestRow = ({ consolidatedRequest }: RequestRowProps) => {
  const assignedTo = consolidatedRequest?.assigned_to
  const responsible = `${assignedTo?.first_name} ${assignedTo?.last_name}`

  return (
    <Box py={12} px={16} bg="white" borderRadius={4}>
      <Row gutter={[16, 16]} align="middle">
        <Col xs={24} sm={12} md={5} lg={5} xl={3} xxl={2}>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography.Text style={{ fontSize: 10 }} type="secondary">
              Status
            </Typography.Text>
            <InventoryRequestState state={consolidatedRequest.state} />
          </Box>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={10}>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography.Text style={{ fontSize: 10 }} type="secondary">
              Request
            </Typography.Text>
            <Typography.Text>{consolidatedRequest.number || 'N/A'}</Typography.Text>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={7} lg={7} xl={4} xxl={3}>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography.Text style={{ fontSize: 10 }} type="secondary">
              Project
            </Typography.Text>
            <Typography.Text>{consolidatedRequest?.project?.name || 'N/A'}</Typography.Text>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={8} lg={8} xl={3} xxl={2}>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography.Text style={{ fontSize: 10 }} type="secondary">
              Created at
            </Typography.Text>
            <Typography.Text>{formatDateString(consolidatedRequest.created_at, 'N/A')}</Typography.Text>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={8} lg={8} xl={3} xxl={3}>
          <Box display="flex" flexDirection="column" width="100%">
            <Typography.Text style={{ fontSize: 10 }} type="secondary">
              Responsible
            </Typography.Text>
            <Typography.Text>{responsible}</Typography.Text>
          </Box>
        </Col>

        <Col xs={24} sm={12} md={8} lg={8} xl={5} xxl={4}>
          <Box display="flex" alignItems="center" justifyContent="space-between" gridGap={10} width="100%">
            <Box display="flex" flexDirection="column" width="100%">
              <Typography.Text style={{ fontSize: 10 }} type="secondary">
                Delivery info
              </Typography.Text>
              {consolidatedRequest?.operations?.length
                ? consolidatedRequest?.operations?.map((operation) => (
                    <Typography.Text key={operation.id}>
                      {formatDateString(operation.requested_date, 'N/A')}
                    </Typography.Text>
                  ))
                : 'N/A'}
            </Box>

            <Dropdown
              menu={{
                items: [
                  { key: 'edit', label: 'Edit', icon: <EditOutlined /> },
                  { key: 'delete', label: 'Delete', icon: <DeleteOutlined />, danger: true },
                ],
              }}
              trigger={['click']}
            >
              <Button icon={<MoreOutlined />} style={{ minWidth: 24 }} size="small" />
            </Dropdown>
          </Box>
        </Col>
      </Row>
    </Box>
  )
}
