import React, { useState } from 'react'

import { Form, Switch, Divider, InputNumber, Typography, Row, Col, FormInstance } from 'antd'

import DateTimePicker from 'common/components/date_time_picker'
import { SelectUnit, makeOptionFromObject } from 'common/components/SelectUnit'
import { Unit } from 'common/server/units'

import { MaterialsAutocomplete } from 'contractor/components/MaterialsAutocomplete'
import { SelectWarehouse } from 'contractor/pages/Inventory/common/components/SelectWarehouse'

type FormContent = { units: Unit[]; form: FormInstance }

export const FormContent = ({ units = [], form }) => {
  const [selectedMaterialDescription, setSelectedMaterialDescription] = useState(null)

  return (
    <>
      <Typography.Title level={5}>General info</Typography.Title>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Name"
            name="company_material_id"
            rules={[
              {
                required: true,
                message: 'Please input the name!',
              },
            ]}
          >
            <MaterialsAutocomplete
              onSelect={(material) => {
                setSelectedMaterialDescription(material['description'])
                form.setFieldValue('company_material_id', material['company_material_id'])
                if (material['unit_id']) {
                  const unit = makeOptionFromObject(material)
                  form.setFieldValue('unit', unit)
                }
              }}
              placeholder={selectedMaterialDescription}
              FooterComponent={() => null}
              useCatalog={false}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="QTY"
            name="qty"
            rules={[
              {
                required: true,
                message: 'Please input the QTY!',
              },
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            label="UOM"
            name="unit"
            rules={[
              {
                required: true,
                message: 'Please input the UOM!',
              },
            ]}
          >
            <SelectUnit allowUnitCreation={false} units={units} allowClear style={{ width: '100%' }} labelInValue />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label="Send to quality hold" name="send_to_quality_hold" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 16, marginTop: 0 }} />

      <Typography.Title level={5}>Stock info</Typography.Title>

      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Warehouse"
            name="warehouse_id"
            rules={[
              {
                required: true,
                message: 'Please select a Warehouse!',
              },
            ]}
          >
            <SelectWarehouse />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Date in" name="date_in">
            <DateTimePicker wrapperProps={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Expiration date" name="expiration_date">
            <DateTimePicker wrapperProps={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={8}>
          <Form.Item label="Manufactured date" name="manufactured_date">
            <DateTimePicker wrapperProps={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}
