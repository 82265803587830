import React from 'react'

import _ from 'lodash'

import styled from '@emotion/styled'

import { ProjectOutlined, SearchOutlined, ShopOutlined } from '@ant-design/icons'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { AutoComplete, AutoCompleteProps, Input, Spin, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { AvailableCommitmentsResponse } from 'common/server/invoice'
import theme from 'common/styles/theme'

const StdInput = styled(Input)`
  color: ${({ theme }) => theme.colors['gray-9']};

  & > input {
    margin-left: 0.25rem;
  }
`

function DefaultNotFound() {
  return <Box p={4}>No commitments found</Box>
}

function renderItem(item: AvailableCommitmentsResponse) {
  return {
    value: item.id,
    label: (
      <Box display="flex" flexDirection="column" gridGap={4} width="100%" py={8}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Typography.Text style={{ fontWeight: 'semi-bold' }} ellipsis>
            {item.number}: {item.name}
          </Typography.Text>
        </Box>

        <Box display="flex" alignItems="center">
          <Box display="flex" alignItems="center">
            <ProjectOutlined style={{ color: theme.colors['gray-7'] }} />
            <Typography.Text type="secondary" style={{ margin: '0 4px' }} ellipsis={{ tooltip: item?.project_name }}>
              {item?.project_name}
            </Typography.Text>
          </Box>
          {item.vendor_name && (
            <Box display="flex" alignItems="center" ml="8">
              <ShopOutlined style={{ color: theme.colors['gray-7'] }} />
              <Typography.Text type="secondary" style={{ margin: '0 4px' }}>
                {item.vendor_name}
              </Typography.Text>
            </Box>
          )}
        </Box>
      </Box>
    ),
  }
}

export type CommitmentsAutocompleteProps = {
  data: AvailableCommitmentsResponse[]
  isLoading?: boolean
  onSelect: (item: AvailableCommitmentsResponse) => void
  size?: 'default' | 'large' | 'small'
} & AutoCompleteProps

function Autocomplete(props: CommitmentsAutocompleteProps) {
  const { data, isLoading, onSelect, size = 'default', ...rest } = props

  const [keyword, setKeyword] = React.useState<string>('')

  const options = React.useMemo(() => {
    const options: Array<{ label: React.ReactNode; value: string; disabled?: boolean }> = _.filter(
      data,
      (item) => !!item.orders?.length && item.name.toLowerCase().includes(keyword),
    )?.map((item) => renderItem(item))

    if (options.length === 0) return [{ label: DefaultNotFound(), value: 'not-found', disabled: true }]

    return options
  }, [data, keyword, isLoading])

  function handleSelect(value: string) {
    if (!value) return

    const item = data?.find((item) => item.id === value)

    if (!item) return

    onSelect(item)
    setKeyword('')
  }

  return (
    <AutoComplete
      options={options}
      onSelect={handleSelect}
      searchValue={keyword}
      data-cy="commitments-autocomplete"
      onSearch={setKeyword}
      dropdownMatchSelectWidth={size === 'default' && 350}
      style={{ width: '100%' }}
      allowClear
      value={keyword}
      onChange={setKeyword}
      {...rest}
    >
      <StdInput
        aria-autocomplete="none"
        placeholder="Commitment"
        prefix={isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 14 }} spin />} /> : <SearchOutlined />}
      />
    </AutoComplete>
  )
}

export const CommitmentsAutocomplete = observer(Autocomplete)
