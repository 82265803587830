import { useState, useEffect } from 'react'

export function useLocalStorage<T>(key: string, defaultValue?: T) {
  const [value, setValue] = useState<T>(() => {
    const storedState = localStorage.getItem(key)

    if (storedState) {
      return JSON.parse(storedState) as T
    }

    return typeof defaultValue === 'function' ? defaultValue() : defaultValue
  })

  const setStoredValue = (newValue: T) => {
    localStorage.setItem(key, JSON.stringify(newValue))
    setValue(newValue)
  }

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setStoredValue] as const
}
