import React from 'react'

import { Select, SelectProps } from 'antd'

import { useGetWarehouses } from './hooks'

export type SelectWarehouseProps = SelectProps

export const SelectWarehouse = ({ style, ...props }: SelectWarehouseProps) => {
  const { isLoading, warehouses } = useGetWarehouses()

  return (
    <Select
      filterOption={(input, option) => {
        const inputValue = input.toLowerCase()
        return option?.searchable?.toLowerCase().includes(inputValue)
      }}
      loading={isLoading}
      showSearch
      style={{ width: '100%', ...style }}
      options={warehouses?.map((warehouse) => ({
        value: warehouse.id,
        label: warehouse.name,
        searchable: warehouse.name,
      }))}
      {...props}
    />
  )
}
