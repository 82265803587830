import React from 'react'

import {} from '@ant-design/icons'
import { Typography } from 'antd'

import { Box } from 'common/components/boxes'

type QtyByStatusProps = {
  title: React.ReactNode
  children: React.ReactNode
}

export const QtyByStatus = ({ title, children }: QtyByStatusProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography.Text type="secondary" style={{ fontSize: 10 }}>
        {title}
      </Typography.Text>

      {children}
    </Box>
  )
}
