import { BindAll } from 'lodash-decorators'

import ListBaseStore from 'common/stores/ListBaseStore'

import { index, create, CreateStockRequest } from 'contractor/server/inventory/stocks'

export class ConsolidatedInventoryStockStore extends ListBaseStore {
  index = index
}

@BindAll()
export default class InventoryStockStore {
  listStore: ConsolidatedInventoryStockStore

  constructor() {
    this.listStore = new ConsolidatedInventoryStockStore()
  }

  createStock(stock: CreateStockRequest) {
    return create(stock)
  }
}
