import React, { useEffect, useCallback, useRef } from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { useLocalStorage } from 'common/hooks/use-local-storage'

import { SelectWarehouse, SelectWarehouseProps } from '../SelectWarehouse'

export type SelectWarehouseFilterProps = SelectWarehouseProps

export const SelectWarehouseFilter = ({ value, onChange, ...props }: SelectWarehouseFilterProps) => {
  const [localStorageWarehouseId, setLocalStorageWarehouseId] = useLocalStorage<string>(
    '@subbase/inventory/stock/warehouse_id',
    null,
  )

  const history = useHistory()
  const location = useLocation()
  const isInitialized = useRef(false)

  const setWarehouseIdToUrl = useCallback(
    (warehouseId: Nullable<string>) => {
      const params = new URLSearchParams(location.search)
      if (warehouseId) {
        params.set('warehouse_id', warehouseId)
      } else {
        params.delete('warehouse_id')
      }
      history.replace({ search: params.toString() })
    },
    [location.search, history],
  )

  useEffect(() => {
    if (!isInitialized.current) {
      const paramWarehouseId = new URLSearchParams(location.search).get('warehouse_id')
      const newWarehouseId = paramWarehouseId || localStorageWarehouseId
      if (newWarehouseId) {
        onChange?.(newWarehouseId, { value: newWarehouseId, label: '' })
      }

      if (paramWarehouseId !== localStorageWarehouseId) {
        if (newWarehouseId) {
          setWarehouseIdToUrl(newWarehouseId)
          setLocalStorageWarehouseId(newWarehouseId)
        }
      }

      // Mark as initialized to prevent further runs
      isInitialized.current = true
    }
  }, [localStorageWarehouseId, location.search, setWarehouseIdToUrl])

  return (
    <SelectWarehouse
      onChange={(value) => {
        setWarehouseIdToUrl(value)
        setLocalStorageWarehouseId(value)
        onChange?.(value, { value, label: '' })
      }}
      value={value}
      {...props}
    />
  )
}
