import { useNotifyWarehouse } from 'common/hooks/use-notify-warehouse'

import { useStores } from 'contractor/hooks/use-stores'
import { Warehouse } from 'contractor/server/warehouses'

type UseUpdateWarehouseProps = {
  payload: Warehouse
  onSuccess?: () => void
  onError?: (error: {}) => void
  setSubmitting?: (value: boolean) => void
}

export const useUpdateWarehouse = () => {
  const { warehouseStore } = useStores()
  const { notifyWarehouse } = useNotifyWarehouse()

  const updateWarehouse = async (props: UseUpdateWarehouseProps) => {
    const { payload, onSuccess, onError, setSubmitting } = props

    try {
      setSubmitting?.(true)
      await warehouseStore.updateWarehouse(payload)

      notifyWarehouse({
        message: 'Warehouse updated!',
        warehouseId: payload.id,
      })

      onSuccess?.()
    } catch (error) {
      onError?.(error)
    } finally {
      setSubmitting?.(false)
    }
  }

  return { updateWarehouse }
}
