import React from 'react'

import { CopyOutlined, DeleteOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons'
import { Button, Checkbox, Row, Col, Dropdown, Typography, Space } from 'antd'

import { Box } from 'common/components/boxes'

export const Actions = () => {
  return (
    <Box py={12} px={16} bg="white" borderRadius={4}>
      <Row gutter={[10, 10]} align="middle">
        <Col>
          <Checkbox style={{ minWidth: 16 }} />
        </Col>
        <Col>
          <Button icon={<PlusOutlined />}>New request</Button>
        </Col>
        <Col>
          <Button icon={<CopyOutlined />}>Copy</Button>
        </Col>
        <Col>
          <Button icon={<DeleteOutlined />} />
        </Col>
        <Col>
          <Dropdown
            menu={{
              items: [
                { key: '1', label: 'Fake attribute' },
                { key: '2', label: 'Fake location' },
              ],
            }}
            trigger={['click']}
          >
            <Space style={{ cursor: 'pointer' }}>
              <Typography.Text>Sort by</Typography.Text>
              <DownOutlined style={{ fontSize: 10 }} />
            </Space>
          </Dropdown>
        </Col>
      </Row>
    </Box>
  )
}
