import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { Autocomplete as CommonAutocomplete, AutocompleteRef, AutocompleteProps } from 'common/components/Autocomplete'

import { useStores } from 'contractor/hooks/use-stores'

import { StockItem } from './stock_item'

export type StocksAutocompleteProps = {
  onSelect: (stock: Record<string, unknown>) => void
  warehouseId: string
} & Omit<AutocompleteProps, 'onSelect'>

const Autocomplete = (props: StocksAutocompleteProps) => {
  const { onSelect, warehouseId, ...rest } = props

  const {
    inventoryStockStore: { listStore },
  } = useStores()

  const ref = useRef<AutocompleteRef>()

  useEffect(() => {
    listStore.setFilter('warehouse_id', warehouseId, true, true)
  }, [warehouseId])

  const renderItem = useCallback((item) => {
    return {
      value: item.id,
      label: <StockItem item={item} />,
    }
  }, [])

  const handleSelect = useCallback(
    (value: string) => {
      if (!value) return

      const item = listStore.records.find((item) => item.id === value)

      onSelect(item)
    },
    [listStore.records.length, onSelect],
  )

  const handleSearch = useCallback((value: string) => {
    const newValue = value?.trim()

    if (newValue?.length) {
      listStore.clearRecords()
      listStore.setSearch(newValue)
    }
  }, [])

  const options = useMemo(() => {
    return listStore.records.map(renderItem)
  }, [listStore.records.length, renderItem])

  return (
    <CommonAutocomplete
      ref={ref}
      onSearch={handleSearch}
      onSelect={handleSelect}
      isFetching={listStore.isFetching}
      options={options}
      data-cy="stocks-autocomplete"
      {...rest}
    />
  )
}

export const StocksAutocomplete = observer(Autocomplete)
