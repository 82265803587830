import React, { useEffect } from 'react'

import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { observer } from 'mobx-react-lite'

import { Tool, trackEvent } from 'common/analytics/event_tracking'
import { Events } from 'common/analytics/events'
import { Layout } from 'common/components/Layout'
import { Loading } from 'common/components/Loading'
import { useQuery } from 'common/hooks/use-query'
import { useReloadIdleUser } from 'common/hooks/use-reload-idle-user'

import { useExportListener } from 'contractor/components/Hooks/use-export-listener'
import MainMenu from 'contractor/components/MainMenu/index'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { ConcreteCommitmentDetails } from 'contractor/pages/@v2/Commitments/Concrete/CommitmentDetail/commitment_detail'
import { NewConcreteCommitment } from 'contractor/pages/@v2/Commitments/Concrete/NewCommitment/new_commitment'
import { CommitmentsPage } from 'contractor/pages/@v2/Commitments/ConsolidatedList/commitments_page'
import { MaterialCommitmentDetails } from 'contractor/pages/@v2/Commitments/Material/CommitmentDetail/commitment_detail'
import { NewCommitmentMaterial } from 'contractor/pages/@v2/Commitments/Material/NewCommitment/new_commitment'
import { InvoicesPageV2 } from 'contractor/pages/@v2/Invoices/invoices'
import { NewQuotePageV2 } from 'contractor/pages/@v2/NewQuote'
import { OrderDetailPageV2 } from 'contractor/pages/@v2/OrderDetail'
import { OrdersMaterialPageV2 } from 'contractor/pages/@v2/OrderMaterials/order_materials_page'
import { OrdersV2 } from 'contractor/pages/@v2/Orders'
import { InvoiceDetailV3 } from 'contractor/pages/@v3/invoices/Detail'
import Calendar from 'contractor/pages/Calendar/index'
import CompanyMaterial from 'contractor/pages/CompanyMaterials/index'
import { PriceSheetEdit } from 'contractor/pages/CompanyMaterials/PriceSheet/Edit'
import CompanySettings from 'contractor/pages/CompanySettings/index'
import CompanyVendor from 'contractor/pages/CompanyVendorContacts/index'
import CompareOrders from 'contractor/pages/CompareOrders/compare_orders'
import Dashboard from 'contractor/pages/Dashboard/index'
import Integrations from 'contractor/pages/Integrations'
import { InventoryPage } from 'contractor/pages/Inventory'
import { RequestCreate } from 'contractor/pages/Inventory/Requests/Create'
import { NewWarehouse } from 'contractor/pages/Inventory/Warehouses/NewWarehouse/new_warehouse'
import { WarehouseDetails } from 'contractor/pages/Inventory/Warehouses/WarehouseDetails/warehouse_details'
import InvoiceDetail from 'contractor/pages/Invoices/Detail'
import NewQuote from 'contractor/pages/NewQuote/index'
import OrderDetail from 'contractor/pages/OrderDetail/index'
import PrivateOrderForm from 'contractor/pages/PrivateOrderForm/index'
import ProjectsPage from 'contractor/pages/Projects'
import ProjectSettings from 'contractor/pages/ProjectSettings/index'
import SubscriptionPricingTable from 'contractor/pages/SubscriptionPricingTable/index'
import ContractorUserSettings from 'contractor/pages/UserSettings/index'
import { AllProviders } from 'contractor/providers'

// Disabled antd form console warning
window['ASYNC_VALIDATOR_NO_WARNING'] = 1

export const MaterialRequesterRoutes = () => {
  const { companySettingStore, userStore } = useStores()

  useQuery(companySettingStore.indexPermissions)

  const canUseCommitments = useFlag('commitments')
  const newCommitmentTypesEnabled = useFlag('new_commitment_types')

  const canUseInvoices = userStore.canUseInvoices && userStore.hasAnyInvoicePermission

  return (
    <Switch>
      <Route path="/order/:id">
        <OrderDetail />
      </Route>
      {canUseCommitments && (
        <Route path="/material_commitments/new">
          <NewConcreteCommitment />
        </Route>
      )}
      {canUseCommitments && (
        <Route path="/material_commitments/:id">
          <MaterialCommitmentDetails />
        </Route>
      )}
      {newCommitmentTypesEnabled && (
        <Route path="/concrete_commitments/new">
          <NewCommitmentMaterial />
        </Route>
      )}
      {newCommitmentTypesEnabled && (
        <Route path="/concrete_commitments/:id">
          <ConcreteCommitmentDetails />
        </Route>
      )}
      <Route path="/orders/new_order">
        <NewQuote orderType="Order" />
      </Route>
      {userStore?.canUseCreateRFQ && (
        <Route path="/orders/new_quote">
          <NewQuote orderType="RFQ" />
        </Route>
      )}
      {canUseInvoices && (
        <Route path="/invoices">
          <InvoicesPageV2 />
        </Route>
      )}
      {(userStore.canCreateNewMaterial || userStore.canEditMaterialDatabase) && (
        <Route path="/company_materials">
          <CompanyMaterial />
        </Route>
      )}
      {userStore.canManageVendors && (
        <Route path="/company_vendors">
          <CompanyVendor />
        </Route>
      )}
      <Route path="/new_order/*">
        <PrivateOrderForm />
      </Route>
      <Route path="/orders">
        <OrdersV2 />
      </Route>
      <Route path="/user_settings">
        <ContractorUserSettings />
      </Route>
      <Route path="*">
        <Redirect to="/orders" />
      </Route>
    </Switch>
  )
}

const TrackRoute = ({ track, path, component: Component }) => {
  const location = useLocation()

  const action = (event: Events) => {
    trackEvent(event, window.location.pathname, { params: location.search }, Tool.AMPLITUDE)
  }

  return (
    <Route
      path={path}
      render={(props) => {
        action(track)
        return <Component {...props} />
      }}
    />
  )
}

const Routes = observer(() => {
  useReloadIdleUser()

  const { userStore, companySettingStore, notificationStore } = useStores()
  const canUseNewOrderScreen = useFlag('order_screen_v2')
  const priceSheetFlagEnabled = useFlag('price_sheet')
  const canUseNewInvoiceDetailScreen = useFlag('invoice_detail_screen_v3') // new invoices detail screen

  const canUseCommitments = useFlag('commitments') && userStore.canSeeAndCreateCommitments
  const canUseInvoices = userStore.canUseInvoices && userStore.hasAnyInvoicePermission
  const newCommitmentTypesEnabled = useFlag('new_commitment_types')
  const canUseInventory = useFlag('inventory')

  const { isLoading } = useQuery(userStore.showUser)
  const { isLoading: isLoadingSettings } = useQuery(companySettingStore.indexOtherSettings)

  useExportListener({ userId: userStore?.currentUser?.id })

  useEffect(() => {
    if (userStore.currentUser?.id) {
      notificationStore.getIndexNotifications()
      notificationStore.subscribe(userStore.currentUser?.id)
    }
  }, [userStore.currentUser?.id])

  if (isLoading || isLoadingSettings) {
    return <Loading />
  }

  if (!userStore.currentUser) {
    return
  }

  if (userStore.isMaterialRequester) {
    return <MaterialRequesterRoutes />
  }

  return (
    <Switch>
      <TrackRoute track={Events.VIEW_DASHBOARD} path="/dashboard" component={Dashboard} />
      <TrackRoute track={Events.VIEW_PROJECTS} path="/projects" component={ProjectsPage} />
      <TrackRoute
        track={Events.VIEW_ORDER_DETAILS}
        path={`/order/:id`}
        component={canUseNewOrderScreen ? OrderDetailPageV2 : OrderDetail}
      />
      {canUseCommitments && (
        <Route exact path="/material_commitments/new">
          <NewCommitmentMaterial />
        </Route>
      )}
      {canUseCommitments && (
        <Route path="/material_commitments/:id">
          <MaterialCommitmentDetails />
        </Route>
      )}
      {newCommitmentTypesEnabled && (
        <Route exact path="/concrete_commitments/new">
          <NewConcreteCommitment />
        </Route>
      )}
      {newCommitmentTypesEnabled && (
        <Route path="/concrete_commitments/:id">
          <ConcreteCommitmentDetails />
        </Route>
      )}
      {userStore.cannotSendAndUpdateOrders && (
        <Route path="/new_order/*">
          <PrivateOrderForm />
        </Route>
      )}
      {userStore.canUseRfqLeveling && (
        <Route path="/order_package/:id">
          <CompareOrders />
        </Route>
      )}
      {userStore.canUseCreateRFQ && (
        <Route path="/orders/new_quote">
          {canUseNewOrderScreen ? <NewQuotePageV2 orderType="RFQ" /> : <NewQuote orderType="RFQ" />}
        </Route>
      )}
      {userStore.canUseMaterialsList && (
        <TrackRoute track={Events.VIEW_MATERIALS} path="/materials" component={OrdersMaterialPageV2} />
      )}
      <Route path="/orders/new_order">
        {canUseNewOrderScreen ? <NewQuotePageV2 orderType="Order" /> : <NewQuote orderType="Order" />}
      </Route>
      <TrackRoute track={Events.VIEW_ORDER_TABLE} path="/orders" component={OrdersV2} />
      <TrackRoute track={Events.VIEW_CALENDAR} path="/calendar" component={Calendar} />
      <Route path="/company_materials">
        <CompanyMaterial />
      </Route>
      {priceSheetFlagEnabled && (
        <Route path="/price_sheets/:id">
          <PriceSheetEdit />
        </Route>
      )}
      <Route path="/company_vendors">
        <CompanyVendor />
      </Route>
      <Route path="/company_settings">
        <CompanySettings />
      </Route>
      {userStore.canUseIntegrations && (
        <Route path="/integrations">
          <Integrations />
        </Route>
      )}
      <Route path="/user_settings">
        <ContractorUserSettings />
      </Route>
      <Route path="/billing/pricing_table">
        <SubscriptionPricingTable />
      </Route>
      <Route path="/project/:id">
        <ProjectSettings />
      </Route>
      {canUseInvoices && <TrackRoute track={Events.VIEW_INVOICES} path="/invoices" component={InvoicesPageV2} />}
      {canUseInvoices && (
        <Route path="/invoice/:id">{canUseNewInvoiceDetailScreen ? <InvoiceDetailV3 /> : <InvoiceDetail />}</Route>
      )}
      {canUseCommitments && (
        <TrackRoute track={Events.VIEW_COMMITMENTS} path="/commitments" component={CommitmentsPage} />
      )}
      {canUseInventory && (
        <Route path="/inventory">
          <InventoryPage />
        </Route>
      )}
      {canUseInventory && (
        <Route exact path="/warehouses/new">
          <NewWarehouse />
        </Route>
      )}
      {canUseInventory && (
        <Route exact path="/warehouses/:id">
          <WarehouseDetails />
        </Route>
      )}
      {canUseInventory && (
        <Route exact path="/warehouses/:warehouseId/requests/new">
          <RequestCreate />
        </Route>
      )}
      <Route path="/" exact>
        <Dashboard />
      </Route>
    </Switch>
  )
})

const Application = () => {
  return (
    <AllProviders>
      <Layout header={<MainMenu />}>
        <Routes />
      </Layout>
    </AllProviders>
  )
}

export default Application
