import axios from 'axios'

import { Address } from 'common/server/addresses'
import { Comment } from 'common/server/comment'

import { InventoryRequestState } from 'contractor/server/inventory/requests'

type Watcher = {
  id: string
  full_name: string
}

export enum CustomAttributeTypeEnum {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
}

export type CustomAttributeType = keyof typeof CustomAttributeTypeEnum

export interface ItemAttribute {
  id?: string
  name: string
  type?: string
  custom_attribute_type: CustomAttributeType
  rules: { required?: boolean; options?: string[] }
  created_at?: string
  updated_at?: string
}

export interface Warehouse {
  id?: string
  name: string
  project_id?: string
  address: Address
  project_name?: string
  location_attributes?: ItemAttribute[]
  item_attributes?: ItemAttribute[]
  created_at?: string
  updated_at?: string
  watchers?: Watcher[]
  internal_comments?: Comment[]
}

export function show(warehouse_id: string) {
  return axios.get<Warehouse>(`/warehouses/${warehouse_id}`)
}

export function index() {
  return axios.get<Warehouse[]>(`/warehouses`)
}

export function destroy(warehouse_id: string) {
  return axios.delete(`/warehouses/${warehouse_id}`)
}

export function newInternalComment(payload: { text: string; warehouse_id: string; company_user_ids: string[] }) {
  return axios.post<Comment>(`/warehouses/${payload.warehouse_id}/internal_comments`, payload)
}

export function updateMaterialWarehouse(payload: Warehouse) {
  return axios.patch<Warehouse>(`/warehouses/${payload['id']}`, payload)
}

export function createMaterialWarehouse(payload: Warehouse) {
  return axios.post<Warehouse>('/warehouses', payload)
}

export function inventory_request_states(warehouse_id: string) {
  return axios.get<InventoryRequestState[]>(`/warehouses/${warehouse_id}/states`)
}
