import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'

import { render, screen, fireEvent } from '@testing-library/react'

import { useLocalStorage } from 'common/hooks/use-local-storage'

import { SelectWarehouseFilter } from '../select_warehouse_filter'

jest.mock('react-router-dom', () => ({
  useHistory: jest.fn(),
  useLocation: jest.fn(),
}))

jest.mock('common/hooks/use-local-storage', () => ({
  useLocalStorage: jest.fn(),
}))

jest.mock('../../SelectWarehouse', () => ({
  SelectWarehouse: ({ onChange, value, ...props }) => (
    <select data-testid="select-warehouse" value={value || ''} onChange={(e) => onChange(e.target.value)} {...props}>
      <option value="1">Warehouse 1</option>
      <option value="2">Warehouse 2</option>
    </select>
  ),
}))

describe('Inventory > common > components > SelectWarehouseFilter', () => {
  const mockReplace = jest.fn()
  const mockSetLocalStorage = jest.fn()

  beforeEach(() => {
    jest.clearAllMocks()
    ;(useHistory as jest.Mock).mockReturnValue({
      replace: mockReplace,
    })
    ;(useLocation as jest.Mock).mockReturnValue({
      search: '',
    })
    ;(useLocalStorage as jest.Mock).mockImplementation(() => [null, mockSetLocalStorage])
  })

  it('should initialize with query param "warehouse_id"', () => {
    ;(useLocation as jest.Mock).mockReturnValue({
      search: '?warehouse_id=2',
    })

    const mockOnChange = jest.fn()

    render(<SelectWarehouseFilter onChange={mockOnChange} />)

    expect(mockOnChange).toBeCalledWith('2', { value: '2', label: '' })
  })

  it('should initialize with local storage value if no query param', () => {
    ;(useLocalStorage as jest.Mock).mockImplementation(() => ['2', mockSetLocalStorage])

    const mockOnChange = jest.fn()

    render(<SelectWarehouseFilter onChange={mockOnChange} />)

    expect(mockOnChange).toBeCalledWith('2', { value: '2', label: '' })
  })

  it('should update URL and local storage when the value changes', () => {
    render(<SelectWarehouseFilter />)

    const select = screen.getByTestId('select-warehouse')
    fireEvent.change(select, { target: { value: '1' } })

    expect(mockReplace).toHaveBeenCalledWith({
      search: 'warehouse_id=1',
    })
    expect(mockSetLocalStorage).toHaveBeenCalledWith('1')
  })

  it('should remove "warehouse_id" from URL and local storage when cleared', () => {
    ;(useLocalStorage as jest.Mock).mockImplementation(() => ['1', mockSetLocalStorage])

    render(<SelectWarehouseFilter />)

    const select = screen.getByTestId('select-warehouse')
    fireEvent.change(select, { target: { value: '' } })

    expect(mockReplace).toHaveBeenCalledWith({
      search: '',
    })
    expect(mockSetLocalStorage).toHaveBeenCalledWith('')
  })

  it('should update onChange when warehouse selection changes', () => {
    const mockOnChange = jest.fn()

    render(<SelectWarehouseFilter onChange={mockOnChange} />)

    const select = screen.getByTestId('select-warehouse')
    fireEvent.change(select, { target: { value: '2' } })

    expect(mockOnChange).toHaveBeenCalledWith('2', { value: '2', label: '' })
  })

  it('should not update URL or local storage when warehouseId remains the same', () => {
    ;(useLocation as jest.Mock).mockReturnValue({
      search: '?warehouse_id=1',
    })
    ;(useLocalStorage as jest.Mock).mockImplementation(() => ['1', mockSetLocalStorage])

    render(<SelectWarehouseFilter />)

    expect(mockReplace).not.toHaveBeenCalled()
  })
})
