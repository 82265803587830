import React, { useMemo } from 'react'

import moment from 'moment'

import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import { Popover, Spin, Typography } from 'antd'

import { Box, BoxProps } from 'common/components/boxes'
import { RelationshipStockStatus, IntegrationsSourceSystem } from 'common/server/server_types'

import { IntegrationIcons } from 'contractor/components/IntegrationIcons'

type AgaveSyncStatusDetailedProps = {
  integration: {
    last_sync_at: Nullable<string>
    status: RelationshipStockStatus
    source: IntegrationsSourceSystem
    errors: {
      fail_message: string
      fail_body: string
    }[]
    warning: string
  }
  integrationName: string
  showAction?: boolean
  onClick?: React.MouseEventHandler<HTMLDivElement>
  webUrl?: string
} & BoxProps

const style = { fontSize: 14, fontWeight: 400, marginLeft: 5, marginRight: 3 }

export const AgaveSyncStatusDetailed = ({
  integration,
  integrationName,
  showAction = false,
  webUrl,
  onClick,
  ...props
}: AgaveSyncStatusDetailedProps) => {
  const content = useMemo(() => {
    switch (integration?.status) {
      case RelationshipStockStatus.FAILED: {
        return (
          <Typography.Text type="danger" style={style}>
            {showAction ? (
              <>
                Unable to Sync:{' '}
                <Typography.Link style={style} onClick={onClick}>
                  RESOLVE
                </Typography.Link>
              </>
            ) : (
              <>Unable to Sync</>
            )}
          </Typography.Text>
        )
      }
      case RelationshipStockStatus.SYNCED: {
        return (
          <Typography.Text type="secondary" style={style}>
            Synced on {moment(integration?.last_sync_at).format('MM/DD/YYYY [at] hh:mm a')}{' '}
            {integration?.warning && (
              <Popover
                content={`Subbase and ${integrationName} operate with different rounding precision limits. As a result, minor value adjustments may be necessary to ensure successful synchronization.`}
                title="Warning"
              >
                <span style={{ color: 'rgb(255, 150, 50)', cursor: 'pointer' }}>{integration?.warning}</span>
              </Popover>
            )}
          </Typography.Text>
        )
      }
      case RelationshipStockStatus.SYNCING: {
        return (
          <Box display="flex" alignItems="center">
            <Typography.Text type="secondary" style={style}>
              Syncing with {integrationName}
            </Typography.Text>
            <Box style={{ marginLeft: 3 }}>
              <Spin indicator={<LoadingOutlined style={{ fontSize: 15 }} spin />} />
            </Box>
          </Box>
        )
      }
      case RelationshipStockStatus.NOT_SYNCED: {
        return (
          <Typography.Text type="danger" style={style}>
            {showAction ? (
              <>
                Not Synced:{' '}
                <Typography.Link style={style} onClick={onClick}>
                  RESOLVE
                </Typography.Link>
              </>
            ) : (
              <>Not Synced</>
            )}
          </Typography.Text>
        )
      }
    }
  }, [integration?.status, showAction, onClick])

  return (
    <Box display="flex" alignItems="center" {...props}>
      {webUrl ? (
        <a href={webUrl} target="_blank" rel="noreferrer">
          <IntegrationIcons width={32} height={32} type={integration?.source} integrationName={integrationName} />
        </a>
      ) : (
        <IntegrationIcons width={32} height={32} type={integration?.source} integrationName={integrationName} />
      )}
      {content}
    </Box>
  )
}
