import React, { useState } from 'react'

import { Alert, Button, message } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, BoxProps } from 'common/components/boxes'
import { InvoiceInboxFilesStates } from 'common/server/server_types'

import { ReportIssueHistory as ReportInvoiceInboxIssueHistory } from 'contractor/components/InvoiceInboxDetailsDrawer'
import { useStores } from 'contractor/hooks/use-stores'

type InReviewAlertProps = BoxProps

export const InReviewAlert = observer<InReviewAlertProps>((props) => {
  const { invoiceStore } = useStores()

  const [isIssueHistoryModal, togglepenIssueHistoryVisible] = useState(false)

  const inReviewAttachment = invoiceStore.invoice?.invoice_inbox_attachment?.state === InvoiceInboxFilesStates.IN_REVIEW

  if (!inReviewAttachment) return null

  const handleSendIssue = async (issueMessage: string) => {
    try {
      await invoiceStore.reportIssue(invoiceStore.invoice?.id, {
        issue_message: issueMessage,
        invoice_inbox_attachment_id: invoiceStore.invoice?.invoice_inbox_attachment?.id,
      })
      message.success('Thanks for your issue report! Our team will take care of it ASAP.')
    } catch (error) {
      message.error(error?.response?.data?.error || 'Error while sending the issue, try again later.')
    }
  }

  return (
    <Box pt={20} px={20} width="100%" {...props}>
      <Alert
        message="This invoice has an issue and an analyst from SubBase is reviewing it. The average resolution time is 1 hour. Please don't make any changes to the invoice until the review is done."
        type="warning"
        style={{ width: '100%' }}
        action={
          <Button size="small" onClick={() => togglepenIssueHistoryVisible(true)}>
            See Details
          </Button>
        }
      />

      <ReportInvoiceInboxIssueHistory
        invoiceInboxId={invoiceStore.invoice?.invoice_inbox_id}
        filters={{ invoice_inbox_attachment_id: invoiceStore.invoice?.invoice_inbox_attachment?.id }}
        open={isIssueHistoryModal}
        onClose={() => togglepenIssueHistoryVisible(false)}
        onSendIssue={(_, issueMessage) => handleSendIssue(issueMessage)}
      />
    </Box>
  )
})
