import { useState } from 'react'

import { useHistory } from 'react-router'
import { useLastLocation } from 'react-router-last-location'

export const useGoBack = () => {
  const history = useHistory()
  const lastLocation = useLastLocation()
  const [previousListLocation] = useState(lastLocation)

  const goBack = (fallbackPath: string) => {
    if (previousListLocation) {
      history.push(previousListLocation)
    } else {
      history.push(fallbackPath)
    }
  }

  return { goBack }
}
