import { BindAll } from 'lodash-decorators'

import {
  open_upload_commitment,
  open_upload_company_materials,
  open_upload_company_vendors,
  open_upload_concrete_commitment,
  open_upload_cost_codes,
  open_upload_phase_codes,
  open_upload_projects,
  open_upload_price_sheet,
  open_upload_inventory_stock_items,
} from 'contractor/server/flatfile'

@BindAll()
export default class FlatfileStore {
  async openUploadCompanyMaterials() {
    const { data } = await open_upload_company_materials()
    return data
  }

  async openUploadCostCodes() {
    const { data } = await open_upload_cost_codes()
    return data
  }

  async openUploadProjects() {
    const { data } = await open_upload_projects()
    return data
  }

  async openUploadCommitment() {
    const { data } = await open_upload_commitment()
    return data
  }

  async openUploadConcreteCommitment() {
    const { data } = await open_upload_concrete_commitment()
    return data
  }

  async openUploadPhaseCodes() {
    const { data } = await open_upload_phase_codes()
    return data
  }

  async openUploadCompanyVendors() {
    const { data } = await open_upload_company_vendors()
    return data
  }

  async openUploadPriceSheet(priceSheetId: string) {
    const { data } = await open_upload_price_sheet(priceSheetId)
    return data
  }

  async openUploadInventoryStockItems(warehouseId: string) {
    const { data } = await open_upload_inventory_stock_items(warehouseId)
    return data
  }
}
