import axios, { AxiosResponse } from 'axios'

import { Role } from 'common/server/server_types'

interface IndexUserResponse {
  users: ManagedUser[]
}

export interface IndexUserFilter {
  activated: boolean
  deactivated: boolean
  ungrouped: boolean
}

export interface ManagedUser {
  id: string
  company_user_id: string
  first_name: string
  last_name: string
  role: Role
  email: string
  confirmed_at: string
  last_sign_in_at: string
  invitation_sent_at: string
  invitation_accepted_at?: string
  invitation_link?: string
  invitation_expires_at?: string
  project_group_ids: string[]
  is_active: boolean
  deactivated_at?: string
  sign_in_count: number
  is_account_user: boolean
}

export interface UpdateRequest {
  user_id: string
  role_id?: string
  resend_invitation?: boolean
  project_group_ids?: string[]
  is_active?: boolean
}

interface InviteRequest {
  email: string
  role_id?: string
  project_group_ids?: string[]
  replacing_user_id?: string
}

export async function index(): Promise<AxiosResponse<IndexUserResponse>> {
  return await axios.get('/company_settings/manage_users')
}

export async function update_user(payload: UpdateRequest): Promise<
  AxiosResponse<{
    invitation_link: string
    invitation_expires_at: string
  }>
> {
  return await axios.patch('/company_settings/manage_users', payload)
}

export async function invite_user(payload: InviteRequest): Promise<AxiosResponse<void>> {
  return await axios.post('/users/invitation', { user: payload })
}
