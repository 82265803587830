import React, { useMemo } from 'react'

import { v4 as uuidV4 } from 'uuid'

import { Checkbox } from 'antd'
import { Card } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box, FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { SearchInput } from 'common/components/SearchInput'

import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'

import { AccountingDetails } from '../components/AccountingDetails'
import { CreateGroup } from '../components/CreateGroup'
import { DeleteInvoiceItems } from '../components/DeleteInvoiceMaterials'
import { DeliveryDetails } from '../components/DeliveryDetails'
import { ReconciliationOrderView } from '../components/ReconciliationOrderView'
import { TabReconcile } from '../components/TabReconcile'
import { useInvoice } from '../context'
import { extractQtyAndUnitsOfOrderMaterials } from '../helpers'
import { TableOptions } from '../ReviewStep/table_options'
import { OrderView } from './order_view'
import { ReconciliationView } from './reconciliation_view'

const getInitialVisibleColumns = ({
  taxLineItemsEnabled,
  canUseCostCode,
  companyAttributes,
  independentPhaseCodesEnabled,
  canUseRetainage,
}) => {
  const columns = [
    { id: 1, Header: 'Qty', dataIndex: 'quantity_shipped', isVisible: true },
    { id: 2, Header: 'UOM', dataIndex: 'uom', isVisible: true },
    { id: 3, Header: 'Unit Cost', dataIndex: 'unit_price', isVisible: true },
    ...(taxLineItemsEnabled
      ? [
          { id: 4, Header: 'Tax', dataIndex: 'tax_split', isVisible: true },
          { id: 5, Header: 'Ext. Cost + Tax', dataIndex: 'ext_cost_with_tax', isVisible: true },
        ]
      : []),
    { id: 6, Header: 'Ordered', dataIndex: 'ordered', isVisible: true },
    { id: 7, Header: 'Invoiced', dataIndex: 'invoiced', isVisible: true },
    { id: 8, Header: 'Remaining', dataIndex: 'remaining', isVisible: true },
  ]

  if (canUseCostCode && companyAttributes.includes('cost_code_id')) {
    columns.push({ id: 9, Header: 'Cost Code', dataIndex: 'cost_code', isVisible: true })

    if (independentPhaseCodesEnabled) {
      columns.push({ id: 10, Header: 'Phase Code', dataIndex: 'cost_code_phase', isVisible: true })
    }
  }

  if (canUseRetainage) {
    columns.push({ id: 11, Header: 'Retainage amount', dataIndex: 'retainage', isVisible: true })
  }

  return columns
}

export const ExtractedData = observer(() => {
  const { companySettingStore, invoiceStore, userStore } = useStores()

  const canUseRetainage = useFlag('retainage_v1')

  const {
    form,
    setSelectedInvoiceDirty,
    handleSearch,
    toggleAllInvoiceMaterialsChecked,
    allInvoiceMaterialsIsChecked,
    invoiceMaterialsFiltered,
    searchTerm,
    currentReconcileStatus,
    modeView,
    taxLineItemsEnabled,
  } = useInvoice()

  const isReconcileMode = ['reconciliation'].includes(modeView)
  const isReviewOrOrderMode = ['review', 'order_view'].includes(modeView)

  const showOrderItemsTab = currentReconcileStatus === 'all_order_items'

  const [columns, setColumns] = React.useState(() =>
    getInitialVisibleColumns({
      taxLineItemsEnabled,
      canUseCostCode: userStore.canUseCostCode,
      companyAttributes: companySettingStore.companyMaterialConfiguration?.company_attributes,
      independentPhaseCodesEnabled:
        companySettingStore.otherSettings?.cost_code_settings?.independent_phase_codes_enabled,
      canUseRetainage,
    }),
  )

  const visibleColumns = React.useMemo(
    () =>
      isReviewOrOrderMode
        ? columns.filter((column) => column.isVisible).map((column) => column.dataIndex)
        : ['description', 'quantity_shipped', 'uom', 'unit_price', 'extended_price'],
    [columns, isReviewOrOrderMode],
  )

  const invoice = invoiceStore.invoice

  const handleAddItem = () => {
    invoiceStore.updateSelectedInvoice('invoice_materials', [
      ...invoice?.invoice_materials,
      {
        id: `new-item-${uuidV4()}`,
        description: '',
        quantity_shipped: 0,
        uom: '',
        unit_price: 0,
        extended_price: 0,
        key: uuidV4(),
        invoice_id: invoice?.id,
        accepts_tax_split: true,
      },
    ])
    setSelectedInvoiceDirty(true)
  }

  React.useEffect(() => {
    form.setFieldsValue({
      taxAmount: invoice?.tax_amount,
      discountAmount: invoice?.discount_amount,
      shippingCost: invoice?.shipping_cost,
      otherCosts: invoice?.other_costs,
    })
  }, [invoice?.tax_amount, invoice?.discount_amount, invoice?.shipping_cost, invoice?.other_costs])

  const handleFilterInvoiceMaterialsTable = (invoiceMaterial) => {
    if (currentReconcileStatus === 'all') {
      return true
    }

    const extractedQuantity = Number(invoiceMaterial?.quantity_shipped || 0)
    const invoiceUnit = invoiceMaterial?.uom?.trim() || ''

    const [orderMaterialsQuantities, orderMaterialsUnits] = extractQtyAndUnitsOfOrderMaterials(
      invoiceMaterial?.order_materials,
    )

    const hasOrderMaterials = !!invoiceMaterial?.order_materials?.length

    if (currentReconcileStatus === 'matches') {
      return (
        hasOrderMaterials &&
        orderMaterialsQuantities?.length <= 1 &&
        orderMaterialsQuantities[0] === extractedQuantity &&
        orderMaterialsUnits?.length <= 1 &&
        invoiceUnit === orderMaterialsUnits[0]
      )
    }

    if (currentReconcileStatus === 'partial-matches') {
      return (
        hasOrderMaterials &&
        (orderMaterialsQuantities?.length > 1 ||
          orderMaterialsUnits?.length > 1 ||
          orderMaterialsQuantities[0] !== extractedQuantity ||
          invoiceUnit !== orderMaterialsUnits[0])
      )
    }

    return !invoiceMaterial?.order_materials?.length
  }

  const accountDetailsButtonIsDisabled = invoiceMaterialsFiltered.materialsFilteredBySearch.every(
    (material) => !material.checked,
  )
  const groupButtonIsDisabled = useMemo(() => {
    const hasSelectedGroups = invoiceMaterialsFiltered.groups.some((group) => group.checked)
    const hasNoSelectedMaterials = invoiceMaterialsFiltered.materials.every((material) => !material.checked)
    return hasSelectedGroups || hasNoSelectedMaterials
  }, [invoiceMaterialsFiltered])

  const viewRender = useMemo(() => {
    if (modeView === 'order_view') return <OrderView />

    if (showOrderItemsTab) return <ReconciliationOrderView />

    return (
      <ReconciliationView
        onAddItem={handleAddItem}
        onFilterInvoiceMaterialsTable={handleFilterInvoiceMaterialsTable}
        visibleColumns={visibleColumns}
      />
    )
  }, [showOrderItemsTab, handleAddItem, handleFilterInvoiceMaterialsTable, modeView])

  return (
    <FlexBoxY flex={1} maxWidth="100%" style={{ gap: 16 }} justifyContent="flex-start">
      <Box display="flex" flexDirection="column" width="100%">
        <TabReconcile />

        <Card
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          bodyStyle={{ padding: 10, flexGrow: 1, display: 'flex', alignItems: 'center' }}
        >
          {!showOrderItemsTab && (
            <Checkbox
              onChange={() => toggleAllInvoiceMaterialsChecked()}
              checked={allInvoiceMaterialsIsChecked}
              style={{ marginRight: 8 }}
            />
          )}

          <FlexBoxX style={{ gap: 8 }}>
            {isReviewOrOrderMode && <DeliveryDetails />}

            {isReconcileMode && !showOrderItemsTab && <CreateGroup />}

            {!showOrderItemsTab && (
              <AccountingDetails disabled={accountDetailsButtonIsDisabled && !groupButtonIsDisabled} />
            )}

            {isReviewOrOrderMode && !showOrderItemsTab && <TableOptions value={columns} onChange={setColumns} />}

            {isReconcileMode && !showOrderItemsTab && <DeleteInvoiceItems />}

            <SearchInput onSearch={handleSearch} onChange={(e) => handleSearch(e.target.value)} value={searchTerm} />
          </FlexBoxX>
        </Card>
      </Box>

      {viewRender}
    </FlexBoxY>
  )
})
