import React, { forwardRef, useEffect } from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Radio, Row, Select, Space, Switch, Typography } from 'antd'

import { Drawer, DrawerRef } from 'common/components/Drawer'
import { formatEnumValue } from 'common/helpers/formatters'

import { DropdownOptions } from 'contractor/pages/Inventory/Warehouses/common/components/WarehouseForm/CustomAtributes/CustomAttributeDrawer/dropdown_options'
import { useWarehouse } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { CustomAttributeTypeEnum, ItemAttribute } from 'contractor/server/warehouses'

type CustomAttributeDrawerProps = {
  onClose: () => void
  attribute?: ItemAttribute
}

const selectOptions = Object.keys(CustomAttributeTypeEnum).map((type: string) => ({
  label: formatEnumValue(type),
  value: type,
}))

export const CustomAttributeDrawer = forwardRef<DrawerRef, CustomAttributeDrawerProps>((props, ref) => {
  const { onClose, attribute } = props
  const { setWarehouse } = useWarehouse()
  const [form] = Form.useForm()
  const attributeType = Form.useWatch('custom_attribute_type', form)
  const isDropdown = attributeType === CustomAttributeTypeEnum.DROPDOWN

  useEffect(() => {
    if (attribute) {
      const { name, custom_attribute_type, rules = {}, type } = attribute
      form.setFieldsValue({
        type: type || 'location_attributes',
        name: name || '',
        custom_attribute_type: custom_attribute_type || '',
        required: rules.required,
        options: rules.options || [],
      })
    } else {
      form.resetFields()
    }
  }, [attribute, form])

  const handleSave = (values) => {
    const { type, name, custom_attribute_type, required, options } = values

    const newAttribute: ItemAttribute = {
      ...(attribute || {}),
      type,
      name,
      custom_attribute_type,
      rules: {
        required,
        ...(custom_attribute_type === CustomAttributeTypeEnum.DROPDOWN && {
          options: options,
        }),
      },
    }

    setWarehouse((prevWarehouse) => {
      const currentValues = prevWarehouse[type] || []
      const existingIndex = currentValues.findIndex((att) => att.name === name)
      const updatedValues =
        existingIndex !== -1
          ? currentValues.map((att, idx) => (idx === existingIndex ? newAttribute : att))
          : [...currentValues, newAttribute]
      return { ...prevWarehouse, [type]: updatedValues }
    })
  }

  const handleFinish = (values: {}) => {
    handleSave(values)
    form.resetFields()
  }

  const handleFinishAndClose = async () => {
    try {
      const values = await form.validateFields()
      handleSave(values)
      onClose()
    } catch (errorInfo) {
      console.error('Validation failed:', errorInfo)
    }
  }

  const handleBack = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Drawer
      ref={ref}
      title={attribute ? 'Edit attribute' : 'New attribute'}
      closable={false}
      onClose={onClose}
      extra={<CloseOutlined onClick={onClose} />}
      width={500}
      footer={
        <>
          <Button onClick={handleFinishAndClose}>Save &amp; close</Button>
          <Space>
            <Button onClick={handleBack}>Back</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Save &amp; continue
            </Button>
          </Space>
        </>
      }
      footerStyle={{ width: '100%', justifyContent: 'space-between', display: 'flex' }}
      bodyStyle={{ padding: 16, display: 'flex', flexDirection: 'column' }}
      destroyOnClose
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFinish}
        requiredMark={false}
        initialValues={{
          type: 'location_attributes',
          required: false,
          options: [],
        }}
        style={{ width: '100%' }}
      >
        <Typography.Title level={5} style={{ margin: 0 }}>
          General info
        </Typography.Title>

        <Form.Item name="type" label="Type of attribute" style={{ paddingTop: 16 }}>
          <Radio.Group optionType="button" buttonStyle="solid" disabled={!!attribute?.type} style={{ display: 'flex' }}>
            <Radio.Button value="location_attributes" style={{ flex: 1, textAlign: 'center' }}>
              Location
            </Radio.Button>
            <Radio.Button value="item_attributes" style={{ flex: 1, textAlign: 'center' }}>
              Item
            </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name="name"
              label="Attribute name"
              rules={[{ required: true, message: 'Please enter attribute name' }]}
            >
              <Input placeholder="Enter attribute name" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name="custom_attribute_type"
              label="Attribute type"
              rules={[
                {
                  required: true,
                  message: 'Please select an attribute type',
                },
              ]}
            >
              <Select placeholder="Select type" options={selectOptions} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item name="options" hidden>
              <Input placeholder="Enter attribute name" />
            </Form.Item>
          </Col>
        </Row>

        {isDropdown && <DropdownOptions form={form} />}

        <Form.Item name="required" valuePropName="checked">
          <Space>
            <Switch size="small" />
            <Typography.Text>Required</Typography.Text>
          </Space>
        </Form.Item>
      </Form>
    </Drawer>
  )
})
