import React from 'react'

import { render, screen } from '@testing-library/react'

import { QtyByStatus } from '../qty_by_status'

describe('Inventory > List > StockRow > QtyByStatus', () => {
  it('should render the title and children correctly', () => {
    render(
      <QtyByStatus title="Test Title">
        <div>Test Children</div>
      </QtyByStatus>,
    )

    const title = screen.getByText('Test Title')
    expect(title).toBeInTheDocument()
    expect(title).toHaveStyle({
      fontSize: '10px',
    })

    const children = screen.getByText('Test Children')
    expect(children).toBeInTheDocument()
  })

  it('should use the correct styles for the Box wrapper', () => {
    const { container } = render(
      <QtyByStatus title="Styled Test">
        <div>Styled Children</div>
      </QtyByStatus>,
    )

    const box = container.firstChild
    expect(box).toHaveStyle({
      display: 'flex',
      flexDirection: 'column',
    })
  })
})
