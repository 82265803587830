import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export const useGetWarehouses = () => {
  const { warehouseStore } = useStores()
  const { data = [], isLoading } = useQuery(warehouseStore.getIndex)

  return { warehouses: data, isLoading }
}
