import React from 'react'

import styled from '@emotion/styled'

import { Divider, Popover, Typography } from 'antd'

import { FlexBoxX, FlexBoxY } from 'common/components/boxes'
import { useMediaQuery } from 'common/hooks/use-media-query'

const WrapperAttributes = styled(FlexBoxX)`
  & > div:last-child .ant-divider {
    display: none;
  }
`

const ItemValue = ({ label = '', item, attribute }) => {
  if (!item || !item[attribute]) {
    return null
  }

  return (
    <FlexBoxX justifyContent="flex-start" flexGrow={0}>
      {label && (
        <Typography.Text type="secondary" style={{ marginRight: 4, fontSize: 12 }}>
          {label}:
        </Typography.Text>
      )}

      <Typography.Text style={{ fontSize: 12, color: '#000000' }}>{item[attribute]}</Typography.Text>

      <Divider type="vertical" />
    </FlexBoxX>
  )
}

const Image = (props) => (
  <img
    {...props}
    onClick={(e) => e.stopPropagation()}
    style={{ objectFit: 'contain', border: '1px solid #f0f0f0', borderRadius: 4, backgroundColor: '#FFFF', padding: 2 }}
  />
)

type StockItemProps = {
  item: Record<string, unknown>
  onSelect?: (id: string) => void
}

export const StockItem = ({ item, onSelect }: StockItemProps) => {
  const isMdScreen = useMediaQuery('md')

  const companyMaterial = item?.company_material
  const imageSrc = companyMaterial?.['image_url']

  const image = isMdScreen ? (
    <Image src={imageSrc} height={64} width={64} />
  ) : (
    <Popover content={<Image src={imageSrc} height={220} width={220} />} overlayStyle={{ zIndex: 9999 }}>
      <Image src={imageSrc} height={64} width={64} />
    </Popover>
  )

  return (
    <FlexBoxX
      data-cy={`search-stock-item-${item.id}`}
      py={6}
      justifyContent="space-between"
      onClick={() => onSelect?.(item['id'] as string)}
    >
      <FlexBoxY alignItems="flex-start" mr="12px">
        <Typography.Text style={{ color: '#000000' }}>{companyMaterial?.['description']}</Typography.Text>
        <WrapperAttributes justifyContent="flex-start" flexWrap="wrap">
          <ItemValue item={companyMaterial} attribute="manufacturer" />
          <ItemValue item={companyMaterial} attribute="manufacturer_name" />
          <ItemValue label="ID" item={companyMaterial} attribute="product_identifier" />
          <ItemValue label="SIZE" item={companyMaterial} attribute="size" />
          {item['unit'] && <ItemValue label="UNIT" item={item['unit']} attribute="unit_name_with_increment_label" />}
          <ItemValue label="MATERIAL" item={companyMaterial} attribute="material" />
        </WrapperAttributes>
      </FlexBoxY>

      {imageSrc && image}
    </FlexBoxX>
  )
}
