import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'
import { Warehouse } from 'contractor/server/warehouses'

type UseGetWarehouseProps = {
  onSuccess?: (data: Warehouse) => void
  id: string
}

export const useGetWarehouse = (props: UseGetWarehouseProps) => {
  const { id, onSuccess } = props
  const { warehouseStore } = useStores()

  const {
    isLoading,
    data = [],
    isError,
  } = useQuery(() => {
    return warehouseStore.loadWarehouse(id).then((res) => {
      onSuccess?.(res)
      return res
    })
  }, [id])

  return {
    data,
    isLoading,
    isError,
  }
}
