import React, { useState } from 'react'

import moment from 'moment'

import { ArrowLeftOutlined } from '@ant-design/icons'
import { message, Tooltip, Typography } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { UnsavedPopup } from 'common/components/Page/unsaved_popup'
import { Visibility } from 'common/components/Visibility'
import {
  InvoiceInboxStates,
  InvoiceInboxSubStates,
  InvoicesStates,
  RelationshipStockStatus,
} from 'common/server/server_types'

import { AgaveSyncStatusDetailed } from 'contractor/components/AgaveSyncStatusDetailed'
import { Sage100SyncStatusDetailded } from 'contractor/components/ManualSyncStatusDetailed/sage_100_sync_status_detailed'
import { useFlag } from 'contractor/hooks/use-flag'
import { useStores } from 'contractor/hooks/use-stores'
import { InboxState } from 'contractor/pages/@v2/Invoices/components/inbox_state'

import { useInvoice } from '../context'
import { IntegrationSync } from '../integration_sync'
import { HeaderProps } from './header'

type LeftActionsProps = Pick<HeaderProps, 'isSubmitting' | 'onChangeName' | 'onSaveInvoice' | 'onConfirmSync'>

export const LeftActions = observer<LeftActionsProps>((props) => {
  const { isSubmitting, onSaveInvoice, onConfirmSync, onChangeName } = props

  const { invoiceStore, integrationStore, userStore, invoiceStateStore } = useStores()
  const sage100CsvDownloadEnabled = useFlag('sage_100_csv_download')

  const { selectedInvoiceDirty, goBack, lockInvoice, inReviewAttachment } = useInvoice()

  const [integrationModalOpen, toggleIntegrationModal] = useState(false)

  const canShowIntegration =
    userStore.canUseIntegrations && integrationStore.connected && integrationStore.invoiceSyncEnabled

  const postedState = invoiceStateStore.invoiceStates?.find(({ state }) => state === InvoicesStates.POSTED)

  const isInvoicePosted = invoiceStore?.invoice?.state?.id === postedState?.id
  const isIntegrationFailedOrNotsynced = [RelationshipStockStatus.FAILED, RelationshipStockStatus.NOT_SYNCED].includes(
    invoiceStore.invoice?.integration?.status,
  )

  const showAction = isInvoicePosted && isIntegrationFailedOrNotsynced && lockInvoice.canPostInvoice

  const handleMarkAsSynced = async () => {
    try {
      await integrationStore.markAsSynced(invoiceStore.invoice?.id)
      toggleIntegrationModal(false)
      await invoiceStore.getInvoice(invoiceStore.invoice?.id)
      message.success('Invoice marked as synced successfully')
    } catch (error) {
      message.error(error.message || 'Failed to mark invoice as synced')
    }
  }

  return (
    <Box display="flex" alignItems="center">
      <Box mr={16}>
        {selectedInvoiceDirty ? <UnsavedPopup goBack={goBack} /> : <ArrowLeftOutlined onClick={() => goBack()} />}
      </Box>

      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Box display="inline-flex" alignItems="center" style={{ gap: 16 }}>
          <Tooltip title="Name" placement="bottom">
            <Typography.Title
              aria-label="invoice-name-title"
              style={{ margin: 0 }}
              level={5}
              editable={
                lockInvoice.canEditInvoice
                  ? {
                      onChange: onChangeName,
                      onCancel: () => onChangeName(invoiceStore.invoice?.name || ''),
                    }
                  : undefined
              }
            >
              {invoiceStore.invoice?.name || 'Invoice'}
            </Typography.Title>
          </Tooltip>
          {invoiceStore?.invoice?.discarded_at && (
            <Typography.Text type="danger">{`Deleted at ${moment(invoiceStore?.invoice?.discarded_at).format(
              'MM/DD/YYYY HH:mm',
            )}`}</Typography.Text>
          )}

          <Visibility.Hidden breakpoint="md">
            {sage100CsvDownloadEnabled ? (
              <Sage100SyncStatusDetailded
                isSynced={!!invoiceStore.invoice?.manual_exported_at}
                manual_exported_at={invoiceStore.invoice?.manual_exported_at}
              />
            ) : canShowIntegration ? (
              <>
                <AgaveSyncStatusDetailed
                  integration={invoiceStore.invoice?.integration}
                  integrationName={integrationStore.getIntegrationName(invoiceStore.invoice?.integration?.source)}
                  webUrl={invoiceStore.invoice?.integration?.web_url}
                  onClick={() => toggleIntegrationModal(true)}
                  showAction={showAction}
                />
                {showAction && (
                  <IntegrationSync
                    onClose={() => toggleIntegrationModal(false)}
                    open={integrationModalOpen}
                    onFinish={onConfirmSync}
                    onFinishWithoutSync={onSaveInvoice}
                    isSubmitting={isSubmitting}
                    markAsSynced={handleMarkAsSynced}
                  />
                )}
              </>
            ) : null}
          </Visibility.Hidden>

          {inReviewAttachment && (
            <InboxState
              state={InvoiceInboxStates.RECEIVED}
              sub_state={InvoiceInboxSubStates.IN_REVIEW}
              style={{ minWidth: 'auto' }}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
})
