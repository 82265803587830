import React from 'react'

import { Button } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { Page } from 'common/components/Page'
import { PersistentFiltersProvider } from 'common/contexts/persistent_filters'

import { useStores } from 'contractor/hooks/use-stores'

import { RequestHeader } from './Header'
import { RequestList } from './List'

export const Requests = observer(() => {
  const {
    inventoryRequestStore: { listStore },
  } = useStores()

  return (
    <PersistentFiltersProvider ignoreQueryParams={['tab']} listStore={listStore}>
      <Page.Header>
        <RequestHeader />
      </Page.Header>

      <Page.Content display="flex" flexDirection="column" gridGap={16}>
        <RequestList />

        {listStore.hasMore && (
          <Box m="0 auto">
            <Button
              type="primary"
              ghost
              loading={listStore.isFetching}
              onClick={listStore.fetchNextPage}
              disabled={false}
            >
              Load More
            </Button>
          </Box>
        )}
      </Page.Content>
    </PersistentFiltersProvider>
  )
})
