export const getFormUnit = (formValues) => {
  if (formValues?.unit?.value === formValues?.unit?.label) {
    return { unit_name: formValues?.unit?.label, unit_id: null }
  }

  if (formValues?.unit?.value) {
    return { unit_id: formValues?.unit?.value, unit_name: null }
  }

  return { unit_id: null, unit_name: null }
}
