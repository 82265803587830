import React from 'react'

import styled from '@emotion/styled'

import { Box } from 'common/components/boxes'
import { withErrorBoundary } from 'common/components/ErrorBoundary/error_boundary'
import AcceptInvitePage from 'common/pages/Landing/accept_invite'
import InviteExpired from 'common/pages/Landing/expired_invite'
import ForgotPasswordPage from 'common/pages/Landing/forgot_password'
import { RegisterMaterialRequesterPage } from 'common/pages/Landing/register_material_requester'
import ResendConfirmationPage from 'common/pages/Landing/resend_confirmation'
import ResetPasswordPage from 'common/pages/Landing/reset_password'
import { SignInPage } from 'common/pages/Landing/SignIn'
import {
  AcceptInvite,
  ForgotPassword,
  RegisterMaterialRequester,
  ResendConfirmation,
  ResetPassword,
  SignIn,
} from 'common/server/user'

/* Load axios to set headers configuration */
import 'common/data-access/axios'

export enum SignInTypes {
  SIGN_IN = 'SIGN_IN',
  REGISTER_MATERIAL_REQUESTER = 'REGISTER_MATERIAL_REQUESTER',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACCEPT_INVITATION = 'ACCEPT_INVITATION',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  RESEND_CONFIRMATION = 'RESEND_CONFIRMATION',
}

export type LandingMethods = {
  acceptInvite: AcceptInvite
  forgotPassword: ForgotPassword
  registerMaterialRequester?: RegisterMaterialRequester
  resendConfirmation: ResendConfirmation
  resetPassword: ResetPassword
  signIn: SignIn
}

export type LandingProps = {
  invitationToken?: string
  invitationExpired?: boolean
  email?: string
  resetPasswordToken?: string
  confirmationToken?: string
  redirectPath: string
  publicOrderFormUrlExtension?: string
} & LandingMethods

const Wrapper = styled(Box)`
  .ant-typography-secondary {
    font-size: 12px;
  }
`

const Landing: React.FC<LandingProps> = ({
  invitationToken,
  resetPasswordToken,
  confirmationToken,
  redirectPath,
  publicOrderFormUrlExtension,
  invitationExpired,

  acceptInvite,
  forgotPassword,
  registerMaterialRequester,
  resendConfirmation,
  resetPassword,
  signIn,
}) => {
  const defaultSignInType = (): SignInTypes => {
    if (resetPasswordToken) {
      return SignInTypes.RESET_PASSWORD
    } else if (invitationExpired) {
      return SignInTypes.INVITATION_EXPIRED
    } else if (invitationToken) {
      return SignInTypes.ACCEPT_INVITATION
    } else if (publicOrderFormUrlExtension) {
      return SignInTypes.REGISTER_MATERIAL_REQUESTER
    } else {
      return SignInTypes.SIGN_IN
    }
  }
  const [signInType, setSignInType] = React.useState(defaultSignInType())
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [resendConfirmationMessage, setResendConfirmationMessage] = React.useState('')

  return (
    <Wrapper display="flex" justifyContent="center" alignItems="center" width="100%" height="100%" overflow="auto">
      <Box width="320px" height="calc(100% - 60px)" mt={40} mb={20}>
        {signInType == SignInTypes.SIGN_IN && (
          <SignInPage
            setSignInType={setSignInType}
            confirmationToken={confirmationToken}
            signIn={signIn}
            redirectPath={redirectPath}
          />
        )}

        {signInType == SignInTypes.REGISTER_MATERIAL_REQUESTER && (
          <RegisterMaterialRequesterPage register={registerMaterialRequester} setSignInType={setSignInType} />
        )}
        {signInType == SignInTypes.FORGOT_PASSWORD && (
          <ForgotPasswordPage setSignInType={setSignInType} forgotPassword={forgotPassword} />
        )}
        {signInType == SignInTypes.RESET_PASSWORD && (
          <ResetPasswordPage setSignInType={setSignInType} token={resetPasswordToken} resetPassword={resetPassword} />
        )}
        {signInType == SignInTypes.ACCEPT_INVITATION && (
          <AcceptInvitePage setSignInType={setSignInType} token={invitationToken} acceptInvite={acceptInvite} />
        )}
        {signInType == SignInTypes.INVITATION_EXPIRED && <InviteExpired setSignInType={setSignInType} />}
        {signInType == SignInTypes.RESEND_CONFIRMATION && (
          <ResendConfirmationPage
            setSignInType={setSignInType}
            resendConfirmationMessage={resendConfirmationMessage}
            resendConfirmation={resendConfirmation}
          />
        )}
      </Box>
    </Wrapper>
  )
}

export default withErrorBoundary(Landing)
