import { waitFor } from '@testing-library/react'
import { renderHooksProvider } from 'testHelpers/providers'

import { InvoiceMaterialGroup } from 'common/server/invoice'

import { useInvoice } from '../../../context'
import { useInvoiceMaterialsState } from '../use_invoice_materials_state'

jest.mock('../../../context', () => ({
  useInvoice: jest.fn(),
}))

const mockedUseInvoice = useInvoice as jest.Mock

const mockInvoiceMaterial = {
  id: '1',
  description: 'Material',
  quantity_shipped: 10,
  unit_price: 100,
  uom: 'CYD',
}

const mockGroup = {
  id: '1',
  name: 'Group',
  invoice_materials: [mockInvoiceMaterial],
} as InvoiceMaterialGroup

const mockInvoiceMaterialsFiltered = {
  materials: [
    mockInvoiceMaterial,
    {
      id: '2',
      description: 'Another Material',
      quantity_shipped: 5,
      unit_price: 50,
      uom: 'CYD',
    },
  ],
}

describe('[DrawerEditGroup: useInvoiceMaterialsState]', () => {
  beforeEach(() => {
    mockedUseInvoice.mockReturnValue({
      invoiceMaterialsFiltered: mockInvoiceMaterialsFiltered,
      handleSaveInvoiceMaterialsGroup: jest.fn(),
    })
  })

  it('should initialize with default values when there is no group', () => {
    const { result } = renderHooksProvider(() => useInvoiceMaterialsState())

    expect(result.current.mode).toBe('view')
    expect(result.current.groupName).toBe('')
    expect(result.current.searchTerm).toBe('')
    expect(result.current.allChecked).toBe(false)
    expect(result.current.invoicesItems.persisted).toHaveLength(0)
    expect(result.current.invoicesItems.notPersisted).toHaveLength(0)
  })

  it('should initialize with group values when group is provided', () => {
    const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

    expect(result.current.groupName).toBe(mockGroup.name)
    expect(result.current.invoicesItems.persisted).toHaveLength(1)
    expect(result.current.invoicesItems.notPersisted).toHaveLength(1)
  })

  it('should update mode and reset checks when setMode is called', async () => {
    const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

    await waitFor(() => {
      result.current.setMode('add')
    })

    expect(result.current.mode).toBe('add')
    expect(result.current.allChecked).toBe(false)
  })

  it('should toggle check for specific item', async () => {
    const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

    await waitFor(() => {
      result.current.toggleCheck(mockInvoiceMaterial.id)
    })

    const item = result.current.invoicesItems.persisted.find((item) => item.id === mockInvoiceMaterial.id)
    expect(item?.checked).toBe(true)
  })

  it('should toggle all checks', async () => {
    const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

    await waitFor(() => {
      result.current.toggleAll(true)
    })

    expect(result.current.allChecked).toBe(true)
    expect(result.current.invoicesItems.persisted[0].checked).toBe(true)
    expect(result.current.invoicesItems.notPersisted[0].checked).toBe(true)
  })

  it('should filter items based on search term', async () => {
    const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

    await waitFor(() => {
      result.current.setSearchTerm('Another')
    })

    expect(result.current.invoicesItems.persisted).toHaveLength(0)
    expect(result.current.invoicesItems.notPersisted).toHaveLength(1)
    expect(result.current.invoicesItems.notPersisted[0].description).toContain('Another')
  })

  describe('handleSave', () => {
    const mockSaveGroup = jest.fn()

    beforeEach(() => {
      mockSaveGroup.mockClear()
      mockedUseInvoice.mockReturnValue({
        invoiceMaterialsFiltered: mockInvoiceMaterialsFiltered,
        handleSaveInvoiceMaterialsGroup: mockSaveGroup,
      })
    })

    it('should not persist changes if not in view mode', async () => {
      const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

      await waitFor(() => {
        result.current.setMode('add')
      })

      let shouldClose
      await waitFor(() => {
        shouldClose = result.current.handleSave()
      })
      expect(shouldClose).toBe(false)
      expect(result.current.mode).toBe('view')

      await waitFor(() => {
        result.current.setMode('ungroup')
      })
      await waitFor(() => {
        shouldClose = result.current.handleSave()
      })

      expect(shouldClose).toBe(false)
      expect(result.current.mode).toBe('view')

      expect(mockSaveGroup).not.toHaveBeenCalled()
    })

    it('should only update local state when in add mode', async () => {
      const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

      await waitFor(() => {
        result.current.setMode('add')
        result.current.toggleCheck('2')
      })

      let shouldClose
      await waitFor(() => {
        shouldClose = result.current.handleSave()
      })

      expect(shouldClose).toBe(false)
      expect(mockSaveGroup).not.toHaveBeenCalled()
      expect(result.current.mode).toBe('view')
    })

    it('should only update local state when in ungroup mode', async () => {
      const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

      await waitFor(() => {
        result.current.setMode('ungroup')
        result.current.toggleCheck(mockInvoiceMaterial.id)
      })

      let shouldClose
      await waitFor(() => {
        shouldClose = result.current.handleSave()
      })

      expect(shouldClose).toBe(false)
      expect(mockSaveGroup).not.toHaveBeenCalled()
      expect(result.current.mode).toBe('view')
    })

    it('should persist changes and return true when in view mode', async () => {
      const { result } = renderHooksProvider(() => useInvoiceMaterialsState(mockGroup))

      let shouldClose
      await waitFor(() => {
        shouldClose = result.current.handleSave()
      })

      expect(shouldClose).toBe(true)
      expect(mockSaveGroup).toHaveBeenCalledWith(
        mockGroup.name,
        expect.arrayContaining([
          expect.objectContaining({
            id: mockInvoiceMaterial.id,
          }),
        ]),
        mockGroup.id,
      )
      expect(result.current.mode).toBe('view')
    })
  })
})
