import React, { useEffect, useState } from 'react'

import { Select, Skeleton } from 'antd'
import { SelectProps } from 'antd/lib/select'

import { UUID_REGEXP } from 'common/helpers/formatters'
import { Unit } from 'common/server/units'

export type Option = {
  label: string
  selectedLabel?: string
  value: string
  original?: Unit
}

export type SelectUnitProps = { units?: Unit[]; allowUnitCreation?: boolean } & SelectProps<Option>

export const makeOption = (unit: Unit) => {
  return {
    value: unit?.id,
    label: unit?.unit_name_with_increment_label,
    selectedLabel: unit?.unit_name_label,
    original: unit,
  }
}

export const makeOptionFromObject = (object) => {
  if (object?.unit_id) {
    return makeOption(object?.unit)
  }

  if (object?.unit_name) {
    return {
      value: object?.unit_name,
      label: object?.unit_name,
      selectedLabel: object?.unit_name,
      original: null,
    }
  }
}

const getUnits = (units) => JSON.parse(JSON.stringify(units))

export const SelectUnit = React.forwardRef(
  ({ units: unitsProp = [], allowUnitCreation = true, ...props }: SelectUnitProps, ref) => {
    const [options, setOptions] = useState<Option[]>(getUnits(unitsProp).map(makeOption))

    const searchUnit = (unit, searchValue = '') => {
      return (
        unit?.synonyms?.some((synonym) => synonym.toLowerCase().includes(searchValue)) ||
        unit.name.toLowerCase().includes(searchValue)
      )
    }

    const handleSearch = (searchValue = '') => {
      if (searchValue.trim()) {
        const value = searchValue.trim().toLowerCase()
        const foundUnits = getUnits(unitsProp).filter((unit) => searchUnit(unit, value))

        if (foundUnits.length) {
          setOptions(foundUnits.map(makeOption))
        } else if (allowUnitCreation) {
          setOptions([
            ...foundUnits.map(makeOption),
            {
              value: searchValue,
              label: searchValue,
              selectedLabel: searchValue,
            },
          ])
        }
      } else {
        setOptions(getUnits(unitsProp).map(makeOption))
      }
    }

    const handleResetOption = () => setOptions(getUnits(unitsProp).map(makeOption))

    useEffect(() => {
      setOptions(getUnits(unitsProp).map(makeOption))
    }, [unitsProp.length])

    let selectedValue = props?.labelInValue ? props?.value : props?.value?.value

    // Check if the selected value is an uuid with regexp
    // If isn't, find the unit with the same name and return the id
    // This is for the case where the selected value is the NAME of the unit
    if (typeof selectedValue === 'string' && !UUID_REGEXP.test(selectedValue)) {
      const foundUnit = getUnits(unitsProp).find((unit) => unit.name === selectedValue)
      if (foundUnit) selectedValue = props?.labelInValue ? makeOption(foundUnit) : foundUnit?.id
    }

    if (getUnits(unitsProp).length === 0) {
      // This avoids the warning when the units are not fully loaded
      return <Skeleton.Input block active />
    }

    return (
      <Select<Option>
        ref={ref}
        data-cy="unit-select"
        style={{ width: '100%' }}
        dropdownStyle={{ minWidth: 240 }}
        showSearch
        filterOption={false}
        onSearch={handleSearch}
        onSelect={() => handleResetOption()}
        options={options.sort((a, b) => {
          const first = a?.original?.override_label || a?.original?.name
          const second = b?.original?.override_label || b?.original?.name
          if (first < second) {
            return -1
          }
          if (first > second) {
            return 1
          }
          return 0
        })}
        aria-autocomplete="none"
        {...props}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        value={selectedValue as any}
      />
    )
  },
)
