import React, { useEffect, useState } from 'react'

import styled from '@emotion/styled'

import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { Tabs, Dropdown, Button } from 'antd'
import { TabsProps } from 'antd/lib/tabs'

import { Box } from 'common/components/boxes'

import VendorDeliveryInfo from 'contractor/components/@v2/Deliveries/delivery_info'

import Tags, { TagProps } from '../../tags'
import { Icon } from './order_material_tabs_icon'

type OrderMaterialTabsProps = {
  onAdd?: () => void
  onDuplicate?: (targetKey: string) => void
  deliveriesCount?: number
  disabled?: boolean
  tagsProps: TagProps & { hidden?: boolean }
} & TabsProps

const TabsStyled = styled(Tabs)`
  width: 100%;
  height: 100%;

  /* TODO: enable when the bill of materials returns to fixed size
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    max-height: 750px;
  }
  */

  &#order-materials-tabs .ant-tabs-nav {
    margin: 0;
    background: ${({ theme }) => theme.colors.white};
  }
  &#order-materials-tabs .ant-tabs-content-holder {
    background: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.sizes[12]};
    border-top-right-radius: ${({ theme }) => theme.radii.sm};
    border-bottom-left-radius: ${({ theme }) => theme.radii.sm};
    border-bottom-right-radius: ${({ theme }) => theme.radii.sm};
  }
  &#order-materials-tabs .ant-tabs-tab {
    padding: ${({ theme }) => `${theme.sizes[12]} ${theme.sizes[16]}`};
    margin: 0 !important;
  }
  &#order-materials-tabs .ant-tabs-content,
  .ant-tabs-tabpane {
    height: 100%;
  }
  /* TODO: enable when the bill of materials returns to fixed size
  .ant-tabs-tabpane:not(#order-materials-tabs-panel-invoices) {
    max-height: calc(100% - 64px);
    @media (max-width: ${({ theme }) => theme.breakpoints[4]}) {
      max-height: calc(100% - 128px);
    }
  }
  */
`

export const OrderMaterialTabs = ({
  onAdd,
  onDuplicate,
  deliveriesCount,
  disabled,
  hideAdd,
  tagsProps,
  ...props
}: OrderMaterialTabsProps) => {
  const [activeKey, setActiveKey] = useState('0')

  const handleAdd = () => {
    onAdd?.()
    setActiveKey(String(deliveriesCount))
  }
  const handleDuplicate = (targetKey: string) => {
    onDuplicate?.(targetKey)
    setActiveKey(String(deliveriesCount))
  }

  useEffect(() => {
    if (Number(activeKey) > props.items.length - 2) {
      setActiveKey('0')
    }
  }, [props.items?.length])

  const items = [
    {
      label: 'New blank delivery',
      key: 'new-blank-delivery',
      onClick: handleAdd,
    },
    ...props?.items
      ?.filter((item) => item.key !== 'invoices')
      ?.map((item) => ({
        label: `Duplicate ${item['title']}`,
        key: item.key,
        onClick: () => handleDuplicate(item.key),
      })),
  ]

  const tabBarExtraContent = hideAdd ? undefined : (
    <Box display="flex" flexDirection="row">
      {!tagsProps?.hidden && (
        <Box minWidth="175px">
          <Tags {...tagsProps} />
        </Box>
      )}

      <Dropdown menu={{ items }} trigger={['click']} disabled={disabled}>
        <Box mx={8}>
          <Button type="text" size="middle" disabled={disabled} icon={<PlusOutlined />} />
        </Box>
      </Dropdown>
    </Box>
  )

  return (
    <TabsStyled
      id="order-materials-tabs"
      onEdit={handleAdd}
      onChange={setActiveKey}
      activeKey={activeKey}
      tabBarExtraContent={{ right: tabBarExtraContent }}
      {...props}
    />
  )
}

OrderMaterialTabs.TabPaneTitle = ({
  isPickUp = false,
  title,
  delivery = null,
  isInvoice = false,
  isRemovable = false,
  handleRemove = () => null,
}) => {
  return (
    <Box display="inline-flex" alignItems="center">
      <Icon isInvoice={isInvoice} isPickUp={isPickUp} />
      {title}
      {!!delivery && <VendorDeliveryInfo delivery={delivery} boxStyle={{ marginLeft: 20 }} />}
      {isRemovable && (
        <Box ml={8}>
          <Button
            type="text"
            size="small"
            icon={<CloseOutlined style={{ fontSize: 12, marginRight: 0 }} onClick={handleRemove} />}
          />
        </Box>
      )}
    </Box>
  )
}
