import { useState } from 'react'

import { Option as CompanyVendorSelectorOption } from '../pages/CompanyVendorContacts/MyVendors/Create/company_vendor_selector'
import { CompanyVendorContact } from '../server/company_vendor_contacts'
import { IndexCompanyVendor } from '../server/company_vendors'
import { Vendor } from '../server/vendors'

export interface VendorDomainValidation {
  contactDomain: string
  matchVendorByDomain: CompanyVendorSelectorOption | null
  setContactDomain: (domain: string) => void
  setMatchVendorByDomain: (vendor: CompanyVendorSelectorOption | null) => void
  validateDomain: (
    email: string,
    selectedCompanyVendor?: IndexCompanyVendor | CompanyVendorContact['company_vendor'],
    selectedVendor?: Vendor,
  ) => void
  checkDomainMatch: (email: string, vendorDomain: string) => void
}

export type VendorDomainValidationErrors = 'vendorDomain' | 'generic'

export const VendorDomainValidationMessages: Record<VendorDomainValidationErrors, string> = {
  vendorDomain:
    'The vendor domain must match the contact email domain. Please select a matching vendor or create a new one.',
  generic: 'Unable to create new vendor contact',
}

export const BYPASS_DOMAINS = ['gmail.com']

export const useVendorDomainValidation = (): VendorDomainValidation => {
  const [contactDomain, setContactDomain] = useState('')
  const [matchVendorByDomain, setMatchVendorByDomain] = useState<CompanyVendorSelectorOption | null>(null)

  const validateDomain = (
    email: string,
    selectedCompanyVendor?: IndexCompanyVendor | CompanyVendorContact['company_vendor'],
    selectedVendor?: Vendor,
  ) => {
    const domain = email.split('@').pop() || ''

    if (domain && !selectedCompanyVendor && !selectedVendor) {
      setContactDomain(domain)
    } else {
      setContactDomain('')
    }
  }

  const checkDomainMatch = (email: string, vendorDomain: string) => {
    const domain = email.split('@').pop()?.toLowerCase()

    if (BYPASS_DOMAINS.includes(domain)) return

    if (domain !== vendorDomain) {
      throw { error: 'vendorDomain' }
    }
  }

  return {
    contactDomain,
    matchVendorByDomain,
    setContactDomain,
    setMatchVendorByDomain,
    validateDomain,
    checkDomainMatch,
  }
}
