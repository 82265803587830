import React from 'react'

import { useParams } from 'react-router'

import { Form, Row, Col, Input } from 'antd'

import { Page } from 'common/components/Page'
import Tags from 'common/components/tags'
import { useGoBack } from 'common/hooks/use-go-back'
import { InventoryOperationTypes } from 'common/server/server_types'

import { SelectProject } from 'contractor/components/SelectProject'
import { SelectWarehouse } from 'contractor/pages/Inventory/common/components/SelectWarehouse'

import { withInventoryRequestProvider, useCreateInventoryRequest } from './context'
import { RequestCreateHeader } from './Header'
import { useCreateRequest } from './hooks'
import { Operations } from './Operations'

const RequestCreateComponent = () => {
  const { goBack } = useGoBack()
  const { form, isLoadingProjects, submitType } = useCreateInventoryRequest()

  const params = useParams<{ warehouseId: string }>()

  const { handleCreateRequest, isSubmitting } = useCreateRequest({
    onSuccess: () => {
      goBack('/inventory?tab=requests')
    },
  })

  return (
    <Form
      form={form}
      layout="vertical"
      scrollToFirstError
      onFinish={(formValues) => {
        handleCreateRequest({
          type: submitType,
          warehouse_id: formValues?.warehouse_id,
          project_id: formValues?.project_id,
          assigned_to_id: formValues?.assigned_to_id,
          number: formValues?.request_name,
          tags: formValues?.tags,
          watcher_ids: formValues?.watchers?.map((watcher) => watcher.id) || [],
          operations: formValues?.operations?.map((operation) => ({
            address_id: operation?.address_id,
            attention_to_id: operation?.attention_to_id,
            operation_type: operation?.operation_type,
            requested_date: operation?.requested_date,
            stock_requests: operation?.stock_requests?.map((stockRequest) => ({
              cost_code_id: stockRequest?.cost_code?.value,
              cost_code_phase_id: stockRequest?.cost_code_phase?.value,
              quantity: stockRequest?.quantity,
              stock_id: stockRequest?.stock_id,
            })),
          })),
        })
      }}
      initialValues={{
        warehouse_id: params.warehouseId,
        operations: [
          {
            operation_type: InventoryOperationTypes.DELIVERY,
            address_id: null,
            attention_to_id: null,
            requested_date: null,
            stock_requests: [],
          },
        ],
      }}
      style={{ width: '100%', height: '100%' }}
    >
      <Page>
        <Page.Header style={{ paddingBottom: 0 }}>
          <RequestCreateHeader isSubmitting={isSubmitting} />
        </Page.Header>
        <Page.Content>
          <Row gutter={16}>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="request_name"
                label="Request name"
                rules={[
                  {
                    required: true,
                    message: 'Please input the request name!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item name="warehouse_id" label="Warehouse">
                <SelectWarehouse disabled />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item
                name="project_id"
                label="Project"
                rules={[
                  {
                    required: true,
                    message: 'Please select the project!',
                  },
                ]}
              >
                <SelectProject placeholder="" loading={isLoadingProjects} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
              <Form.Item name="tags" label="Tags">
                <Tags tags={[]} />
              </Form.Item>
            </Col>
          </Row>

          <Operations />
        </Page.Content>
      </Page>
    </Form>
  )
}

export const RequestCreate = withInventoryRequestProvider(RequestCreateComponent)
