import React, { useState, useEffect } from 'react'

import styled from '@emotion/styled'

import { PlusOutlined } from '@ant-design/icons'
import { Tabs, Dropdown, Button } from 'antd'
import { TabsProps } from 'antd/lib/tabs'

type OperationTabsProps = {
  onAdd?: () => void
  onDuplicate?: (targetKey: string) => void
  operationsCount: number
} & TabsProps

const TabsStyled = styled(Tabs)`
  width: 100%;

  &#operation-tabs .ant-tabs-nav {
    margin: 0;
    border-top-left-radius: ${({ theme }) => theme.radii.md};
    border-top-right-radius: ${({ theme }) => theme.radii.md};
    background: ${({ theme }) => theme.colors.white};
  }
  &#operation-tabs .ant-tabs-tab {
    padding: ${({ theme }) => theme.sizes[12]};
  }
  &#operation-tabs #operation-information {
    padding: ${({ theme }) => theme.sizes[12]};
    background: ${({ theme }) => theme.colors.white};
    border-bottom-left-radius: ${({ theme }) => theme.radii.md};
    border-bottom-right-radius: ${({ theme }) => theme.radii.md};
  }
`

export const OperationTabs = ({ onAdd, onDuplicate, operationsCount, ...props }: OperationTabsProps) => {
  const [activeKey, setActiveKey] = useState('0')

  const handleAdd = () => {
    onAdd?.()
    setActiveKey(String(operationsCount))
  }
  const handleDuplicate = (targetKey: string) => {
    onDuplicate?.(targetKey)
    setActiveKey(String(operationsCount))
  }

  // When the user delete a current tab, the activeKey will be the first tab
  useEffect(() => {
    if (Number(activeKey) > operationsCount - 1) {
      setActiveKey('0')
    }
  }, [operationsCount, activeKey])

  const items = [
    {
      label: 'New blank delivery',
      key: 'new-blank-delivery',
      onClick: handleAdd,
    },
    ...props?.items?.map((item) => ({
      label: `Duplicate ${item['title']}`,
      key: `dropdown-duplicate-${item.key}`,
      onClick: () => handleDuplicate(item.key),
    })),
  ]

  return (
    <TabsStyled
      id="operation-tabs"
      onEdit={handleAdd}
      onChange={setActiveKey}
      activeKey={activeKey}
      tabBarExtraContent={{
        right: (
          <Dropdown menu={{ items }} trigger={['click']}>
            <Button type="text" size="middle" icon={<PlusOutlined />} style={{ marginRight: 12 }} />
          </Dropdown>
        ),
      }}
      {...props}
    />
  )
}
