import React from 'react'

import { Tag, TagProps } from 'antd'

import { useTheme } from 'common/hooks/use-theme'

import { InventoryRequestState as IInventoryRequestState } from 'contractor/server/inventory/requests'

const defaultStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  width: '100%',
}

export type InventoryRequestStateProps = {
  state: IInventoryRequestState
} & TagProps

export const InventoryRequestState = ({ state, style, ...props }: InventoryRequestStateProps) => {
  const theme = useTheme()

  const colorName = state?.color || 'gray'

  return (
    <Tag
      style={{
        ...defaultStyle,
        backgroundColor: theme.colors[`${colorName}-1`],
        borderColor: theme.colors[`${colorName}-3`],
        color: theme.colors[`${colorName}-6`],
        ...style,
      }}
      onClick={(e) => e.stopPropagation()}
      {...props}
    >
      {state?.label}
    </Tag>
  )
}
