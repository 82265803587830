import axios from 'axios'

import { InventoryOperationTypes } from 'common/server/server_types'

import { InventoryRequest } from './common_interfaces'

export interface CreateInventoryRequestRequest {
  type: 'DRAFT' | 'REQUESTED'
  warehouse_id: string
  project_id: string
  assigned_to_id: string
  number: string
  tags?: string[]
  watcher_ids?: string[]
  operations: {
    operation_type: InventoryOperationTypes
    requested_date?: string
    address_id?: string
    attention_to_id?: string
    stock_requests: {
      quantity: string
      stock_id: string
      cost_code_id?: string
      cost_phase_id?: string
    }[]
  }[]
}

export function create(request: CreateInventoryRequestRequest) {
  return axios.post<InventoryRequest>(`/warehouses/${request.warehouse_id}/requests`, { request })
}
