import React from 'react'

import { render, screen, fireEvent, waitFor } from '@testing-library/react'

import { SelectWarehouse } from '../'
import { useGetWarehouses } from '../hooks'

jest.mock('../hooks', () => ({
  useGetWarehouses: jest.fn(),
}))

const mockUseGetWarehouses = useGetWarehouses as jest.MockedFunction<typeof useGetWarehouses>

describe('Inventory > common > components > SelectWarehouse', () => {
  it('should render the loading state when data is loading', () => {
    mockUseGetWarehouses.mockReturnValue({
      isLoading: true,
      warehouses: [],
    })

    render(<SelectWarehouse />)
    expect(screen.getByLabelText('loading')).toBeInTheDocument()
  })

  it('should render options when data is loaded', async () => {
    mockUseGetWarehouses.mockReturnValue({
      isLoading: false,
      warehouses: [
        { id: '1', name: 'Warehouse A', searchable: 'Warehouse A' },
        { id: '2', name: 'Warehouse B', searchable: 'Warehouse B' },
      ],
    })

    render(<SelectWarehouse />)

    fireEvent.mouseDown(screen.getByRole('combobox'))

    await waitFor(() => {
      expect(screen.getByText('Warehouse A')).toBeInTheDocument()
      expect(screen.getByText('Warehouse B')).toBeInTheDocument()
    })
  })

  it('should filter options based on user input', async () => {
    mockUseGetWarehouses.mockReturnValue({
      isLoading: false,
      warehouses: [
        { id: '1', name: 'Warehouse A', searchable: 'Warehouse A' },
        { id: '2', name: 'Warehouse B', searchable: 'Warehouse B' },
      ],
    })

    render(<SelectWarehouse />)

    fireEvent.mouseDown(screen.getByRole('combobox'))

    fireEvent.change(screen.getByRole('combobox'), { target: { value: 'B' } })

    // Wait for filtering
    await waitFor(() => {
      expect(screen.getByText('Warehouse B')).toBeInTheDocument()
      expect(screen.queryByText('Warehouse A')).not.toBeInTheDocument()
    })
  })
})
