import React from 'react'

import { CloseOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'

import { observer } from 'mobx-react-lite'

import { Drawer, DrawerBody, DrawerFooter, DrawerProps } from 'common/components/Drawer'
import { getFormUnit } from 'common/components/SelectUnit'

import { useStores } from 'contractor/hooks/use-stores'

import { FormContent } from './form_content'
import { useCreateStock, useGetUnits } from './hooks'

type StockCreateProps = Partial<DrawerProps>

export const StockCreate = observer(({ ...props }: StockCreateProps) => {
  const { inventoryStockStore } = useStores()
  const { units } = useGetUnits()

  const [form] = Form.useForm()

  const { handleCreateStock, isSubmitting } = useCreateStock({
    onSuccess: () => {
      inventoryStockStore.listStore.fetchRecords()
      form.resetFields()
      props.onClose(null)
    },
  })

  return (
    <Drawer
      title="New item"
      extra={<Button size="small" type="text" onClick={props.onClose} icon={<CloseOutlined />} shape="circle" />}
      closable={false}
      destroyOnClose
      width={635}
      {...props}
    >
      <Form
        form={form}
        layout="vertical"
        scrollToFirstError
        onFinish={(formValues) =>
          handleCreateStock({
            warehouse_id: formValues?.warehouse_id,
            company_material_id: formValues?.company_material_id,
            unit_id: getFormUnit(formValues).unit_id,
            expiration_date: formValues?.expiration_date,
            manufactured_date: formValues?.manufactured_date,
            date_in: formValues?.date_in,
            qty: formValues?.qty,
            send_to_quality_hold: formValues?.send_to_quality_hold,
            location_values: formValues?.location_values,
            item_values: formValues?.item_values,
          })
        }
        style={{ display: 'flex', flexDirection: 'column', width: '100%', flexGrow: 1 }}
      >
        <DrawerBody>
          <FormContent form={form} units={units} />
        </DrawerBody>

        <DrawerFooter gridGap={16} justifyContent="flex-end">
          <Button onClick={props.onClose} disabled={isSubmitting} style={{ width: 100 }}>
            Back
          </Button>
          <Button type="primary" loading={isSubmitting} htmlType="submit" style={{ width: 100 }}>
            Save
          </Button>
        </DrawerFooter>
      </Form>
    </Drawer>
  )
})
