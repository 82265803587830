import React from 'react'

import { InvoiceMaterialGroup } from 'common/server/invoice'

import { useInvoice } from '../../context'
import { DrawerEditGroupActions, DrawerEditGroupFiltered, DrawerEditGroupModeType, DrawerEditGroupState } from './types'

function reducer(state: DrawerEditGroupState, action: DrawerEditGroupActions): DrawerEditGroupState {
  switch (action.type) {
    case 'SET_MODE':
      return {
        ...state,
        mode: action.payload,
      }
    case 'SET_GROUP_NAME':
      return {
        ...state,
        groupName: action.payload,
      }
    case 'SET_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      }
    case 'TOGGLE_CHECK':
      return {
        ...state,
        invoiceMaterials: state.invoiceMaterials.map((item) =>
          item.id === action.payload ? { ...item, checked: !item.checked } : item,
        ),
      }
    case 'TOGGLE_ALL_CHECKS':
      return {
        ...state,
        invoiceMaterials: state.invoiceMaterials.map((item) => ({
          ...item,
          checked: action.payload,
        })),
      }
    case 'APPLY_GROUP_CHANGES':
      return {
        ...state,
        mode: 'view',
        invoiceMaterials: state.invoiceMaterials.map((item) => ({
          ...item,
          persisted: item.persisted || item.checked,
          checked: false,
        })),
      }
    case 'APPLY_UNGROUP_CHANGES':
      return {
        ...state,
        mode: 'view',
        invoiceMaterials: state.invoiceMaterials.map((item) => ({
          ...item,
          persisted: item.persisted && !item.checked,
          checked: false,
        })),
      }
    case 'RESET_CHECKS':
      return {
        ...state,
        invoiceMaterials: state.invoiceMaterials.map((item) => ({
          ...item,
          checked: false,
        })),
      }
    default:
      return state
  }
}

function getInitialMaterials(group?: InvoiceMaterialGroup, invoiceMaterialsFiltered?: DrawerEditGroupFiltered) {
  if (!group || !invoiceMaterialsFiltered) return []

  const originalMaterials = group.invoice_materials.map((item) => ({
    ...item,
    checked: false,
    persisted: true,
  }))

  const otherMaterials = invoiceMaterialsFiltered.materials
    .filter((item) => !originalMaterials.find((invItem) => invItem.id === item.id))
    .map((item) => ({
      ...item,
      checked: false,
      persisted: false,
    }))

  return originalMaterials.concat(otherMaterials)
}

const getInitialState = (
  group?: InvoiceMaterialGroup,
  invoiceMaterialsFiltered?: DrawerEditGroupFiltered,
): DrawerEditGroupState => ({
  mode: 'view',
  groupName: group?.name || '',
  searchTerm: '',
  invoiceMaterials: getInitialMaterials(group, invoiceMaterialsFiltered),
})

export function useInvoiceMaterialsState(group?: InvoiceMaterialGroup) {
  const { invoiceMaterialsFiltered, handleSaveInvoiceMaterialsGroup } = useInvoice()
  const [state, dispatch] = React.useReducer(reducer, getInitialState(group, invoiceMaterialsFiltered))

  const setMode = React.useCallback((mode: DrawerEditGroupModeType) => {
    dispatch({ type: 'SET_MODE', payload: mode })
    dispatch({ type: 'RESET_CHECKS' })
  }, [])

  const setGroupName = React.useCallback((name: string) => {
    dispatch({ type: 'SET_GROUP_NAME', payload: name })
  }, [])

  const setSearchTerm = React.useCallback((term: string) => {
    dispatch({ type: 'SET_SEARCH_TERM', payload: term })
  }, [])

  const toggleCheck = React.useCallback((id: string) => {
    dispatch({ type: 'TOGGLE_CHECK', payload: id })
  }, [])

  const toggleAll = React.useCallback((checked: boolean) => {
    dispatch({ type: 'TOGGLE_ALL_CHECKS', payload: checked })
  }, [])

  const handleSave = React.useCallback(() => {
    switch (state.mode) {
      case 'add':
        dispatch({ type: 'APPLY_GROUP_CHANGES' })
        return false
      case 'ungroup':
        dispatch({ type: 'APPLY_UNGROUP_CHANGES' })
        return false
      case 'view':
        handleSaveInvoiceMaterialsGroup(
          state.groupName,
          state.invoiceMaterials.filter((item) => item.persisted),
          group?.id,
        )
        return true
      default:
        return false
    }
  }, [state.mode, state.groupName, state.invoiceMaterials, group?.id, handleSaveInvoiceMaterialsGroup])

  const invoicesItems = React.useMemo(
    () => ({
      persisted: state.invoiceMaterials.filter(
        (item) => item.persisted && item.description.toLowerCase().includes(state.searchTerm.toLowerCase()),
      ),
      notPersisted: state.invoiceMaterials.filter(
        (item) => !item.persisted && item.description.toLowerCase().includes(state.searchTerm.toLowerCase()),
      ),
    }),
    [state.invoiceMaterials, state.searchTerm],
  )

  const allChecked = React.useMemo(
    () => state.invoiceMaterials.length > 0 && state.invoiceMaterials.every((item) => item.checked),
    [state.invoiceMaterials],
  )

  return {
    mode: state.mode,
    groupName: state.groupName,
    searchTerm: state.searchTerm,
    invoicesItems,
    allChecked,
    setMode,
    setGroupName,
    setSearchTerm,
    toggleCheck,
    toggleAll,
    handleSave,
  }
}
