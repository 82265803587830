import React from 'react'

import { Box } from 'common/components/boxes'
import { Drawer } from 'common/components/Drawer'
import { InvoiceMaterialGroup } from 'common/server/invoice'

import { useInvoice } from '../../context'
import { Footer } from './footer'
import { GroupList } from './group_list'
import { SearchBar } from './search_bar'
import { useInvoiceMaterialsState } from './use_invoice_materials_state'

interface DrawerEditGroupProps {
  isOpened: boolean
  setIsOpened: (opened: boolean) => void
  group?: InvoiceMaterialGroup | null
}

interface DrawerEditGroupContentProps {
  group?: InvoiceMaterialGroup
  onClose: () => void
}

const DrawerEditGroupContent = ({ group, onClose }: DrawerEditGroupContentProps) => {
  const { calcExtCost } = useInvoice()

  const {
    mode,
    groupName,
    searchTerm,
    invoicesItems,
    allChecked,
    setMode,
    setGroupName,
    setSearchTerm,
    toggleCheck,
    toggleAll,
    handleSave,
  } = useInvoiceMaterialsState(group)

  return (
    <Box width="100%" height="100%" overflow="auto">
      <SearchBar
        mode={mode}
        groupName={groupName}
        searchTerm={searchTerm}
        allChecked={allChecked}
        onSetGroupName={setGroupName}
        onSetSearchTerm={setSearchTerm}
        onToggleAll={() => toggleAll(!allChecked)}
        onSetMode={setMode}
      />

      <GroupList mode={mode} invoicesItems={invoicesItems} calcExtCost={calcExtCost} onCheckItem={toggleCheck} />

      <Footer mode={mode} onSave={handleSave} onClose={onClose} />
    </Box>
  )
}

export const DrawerEditGroup = ({ isOpened, setIsOpened, group = null }: DrawerEditGroupProps) => {
  return (
    <Drawer
      onClose={() => setIsOpened(false)}
      open={isOpened}
      width={700}
      title="Group editing"
      forceRender
      closable={false}
    >
      <Box width="100%" overflow="auto">
        {isOpened && <DrawerEditGroupContent onClose={() => setIsOpened(false)} group={group} />}
      </Box>
    </Drawer>
  )
}

export default DrawerEditGroup
