import axios from 'axios'

import { StandardIndexRequest, StandardIndexResponse } from 'common/server/server_types'

import { InventoryRequest } from './common_interfaces'

export function index(request: StandardIndexRequest) {
  const { filters, ...restRequest } = request
  const url = `/warehouses/${filters.warehouse_id}/requests`

  return axios.get<StandardIndexResponse<InventoryRequest[]>>(url, {
    params: {
      ...filters,
      ...restRequest,
    },
  })
}
