import { BindAll } from 'lodash-decorators'

import { Address } from 'common/server/addresses'

import { create as createAddress } from 'contractor/server/addresses'
import {
  createMaterialWarehouse,
  destroy,
  index,
  show,
  updateMaterialWarehouse,
  inventory_request_states,
} from 'contractor/server/warehouses'
import { Warehouse } from 'contractor/server/warehouses/warehouse'

@BindAll()
export default class WarehouseStore {
  async loadWarehouse(id: string) {
    return (await show(id)).data
  }

  async addAddress(warehouse_id: string, address: Address): Promise<void> {
    await createAddress({ warehouse_id, address })
  }

  async updateWarehouse(params: Warehouse) {
    return (await updateMaterialWarehouse(params)).data
  }

  async deleteWarehouse(id: string) {
    return (await destroy(id)).data
  }

  async createWarehouse(params: Warehouse) {
    return (await createMaterialWarehouse(params)).data
  }

  async getIndex() {
    return (await index()).data
  }

  async getInventoryRequestStates(warehouseId: string) {
    return (await inventory_request_states(warehouseId)).data
  }
}
