import { useQuery } from 'common/hooks/use-query'

import { useStores } from 'contractor/hooks/use-stores'

export const useGetWarehouses = () => {
  const { warehouseStore } = useStores()

  const {
    isLoading,
    data = [],
    isError,
    refetch,
  } = useQuery(() => {
    return warehouseStore.getIndex()
  })

  return {
    data,
    isLoading,
    isError,
    refetch,
  }
}
