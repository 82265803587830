import React from 'react'

import styled from '@emotion/styled'

import { Button, Card, Collapse, Divider, Modal, Typography } from 'antd'

import { Box, FlexBoxX } from 'common/components/boxes'
import theme from 'common/styles/theme'

import { useDeleteInvoiceMaterials } from '../components/DeleteInvoiceMaterials/hooks/use-delete-invoice-materials'
import { InvoiceItem } from '../components/InvoiceItem'
import { InvoiceItemOrder } from '../components/InvoiceItem/invoice_item_order'
import { useInvoice } from '../context'
import { getRemainingPriceColor } from '../helpers'

const StyledPanel = styled(Collapse.Panel)`
  .ant-collapse-header {
    border: 1px solid #f0f0f0;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }
`

export const OrderView = () => {
  const { handleDelete, handleConfirmDelete, handleCancel, opened } = useDeleteInvoiceMaterials()

  const {
    calcExtCost,
    currencyFormatter,
    selectedOrders,
    invoice,
    allInvoicesExceptTheCurrent,
    calculatedGrandTotal,
    calculatedEligibleTaxSplitTotal,
    taxAmountField,
    shippingCostField,
    otherCostsField,
    discountAmountField,
    handleUnmatch,
    handleSelectInvoiceMaterials,
  } = useInvoice()

  const allInvoices = [
    {
      ...invoice,
      invoice_grand_total: calculatedGrandTotal,
      eligible_tax_split_total: calculatedEligibleTaxSplitTotal,
      tax_amount: taxAmountField,
      shipping_cost: shippingCostField,
      other_costs: otherCostsField,
      discount_amount: discountAmountField,
    },
    ...allInvoicesExceptTheCurrent,
  ]

  const getInvoiceMaterials = React.useCallback(
    (invoice_materials) => {
      const mapCurrentInvoiceMaterial = (invoiceMaterial) => {
        const invoice = allInvoices.find((inv) => inv.id === invoiceMaterial.invoice_id)

        const taxAmount = invoice?.tax_amount || 0
        const eligibleTaxSplitTotal = invoice?.eligible_tax_split_total || 0

        const common = {
          ...invoiceMaterial,
          tax_amount: taxAmount,
          eligible_tax_split_total: eligibleTaxSplitTotal,
        }

        if (!invoiceMaterial.accepts_tax_split) {
          return common
        }

        const extCost = calcExtCost({
          unitCost: invoiceMaterial?.unit_price,
          quantity: invoiceMaterial?.quantity_shipped,
          multiplier: invoiceMaterial?.unit?.multiplier,
          qtyIncrement: invoiceMaterial?.unit?.qty_increment,
        })

        const extCostWithTax = extCost + invoiceMaterial.tax_split

        return { ...common, ext_cost_with_tax: extCostWithTax }
      }

      return invoice_materials.map(mapCurrentInvoiceMaterial)
    },
    [allInvoices, calcExtCost],
  )

  return (
    <>
      <Box display="flex" flex={1} flexDirection="column" width="100%" style={{ gap: 8 }} overflowY="auto">
        {selectedOrders?.map(({ order }) => (
          <Card key={order.id} style={{ width: '100%' }} bodyStyle={{ padding: 0, borderRadius: 0 }}>
            <Box display="flex" justifyContent="space-between" m={12} style={{ gap: 8 }}>
              <Typography.Text strong> {`Order (${order.order_number})`}</Typography.Text>

              <Box display="flex" style={{ gap: 8 }}>
                <Typography.Text>PO Total</Typography.Text>
                <Typography.Text strong>
                  {order.grand_total ? currencyFormatter(Number(order.grand_total)) : 'Unknown'}
                </Typography.Text>
              </Box>
            </Box>

            <Divider style={{ margin: 0 }} />

            {order.invoices.map((invoice) => {
              const remaining = Number(order?.grand_total) - Number(invoice.total_amount)

              return (
                <Collapse key={invoice.id} ghost expandIconPosition="right" style={{ margin: 12 }}>
                  <StyledPanel
                    header={
                      <Box display="flex" alignItems="center" justifyContent="space-between" style={{ gap: 8 }}>
                        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                          <Typography.Text strong>Invoice {invoice.number}</Typography.Text>
                          <Button
                            size="small"
                            style={{ borderRadius: 20 }}
                            onClick={() => window.open(`/invoice/${invoice.id}`, '_blank')}
                          >
                            Go to invoice
                          </Button>
                        </Box>

                        <Box display="flex" alignItems="center" style={{ gap: 8 }}>
                          <Typography.Text>Total Invoiced</Typography.Text>
                          <Typography.Text strong>
                            {invoice.invoice_grand_total
                              ? currencyFormatter(Number(invoice.invoice_grand_total))
                              : 'Unknown'}
                          </Typography.Text>

                          <Divider
                            type="vertical"
                            style={{ height: 24, borderColor: theme.colors['gray-6'], margin: 0 }}
                          />

                          <Typography.Text>Remaining</Typography.Text>
                          <Typography.Text strong style={{ margin: 0, color: getRemainingPriceColor(remaining) }}>
                            {currencyFormatter(remaining)}
                          </Typography.Text>
                        </Box>
                      </Box>
                    }
                    key="1"
                  >
                    <Box display="flex" flexDirection="column" style={{ gap: 8 }}>
                      {getInvoiceMaterials(invoice.invoice_materials).map((invoiceMaterial) => (
                        <Card key={invoiceMaterial.id} bodyStyle={{ padding: 12 }}>
                          <InvoiceItem
                            item={{
                              checked: invoiceMaterial?.checked,
                              id: invoiceMaterial.id,
                              description: invoiceMaterial.description,
                              quantity_shipped: invoiceMaterial.quantity_shipped,
                              uom: invoiceMaterial.uom,
                              unit_price: invoiceMaterial.unit_price,
                              calcExtCost,
                              invoice_material: invoiceMaterial,
                            }}
                            onCheck={(itemId) => handleSelectInvoiceMaterials([itemId])}
                            onDelete={(itemId) => handleDelete([itemId])}
                            childrenIsExpandable
                          >
                            <FlexBoxX width="100%">
                              {invoiceMaterial.order_materials?.length > 0 && (
                                <InvoiceItemOrder
                                  items={invoiceMaterial.order_materials.map((material) => ({
                                    calcExtCost,
                                    id: material.id,
                                    description: material?.company_material.description,
                                    quantity_shipped: material.quantity_delivered || material.quantity,
                                    uom: material?.company_material.unit?.name || material.company_material?.unit_name,
                                    unit_price: material.unit_cost as number,
                                    unit_multiplier: Number(material.company_material?.unit?.multiplier),
                                    unit_qty_increment: Number(material.company_material?.unit?.qty_increment),
                                  }))}
                                  onDelete={handleUnmatch}
                                />
                              )}
                            </FlexBoxX>
                          </InvoiceItem>
                        </Card>
                      ))}
                    </Box>
                  </StyledPanel>
                </Collapse>
              )
            })}
          </Card>
        ))}
      </Box>

      <Modal title="Delete items" open={opened} onCancel={handleCancel} onOk={handleConfirmDelete}>
        Do you want to permanently delete the items?
      </Modal>
    </>
  )
}
