import { useNotifyWarehouse } from 'common/hooks/use-notify-warehouse'
import { Address } from 'common/server/addresses'

import { useStores } from 'contractor/hooks/use-stores'
import { Warehouse } from 'contractor/server/warehouses'

type UseCreateWarehouseProps = {
  payload: Warehouse
  onSuccess?: () => void
  onError?: (error: {}) => void
  setSubmitting?: (value: boolean) => void
  addressPayload?: Address
  addAddress?: (id: string, addressPayload: Address) => void
}

export const useCreateWarehouse = () => {
  const { warehouseStore } = useStores()
  const { notifyWarehouse } = useNotifyWarehouse()

  const createWarehouse = async (props: UseCreateWarehouseProps) => {
    const { payload, onSuccess, onError, setSubmitting, addressPayload, addAddress } = props

    try {
      setSubmitting?.(true)
      const wareHouse = await warehouseStore.createWarehouse(payload)

      notifyWarehouse({
        message: 'Warehouse created!',
        warehouseId: payload.id,
      })

      addAddress?.(wareHouse.id, addressPayload)

      onSuccess?.()
    } catch (error) {
      onError?.(error)
    } finally {
      setSubmitting?.(false)
    }
  }

  return { createWarehouse }
}
