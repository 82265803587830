import { useMemo } from 'react'

import moment from 'moment'

import { InventoryOperationTypes } from 'common/server/server_types'

export const useOperationTitles = (operations = []) => {
  // Compute indexes for pickups and deliveries
  const operationIndexes = useMemo(() => {
    return operations.reduce((acc, operation) => {
      const last = acc[acc.length - 1] || { pickUp: 0, delivery: 0 }
      const newCount = {
        pickUp: last.pickUp + (operation.operation_type === InventoryOperationTypes.PICK_UP ? 1 : 0),
        delivery: last.delivery + (operation.operation_type !== InventoryOperationTypes.PICK_UP ? 1 : 0),
      }
      return [...acc, newCount]
    }, [])
  }, [operations])

  // Generate the title for each operation
  const getOperationTitle = (operationIndex: number) => {
    const operation = operations[operationIndex]
    const requestedDate = operation?.requested_date
    const isPickUp = operation?.operation_type === InventoryOperationTypes.PICK_UP
    const count = isPickUp ? operationIndexes[operationIndex]?.pickUp : operationIndexes[operationIndex]?.delivery

    if (requestedDate) {
      return moment(requestedDate).format('MM/DD/YYYY')
    }

    return isPickUp ? `Pick Up ${count}` : `Delivery ${count}`
  }

  return { getOperationTitle }
}
