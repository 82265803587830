import React from 'react'

import { Typography } from 'antd'

import { Box } from 'common/components/boxes'
import { SignInTypes } from 'common/pages/Landing'

import { Logo } from './logo'

type InviteExpiredProps = {
  setSignInType: (string) => void
}

const InviteExpired = ({ setSignInType }: InviteExpiredProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      height="100%"
      justifyContent={{ _: 'space-between', xs: 'center' }}
      marginBottom={{ _: '0', xs: '60px' }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" width="inherit">
        <Logo onClick={() => setSignInType(SignInTypes.SIGN_IN)} />

        <Typography.Title level={4}> Oops! Your Invite Has Expired</Typography.Title>
        <Typography.Text style={{ marginBottom: '20px', fontSize: '16px' }}>
          It looks like this invite link is no longer valid. Don’t worry—you can request a new one by reaching out to
          your account administrator. If you believe this is a mistake or need further assistance, feel free to contact
          our support team at{' '}
          <a href="mailto:help@subbase.io" style={{ textDecoration: 'underline' }}>
            help@subbase.io
          </a>
          .
        </Typography.Text>
      </Box>
    </Box>
  )
}

export default InviteExpired
