import React from 'react'

import { Box } from 'common/components/boxes'
import { ErrorState } from 'common/components/ErrorState/error_state'
import { Loading } from 'common/components/Loading'
import { Page } from 'common/components/Page'
import { SearchInput } from 'common/components/SearchInput'
import { Visibility } from 'common/components/Visibility'

import { withWarehouseProvider } from 'contractor/pages/Inventory/Warehouses/common/context/context'
import { useGetWarehouses } from 'contractor/pages/Inventory/Warehouses/common/hooks/use-get-warehouses'
import { ListActions } from 'contractor/pages/Inventory/Warehouses/List/Components/ListActions/list_actions'
import { WarehouseList } from 'contractor/pages/Inventory/Warehouses/List/Components/WarehouseList/warehouse_list'
import { Warehouse } from 'contractor/server/warehouses'

const Warehouses = () => {
  const [search, setSearch] = React.useState('')
  const { isLoading, data, isError, refetch } = useGetWarehouses()

  const filterData = (warehouse: Warehouse) => {
    if (!search) return true

    return warehouse.name.toLowerCase().includes(search.trim())
  }

  if (isLoading) return <Loading />

  if (isError) return <ErrorState />

  return (
    <Page>
      <Page.Header display="grid" gridGap={16}>
        <Visibility.Show breakpoint="md">
          <Box width="100%">
            <SearchInput value={search} placeholder="Search a warehouse" onSearch={setSearch} />
            <ListActions />
          </Box>
        </Visibility.Show>

        <Visibility.Hidden breakpoint="md">
          <Box display="flex" gridGap={8} alignItems="center" justifyContent="space-between">
            <SearchInput value={search} placeholder="Search a warehouse" onSearch={setSearch} />
            <ListActions />
          </Box>
        </Visibility.Hidden>
      </Page.Header>
      <Page.Content p={0} px={{ _: 0, md: 12, lg: 16 }} pb={24} pt={{ _: 0, md: 16 }} height="100%">
        <WarehouseList data={data.filter(filterData)} refetch={refetch} />
      </Page.Content>
    </Page>
  )
}

export const WarehousePage = withWarehouseProvider(Warehouses)
