import React, { useState } from 'react'

import { DownOutlined, FilterOutlined, HistoryOutlined, UploadOutlined } from '@ant-design/icons'
import { Col, Row, Button, Dropdown } from 'antd'

import { observer } from 'mobx-react-lite'

import { Box } from 'common/components/boxes'
import { RibbonFilter } from 'common/components/RibbonFilter'
import { SearchInput } from 'common/components/SearchInput'
import { usePersistentFilters } from 'common/contexts/persistent_filters'

import { Flatfile } from 'contractor/components/Flatfile'
import { useStores } from 'contractor/hooks/use-stores'
import { SelectWarehouseFilter } from 'contractor/pages/Inventory/common/components/SelectWarehouseFilter'

import { StockCreate } from '../Create'

const ribbonOptions = [
  { label: 'All', filter: '' },
  { label: 'Available', filter: 'AVAILABLE' },
  { label: 'Commited', filter: 'COMMITED' },
  { label: 'Quality hold', filter: 'QUALITY_HOLD' },
  { label: 'Rejected', filter: 'REJECTED' },
]

export const StockHeader = observer(() => {
  const {
    inventoryStockStore: { listStore },
    flatfileStore,
  } = useStores()
  const persistentFilters = usePersistentFilters()

  const [openStockCreate, toggleStockCreate] = useState(false)

  const warehouseId = listStore.searchState.filters['warehouse_id']

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 10 }}>
        <Col xs={24} sm={8} md={6} lg={5} xl={4} xxl={4}>
          <SelectWarehouseFilter
            value={warehouseId}
            onChange={(value) => persistentFilters.handleChangeFilters({ warehouse_id: value }, false)}
          />
        </Col>
        <Col xs={24} sm={16} md={18} lg={11} xl={12} xxl={14}>
          <Box display="flex" alignItems="center" gridGap={10}>
            <SearchInput
              value={listStore.searchState.search}
              onSearch={(search: string) => persistentFilters.handleChangeFilters({ search }, false)}
            />
            <Button icon={<FilterOutlined />} style={{ minWidth: 32 }} />
            <Button icon={<HistoryOutlined />} style={{ minWidth: 32 }} />
          </Box>
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={3}>
          <Flatfile
            buttonProps={{
              style: { width: '100%' },
              icon: <UploadOutlined />,
              type: 'default',
              disabled: !warehouseId,
            }}
            icon="Import material"
            openUpload={() => flatfileStore.openUploadInventoryStockItems(warehouseId)}
            onSuccess={listStore.fetchRecords}
          />
        </Col>
        <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={3}>
          <Dropdown
            trigger={['click']}
            menu={{ items: [{ key: 'add-new-item', label: 'Add new item', onClick: () => toggleStockCreate(true) }] }}
          >
            <Button icon={<DownOutlined style={{ fontSize: 10 }} />} style={{ width: '100%' }} type="primary">
              Add stock
            </Button>
          </Dropdown>
        </Col>
      </Row>

      <RibbonFilter
        showCounts
        overflowCount={9999}
        value={listStore.searchState.filters['state'] || []}
        counts={listStore.stateCounts || {}}
        onChange={(value) => persistentFilters.handleChangeFilters({ state: value })}
        options={ribbonOptions}
        boxProps={{ overflowX: 'auto' }}
      />

      <StockCreate open={openStockCreate} onClose={() => toggleStockCreate(false)} />
    </>
  )
})
