import React, { useState } from 'react'

import DownloadOutlined from '@ant-design/icons/DownloadOutlined'
import { Button, message, Tooltip, Typography } from 'antd'
import { ButtonProps } from 'antd/lib/button'

import { Box } from 'common/components/boxes'

interface Props {
  onExport: (hiddenColumns?: string[], extras?: {}) => Promise<void>
  title: string
  buttonProps?: ButtonProps
  closeDrawer?: () => void
  hiddenColumns?: string[]
  extras?: {}
}
export function ExportButton(props: Props) {
  const { onExport, title, buttonProps, closeDrawer, hiddenColumns, extras } = props
  const [loading, setLoading] = useState(false)

  const handleFinish = async () => {
    try {
      setLoading(true)
      await onExport(hiddenColumns, extras)
      message.success(
        "We are processing the download, you will receive a pop-up notification and also an email when it's done",
      )
    } catch (error) {
      message.error(error?.response?.data?.error || 'Unable to download.')
    } finally {
      setLoading(false)
      closeDrawer?.()
    }
  }

  return (
    <Box p={12} display="flex" alignItems="center" justifyContent="space-between">
      <Typography.Title level={5}>{title}</Typography.Title>
      <Tooltip title={title} placement="bottomRight">
        <Button {...buttonProps} loading={loading} data-cy="download-button" onClick={handleFinish}>
          <DownloadOutlined />
        </Button>
      </Tooltip>
    </Box>
  )
}
