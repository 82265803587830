import { useState } from 'react'

import { message } from 'antd'

import { noticeError } from 'common/helpers/new_relic'

import { useStores } from 'contractor/hooks/use-stores'
import { CreateStockRequest } from 'contractor/server/inventory'

export const useCreateStock = ({ onSuccess }) => {
  const { inventoryStockStore } = useStores()

  const [isSubmitting, toggleIsSubmitting] = useState(false)

  const handleCreateStock = async (stock: CreateStockRequest) => {
    try {
      toggleIsSubmitting(true)
      await inventoryStockStore.createStock(stock)
      message.success('Successfully created stock')
      onSuccess?.()
    } catch (error) {
      noticeError(error, { entry: 'create-inventory-stock' })
      message.error(error?.response?.data?.error || 'Unable to create stock')
    } finally {
      toggleIsSubmitting(false)
    }
  }

  return { handleCreateStock, isSubmitting }
}
